import React, { Component, Dispatch } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Table, Row, Col, Card, CardBody, CardTitle, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Datatable/datatables.scss";
import { del, get, post } from "src/helpers/api_helper";
import NoDataIndication from "../Datatable/NoDataIndication";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import { connect } from "react-redux";
import { getCustomers } from "src/store/actions";
import { PermissionModule } from "src/store/permissions/actionTypes";
import CustomerCreateFullModal from "./CustomerCreateFullModal";

interface MyProps extends RouteComponentProps<any> {
    permissions: Array<any>;
    getCustomers: () => void;
}

interface MyState {
    sizePerPage: number;
    totalSize: number;
    data: any;
    sortField: string;
    sortOrder: string;
    loading: boolean;
    permissions: Array<any>;
    modal_cust_is_open: boolean;
    customer_id: string;
};

interface ChangeProp {
    page: number;
    sizePerPage: number;
}

class CustomerList extends Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);
        this.state = {
            sizePerPage: 10,
            totalSize: 0,
            data: [],
            sortField: "id",
            sortOrder: "asc",
            loading: true,
            permissions: [],
            modal_cust_is_open: false,
            customer_id: "0"
        };
        this.handleDelete = this.handleDelete.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    fetchData = (param: any) => {
        const sendGetRequest = async () => {
            try {

                const resp = await post(process.env.REACT_APP_API_URL + `/api/customer_list_grid`, param);
                if (resp.success) {
                    this.setState({
                        totalSize: resp.data.count,
                        data: resp.data.results,
                        loading: false
                    });
                }

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        sendGetRequest();
    }

    handleTableChange = (type: string, param: ChangeProp) => {
        this.setState({ sizePerPage: param.sizePerPage });
        this.fetchData(param);

    }

    handleDelete = (row: any) => {
        const sendGetRequest = async () => {
            try {

                const conf = window.confirm("Do you want to delete this record?");
                if (conf) {
                    const resp = del(process.env.REACT_APP_API_URL + '/api/customer_delete/' + row.id);
                    this.props.getCustomers();
                }

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };
        sendGetRequest();
        this.fetchData({ page: 1, searchText: "", sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    }
    sendGetRequest = async () => {
        try {
            let customerPermissions = this.props.permissions.filter(permission => permission.module_permission_id === PermissionModule.CUSTOMER);
            this.setState({
                permissions: customerPermissions
            });
            this.fetchData({ page: 1, searchText: "", sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    };
    componentDidMount() {
        this.sendGetRequest();
    }
    openCustModal = (id: string) => {
        this.setState({ modal_cust_is_open: true, customer_id: id });
    }
    closeCustModal = () => {
        this.setState({ modal_cust_is_open: false, customer_id: "0" });
    }
    parentCallback = () => {
        this.closeCustModal();
        this.fetchData({ page: 1, searchText: "", sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    }
    render() {

        const pageOptions: any = {
            sizePerPage: this.state.sizePerPage,
            totalSize: this.state.totalSize, // replace later with size(customers),
            custom: true,
        };

        const { SearchBar } = Search;

        const defaultSorted: any = [
            {
                dataField: this.state.sortField,
                order: this.state.sortOrder,
            },
        ];

        const columns = [
            {
                dataField: "id",
                text: "Id",
                sort: true,
                hidden: true
            },
            {
                dataField: "customer_name",
                text: "Customer Name",
                sort: true
            },
            {
                dataField: "phone",
                text: "Contact No",
                sort: true
            },
            {
                dataField: "email",
                text: "Email",
                sort: true
            },
            {
                dataField: "gstin",
                text: "GSTIN",
                sort: true
            },
            {
                dataField: "actions",
                text: "Actions",
                isDummyField: true,
                formatter: (cellContent: any, row: any) => {
                    return (
                        <>                           
                            {this.state.permissions.find(({ action, is_active }) => action == "modify" && is_active == 1) &&
                                <Link to="#" onClick={() => this.openCustModal(row.id)}>
                                    <span className="btn tbl-btn mx-2">
                                        <i className="fas fa-edit" data-toggle="tooltip" data-placement="bottom" title="Edit" style={{ color: '#28a745' }} />
                                    </span>
                                </Link>
                            }
                            {this.state.permissions.find(({ action, is_active }) => action == "delete" && is_active == 1) &&
                                <Link to="#" onClick={() => this.handleDelete(row)}>
                                    <span className="btn tbl-btn">
                                        <i className="fas fa-trash-alt" data-toggle="tooltip" data-placement="bottom" title="Delete" style={{ color: '#dc3545' }} />
                                    </span>
                                </Link>
                            }
                        </>
                    );
                }
            }
        ];

        return (
            <React.Fragment>

                <Row>
                    <Col className="col-12">
                        <Card>

                            <CardBody>

                                {this.state.loading == true ? (
                                    <>
                                        <CardTitle className="h4"><Skeleton height={'10%'} width={'10%'} /></CardTitle>
                                        <Table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th><Skeleton width={100} /></th>
                                                    <th><Skeleton width={100} /></th>
                                                    <th><Skeleton width={100} /></th>
                                                    <th><Skeleton width={100} /></th>
                                                    <th><Skeleton width={100} /></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><Skeleton height={20} /></td>
                                                    <td><Skeleton height={20} /></td>
                                                    <td><Skeleton height={20} /></td>
                                                    <td><Skeleton height={20} /></td>
                                                    <td><Skeleton height={20} /></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </>
                                ) :
                                    (
                                        <>

                                            <PaginationProvider
                                                pagination={paginationFactory(pageOptions)}
                                            >
                                                {({ paginationProps, paginationTableProps }) => (
                                                    <ToolkitProvider
                                                        keyField="id"
                                                        columns={columns}
                                                        data={this.state.data}
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <div className="d-sm-flex align-items-center justify-content-between">
                                                                    <div className="search-box me-2 mb-2 d-inline-block cstm-search">

                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                        {/* <i className="bx bx-search-alt search-icon" /> */}

                                                                    </div>
                                                                    <Link to="#" onClick={() => this.openCustModal("0")} >
                                                                        <button type="button" className="btn btn-primary"><i className="fa fa-plus"></i> Create</button>
                                                                    </Link>

                                                                </div>
                                                                <Row>
                                                                    <Col xl="12">
                                                                        <div className="table-responsive">
                                                                            <BootstrapTable
                                                                                // responsive                                  
                                                                                remote
                                                                                bordered={false}
                                                                                striped={false}
                                                                                defaultSorted={defaultSorted}
                                                                                classes={"table align-middle table-nowrap"}
                                                                                headerWrapperClasses={"thead-light"}
                                                                                onTableChange={this.handleTableChange}
                                                                                {...toolkitProps.baseProps}
                                                                                {...paginationTableProps}
                                                                                noDataIndication={() => <NoDataIndication />}
                                                                            />



                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <Row className="align-items-md-center mt-30">
                                                                    <Col className="inner-custom-pagination d-flex">
                                                                        <div className="d-inline">
                                                                            <SizePerPageDropdownStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                        <div className="text-md-right ms-auto">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                )}
                                            </PaginationProvider>
                                        </>
                                    )
                                }

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {this.state.modal_cust_is_open == true && <CustomerCreateFullModal id={this.state.customer_id} modal_cust_is_open={this.state.modal_cust_is_open} closeModal={() => this.closeCustModal()} parentCallback={this.parentCallback} />}

            </React.Fragment>
        );
    }
}
const mapStateToProps = (state: { permissions: any }) => {
    return {
        permissions: state.permissions.permissions
    }
}
const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        getCustomers: () => {
            dispatch(getCustomers());
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerList));