import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { useHistory } from 'react-router';
import { get, post } from 'src/helpers/api_helper';
import { useSelector } from 'react-redux';

interface CategoryProps {
    modal_is_open: boolean;
    openModal: React.KeyboardEventHandler<any> | React.MouseEventHandler<any>;
    closeModal: Function;
    id: string;
}
const ExpCategoryModal = (props: CategoryProps) => {
    const [isProgress, setIsProgress] = useState(0);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState({ category_name: null, parent_category_id: null, description: null });
    const handleValidSubmit = (event: any, values: any) => {
        setIsProgress(1);
        values["id"] = props.id;
        const resp = post(process.env.REACT_APP_API_URL + '/api/exp_category_save', values);
        props.closeModal();
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resCs: any = await get(process.env.REACT_APP_API_URL + '/api/get_exp_categories');
                setCategories(resCs.data);

                if (props.id != '0') {
                    const resC: any = await get(process.env.REACT_APP_API_URL + '/api/exp_category_get/' + props.id).then();
                    setCategory(resC.data);
                }
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();

    }, []);
    return (
        <React.Fragment>
            <Modal
                isOpen={props.modal_is_open}
                toggle={props.openModal}
                className="md-700"
            >

                <AvForm onValidSubmit={handleValidSubmit}  >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            {props.id == '0' ? "Create Category" : "Modify Category"}
                        </h5>
                        <button
                            type="button"
                            onClick={() => props.closeModal()}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            id="closeButton"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        <div className="grey-bg-frm">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="progresspill-firstname-input">
                                            Category name
                                        </label>
                                        <AvField
                                            name="category_name"
                                            type="text"
                                            placeholder="Enter Category name"
                                            errorMessage="Enter Category name"
                                            validate={{ required: { value: true } }}
                                            value={category.category_name}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="progresspill-address-input">
                                            Parent Category
                                        </label>
                                        <AvField type="select" name="parent_category_id" value={category.parent_category_id} >
                                            <option value="" key="" >Parent Category</option>
                                            {categories.map((category: { id: string; category_name: string; }) => (
                                                <option value={category.id} key={category.id} >{category.category_name}</option>
                                            ))}
                                        </AvField>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="progresspill-address-input">
                                            Description
                                        </label>
                                        <AvField type="text" name="description" value={category.description} />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="modal-footer justify-content-center">
                        {isProgress ?
                            <>
                                <button type="submit" className="btn btn-primary " disabled={true}>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Submiting
                                </button>
                            </> :
                            <>
                                <button
                                    type="button"
                                    onClick={() => props.closeModal()}
                                    className="btn btn-secondary "
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>{" "}
                                <Button type="submit" color="primary" className="">
                                    Submit
                                </Button>
                            </>
                        }
                    </div>
                </AvForm>
            </Modal>
        </React.Fragment>
    )
}

export default ExpCategoryModal;

