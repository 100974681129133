import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, BreadcrumbItem, NavLink, TabContent, TabPane } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { ActionMeta, OnChangeValue, Options } from "react-select/dist/declarations/src";

//Import Breadcrumb
import "../Datatable/datatables.scss";
import { del, get, post } from "src/helpers/api_helper";
import NoDataIndication from "../Datatable/NoDataIndication";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ExpenseCreate from "./ExpenseCreate";
import moment from "moment";
import MetaTags from "react-meta-tags";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import ReactDatePicker from "react-datepicker";
import Select from 'react-select';
import ExpenseIcon from "src/assets/images/icons/Expense";
import Addcustomer2Icon from "src/assets/images/icons/Customer2";
import classnames from "classnames";
import Estimate2Icon from "src/assets/images/icons/Estimate2";
import Invoice2Icon from "src/assets/images/icons/Invoice2";
import PurchaseList from "./PurchaseList";
import VendorModal from "../Vendors/VendorModal";
import ExpItemModal from "../ExpItems/ExpItemModal";
import ExpCategoryModal from "../ExpCategories/ExpCategoryModal";

interface MyProps extends RouteComponentProps<any> {

}
interface Option {
    label: string;
    value: string;
}
interface MyState {
    activeTab: number;
    sizePerPage: number;
    totalSize: number;
    data: any;
    sortField: string;
    sortOrder: string;
    permissions: Array<any>;
    loading: boolean;
    modal_is_open: boolean;
    expense_prefix: string;
    startDate: Date | null;
    endDate: Date | null;
    vendors: Array<any>;
    items: Array<any>;
    vendor: Option | null | undefined;
    item: Option | null | undefined;
    modalVendor: boolean;
    modalExpItem: boolean;
    modalExpCategory: boolean;
    totalAmount: number;
};

interface ChangeProp {
    page: number;
    sizePerPage: number;
}

const createOption = (label: string, value: string) => ({
    label,
    value,
});

class Purchases extends Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);
        this.state = {
            activeTab: 1,
            sizePerPage: 10,
            totalSize: 0,
            data: [],
            sortField: "invoice_no",
            sortOrder: "asc",
            loading: true,
            permissions: [],
            modal_is_open: false,
            expense_prefix: '',
            startDate: null,
            endDate: null,
            vendors: [],
            items: [],
            vendor: null,
            item: null,
            modalVendor: false,
            modalExpItem: false,
            modalExpCategory: false,
            totalAmount: 0
        };
    }
    openModalVendor = () => {
        this.setState({ modalVendor: true });
    }

    closeModalVendor = () => {
        this.setState({ modalVendor: false });
    }
    openModalExpItem = () => {
        this.setState({ modalExpItem: true });
    }

    closeModalExpItem = () => {
        this.setState({ modalExpItem: false });
    }
    openModalExpCategory = () => {
        this.setState({ modalExpCategory: true });
    }

    closeModalExpCategory = () => {
        this.setState({ modalExpCategory: false });
    }
    setActiveTab = (tab: any) => {
        this.setState({ activeTab: tab });
    }
    toggleTab = (tab: any) => {
        if (this.state.activeTab !== tab) {
            this.setActiveTab(tab);
        }
    }
    fetchData = (param: any) => {
        const sendGetRequest = async () => {
            try {

                const resp = await post(process.env.REACT_APP_API_URL + `/api/purchase_list_grid`, param);
                if (resp.success) {
                    this.setState({
                        totalSize: resp.data.count,
                        totalAmount: resp.data.totalAmount,
                        data: resp.data.results,
                    });
                }

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        sendGetRequest();
    }
    
    componentDidMount() {
        const sendGetRequest = async () => {
            try {
                const respPer = await get(process.env.REACT_APP_API_URL + `/api/permissions/7`);
                if (respPer.success) {
                    this.setState({
                        permissions: respPer.data
                    });
                    this.fetchData({ page: 1, searchText: "", sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
                    const respE: any = await get(process.env.REACT_APP_API_URL + '/api/get_expense_no');

                    this.setState({ expense_prefix: respE.data.expense_prefix });

                    const resV: any = await get(process.env.REACT_APP_API_URL + '/api/get_vendors');
                    resV.data.map((vendor: { vendor_name: string, id: string }) => {
                        let newOption = createOption(vendor.vendor_name, vendor.id);
                        this.setState({ vendors: [...this.state.vendors, newOption] });
                    }
                    )

                    const resI: any = await get(process.env.REACT_APP_API_URL + '/api/get_exp_items');
                    resI.data.map((item: { item_name: string, id: string }) => {
                        let newOption = createOption(item.item_name, item.id);
                        this.setState({ items: [...this.state.items, newOption] });
                    }
                    )
                }
            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };
        sendGetRequest();
        this.setState({ loading: false });
    }

    handleTableChange = (type: string, param: ChangeProp) => {

        this.setState({ sizePerPage: param.sizePerPage });
        this.fetchData(param);

    }

    handleDelete = (row: any) => {
        const sendGetRequest = async () => {
            try {

                const conf = window.confirm("Do you want to delete this record?");
                if (conf) {
                    const resp = del(process.env.REACT_APP_API_URL + '/api/expense_delete/' + row.id);

                }

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        sendGetRequest();
        this.fetchData({ page: 1, searchText: "", sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    }

    openModal = () => {
        this.setState({ modal_is_open: true });
    }

    closeModal = () => {
        this.setState({ modal_is_open: false });
    }

    setStartDate(dateRange: Array<any>) {
        const [startDate, endDate] = dateRange;
        this.setState({ startDate: startDate, endDate: endDate });
        this.fetchData({ page: 1, searchText: "", item_id: this.state.item?.value, startDate: startDate, endDate: endDate, sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    }
    handleChange = async (
        newValue: OnChangeValue<Option, false>,
        actionMeta: ActionMeta<Option>
    ) => {
        let name: any = actionMeta.name;
        let data: any = {};
        data[name] = newValue;
        this.setState(data);
        if (name == 'item') {
            this.fetchData({ page: 1, searchText: "", item_id: newValue?.value, startDate: this.state.startDate, endDate: this.state.endDate, sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
        }
    }
    render() {

        const pageOptions: any = {
            sizePerPage: this.state.sizePerPage,
            totalSize: this.state.totalSize, // replace later with size(customers),
            custom: true,
        };

        const { SearchBar } = Search;

        const defaultSorted: any = [
            {
                dataField: this.state.sortField,
                order: this.state.sortOrder,
            },
        ];

        const columns = [
            {
                dataField: "id",
                text: "Id",
                sort: true,
                hidden: true
            },
            {
                dataField: "expense_date",
                text: "Date",
                sort: true,
                formatter: (cellContent: any, row: any) => {
                    return (
                        <>
                            {moment(cellContent).format('YYYY-MM-DD')}
                        </>
                    );
                }
            },
            {
                dataField: "expense_no",
                text: "Expense No",
                sort: true,
                isDummyField: true,
                formatter: (cellContent: any, row: any) => {
                    return (
                        <>
                            {this.state.expense_prefix ? this.state.expense_prefix + row.expense_no : "EXP" + row.expense_no}
                        </>
                    );
                }
            },
            {
                dataField: "item_name",
                text: "Item",
                sort: true
            },
            {
                dataField: "bank_name",
                text: "Mode",
                sort: true
            },
            {
                dataField: "amount",
                text: "Amount",
                sort: true
            },
            {
                dataField: "actions",
                text: "Actions",
                isDummyField: true,
                formatter: (cellContent: any, row: any) => {
                    return (
                        <div className='table-button'>
                            <Link to={"/expense_edit/" + row.id} className="btn tbl-btn mx-2">
                                <i className="fas fa-edit"></i>
                            </Link>
                            <Link to="#" className="btn tbl-btn" onClick={() => this.handleDelete(row)}>
                                <i className="fas fa-trash"></i>
                            </Link>
                        </div>
                    );
                }
            }
        ];
        const title = "Expense";
        const breadcrumbItem = "Purchase Bills";
        return (
            <React.Fragment>
                <div className="page-content" style={{ minHeight: '685px' }}>
                    <MetaTags>
                        <title>{title}</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Row>
                            <Col xs="12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between form-wrap">
                                    <div className="header"> <h4 className="mb-0 font-size-18">
                                        {this.state.activeTab == 1 ? breadcrumbItem : "Purchase bill List"}
                                    </h4>
                                        <div className="form-inline">
                                            <AvForm >
                                                <Row>
                                                    <Col lg={6}>
                                                        <Select
                                                            isClearable={true}
                                                            onChange={this.handleChange}
                                                            options={this.state.vendors}
                                                            value={this.state.vendor}
                                                            name="vendor"
                                                            placeholder="Select Vendor"
                                                        />


                                                    </Col>
                                                    <Col lg={6}>

                                                        <ReactDatePicker
                                                            selected={this.state.startDate}
                                                            onChange={(date: Array<any>) => this.setStartDate(date)}
                                                            selectsRange={true}
                                                            startDate={this.state.startDate}
                                                            endDate={this.state.endDate}
                                                            dateFormat="yyyy-MM-dd"
                                                            className="form-control calendar"
                                                            isClearable={true}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            todayButton="Today"
                                                            monthsShown={2}
                                                        />


                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        </div>
                                    </div>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <BreadcrumbItem>
                                                <Link to="#">{title}</Link>
                                            </BreadcrumbItem>
                                            <BreadcrumbItem active>
                                                <Link to="#">{breadcrumbItem}</Link>
                                            </BreadcrumbItem>
                                        </ol>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardHeader>
                                        <div className="row">

                                        <div className="col-md-12 col-xl-7 order-xl-1">
                                                {this.state.loading == true ? (
                                                    <Skeleton width={'30%'} height={'100%'} />
                                                ) : (
                                                    <ul className="values">

                                                        <li className="active">
                                                            <NavLink>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="32.815" height="49.055" viewBox="0 0 32.815 49.055">
                                                                    <path id="Path_441" data-name="Path 441" d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z" transform="translate(-1.415 49.055)" />
                                                                </svg>


                                                                <span>{" "}{this.state.totalAmount}</span>
                                                                <label>Total</label>
                                                            </NavLink>
                                                        </li>

                                                    </ul>
                                                )}
                                            </div>
                                            <div className="col-md-12 col-xl-5 order-xl-1">
                                                {this.state.permissions.find(({ action, is_active }) => action == "create" && is_active == 1) &&
                                                    (
                                                        <ul className="qck-link1">
                                                            <li>
                                                                <Link to="#" onClick={(e: any) => this.openModalExpCategory()}>
                                                                    <span><ExpenseIcon /></span>
                                                                    <label>Add Category</label>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" onClick={(e: any) => this.openModalExpItem()}>
                                                                    <span><ExpenseIcon /></span>
                                                                    <label>Add Item</label>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" onClick={(e: any) => this.openModalVendor()}>
                                                                    <span><Addcustomer2Icon /></span>
                                                                    <label>Add Vendor</label>
                                                                </Link>
                                                            </li>                                                            

                                                            <li>
                                                                <Link to="/purchase_add"  >
                                                                    <span><Invoice2Icon /></span>
                                                                    <label>Add purchase bill</label>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    )
                                                }
                                            </div>
                                        </div>

                                    </CardHeader>
                                    <CardBody>

                                        <Row>
                                            <Col sm="12">
                                                <CardTitle className="h4">{breadcrumbItem}</CardTitle>
                                                <PurchaseList valueVendor={this.state.vendor} startDate={this.state.startDate} endDate={this.state.endDate} />
                                            </Col>
                                        </Row>


                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>

                {this.state.modalVendor && (
                    <VendorModal modal_is_open={this.state.modalVendor} openModal={this.openModalVendor} closeModal={this.closeModalVendor} id={'0'} />
                )}
                {this.state.modalExpItem && (
                    <ExpItemModal modal_is_open={this.state.modalExpItem} openModal={this.openModalExpItem} closeModal={this.closeModalExpItem} id={'0'} />
                )}
                {this.state.modalExpCategory && (
                    <ExpCategoryModal modal_is_open={this.state.modalExpCategory} openModal={this.openModalExpCategory} closeModal={this.closeModalExpCategory} id={'0'} />
                )}
            </React.Fragment>
        );
    }
}

export default withRouter(Purchases);