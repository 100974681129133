import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, UncontrolledAlert, ModalFooter, Button, Table } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Datatable/datatables.scss";
import { del, post, get } from "src/helpers/api_helper";
import NoDataIndication from "../Datatable/NoDataIndication";
import moment from "moment";

import Select from 'react-select';
import { ActionMeta, OnChangeValue, Options } from "react-select/dist/declarations/src";

import 'react-datepicker/dist/react-datepicker.css';

import SentIcon from "src/assets/images/icons/Sent";
import WhatsappIcon from "src/assets/images/icons/Whatsapp";

import EllipsisIcon from "src/assets/images/icons/Elipsis";

interface MyProps extends RouteComponentProps<any> {
    valueVendor?: Option | null | undefined;
    startDate?: Date | null;
    endDate?: Date | null;
}

interface MyState {
    sizePerPage: number;
    totalSize: number;
    data: any;
    sortField: string;
    sortOrder: string;
    totalAmount: number;
};

interface ChangeProp {
    page: number;
    sizePerPage: number;
}

interface Option {
    label: string;
    value: string;
}

class PurchaseList extends Component<MyProps, MyState> {

    constructor(props: any) {
        super(props);
        this.state = {
            sizePerPage: 10,
            totalSize: 0,
            data: [],
            sortField: "invoice_no",
            sortOrder: "desc",
            totalAmount: 0
        };
        this.handleDelete = this.handleDelete.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    fetchData = (param: any) => {
        const sendGetRequest = async () => {
            try {

                const resp = await post(process.env.REACT_APP_API_URL + `/api/purchase_list_grid`, param);                
                if (resp.success) {
                    this.setState({
                        totalSize: resp.data.count,
                        totalAmount: resp.data.totalAmount,
                        data: resp.data.results
                    });
                }

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }


        };

        sendGetRequest();
    }
    componentDidMount() {
        const sendGetRequest = async () => {
            try {
                this.fetchData({ page: 1, searchText: "", sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };
        sendGetRequest();
    }
    handleTableChange = (type: string, param: ChangeProp) => {
        this.setState({ sizePerPage: param.sizePerPage });
        this.fetchData(param);

    }

    handleDelete = (row: any) => {
        const sendGetRequest = async () => {
            try {

                const conf = window.confirm("Do you want to delete this record?");
                if (conf) {
                    const resp = del(process.env.REACT_APP_API_URL + '/api/purchase_delete/' + row.id);

                }

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        sendGetRequest();
        this.fetchData({ page: 1, searchText: "", sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    }
    componentDidUpdate(prevProps: MyProps) {
        if (this.props.valueVendor !== prevProps.valueVendor || this.props.startDate !== prevProps.startDate || this.props.endDate !== prevProps.endDate) {
            this.fetchData({ page: 1, searchText: "", vendor_id: this.props.valueVendor?.value, startDate: this.props.startDate, endDate: this.props.endDate, sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
        }
    }

    render() {

        const pageOptions: any = {
            sizePerPage: this.state.sizePerPage,
            totalSize: this.state.totalSize,
            custom: true,
        };

        const { SearchBar } = Search;

        const defaultSorted: any = [
            {
                dataField: this.state.sortField,
                order: this.state.sortOrder,
            },
        ];

        const columns = [
            {
                dataField: "id",
                text: "Id",
                sort: true,
                hidden: true
            },
            {
                dataField: "invoice_date",
                text: "Invoice Date",
                sort: true,
                formatter: (cellContent: any, row: any) => {
                    return (
                        <>{moment(row.invoice_date).format("DD/MM/YYYY")}</>
                    )
                }
            },
            {
                dataField: "vendor_name",
                text: "Vendor",
                sort: true,
            },
            {
                dataField: "invoice_no",
                text: "Invoice No",
                sort: true,
            },
            {
                dataField: "amount",
                text: "Amount",
                sort: true,
            },
            {
                dataField: "actions",
                text: "Actions",
                isDummyField: true,
                formatter: (cellContent: any, row: any) => {
                    return (
                        <div className='table-button'>
                            <Link to={"/purchase_edit/" + row.id} className="btn tbl-btn mx-2">
                                <i className="fas fa-edit"></i>
                            </Link>
                            <Link to="#" className="btn tbl-btn" onClick={() => this.handleDelete(row)}>
                                <i className="fas fa-trash"></i>
                            </Link>
                        </div>
                    );
                }
            }

        ];

        return (
            <React.Fragment>

                <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                >
                    {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={this.state.data}
                            search
                        >
                            {toolkitProps => (
                                <React.Fragment>

                                    <Row>
                                        <Col xl="12">
                                            <div className="table-responsive">
                                                <BootstrapTable
                                                    // responsive                                                
                                                    remote
                                                    bordered={false}
                                                    striped={false}
                                                    defaultSorted={defaultSorted}
                                                    classes={"table align-middle table-nowrap"}
                                                    headerWrapperClasses={"thead-light"}
                                                    onTableChange={this.handleTableChange}
                                                    {...toolkitProps.baseProps}
                                                    {...paginationTableProps}
                                                    noDataIndication={() => <NoDataIndication />}
                                                />
                                            </div>
                                        </Col>
                                    </Row>


                                    <Row className="align-items-md-center mt-30">
                                        <Col className="inner-custom-pagination d-flex">
                                            <div className="d-inline">
                                                <SizePerPageDropdownStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                            <div className="text-md-right ms-auto">
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                        </Col>
                                    </Row>


                                </React.Fragment>
                            )}
                        </ToolkitProvider>
                    )}
                </PaginationProvider>


            </React.Fragment>
        );
    }
}



export default withRouter(PurchaseList);
