import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { useHistory } from 'react-router';
import { get, post } from 'src/helpers/api_helper';
import { useSelector } from 'react-redux';

interface VendorProps {
    modal_is_open: boolean;
    openModal: React.KeyboardEventHandler<any> | React.MouseEventHandler<any>;
    closeModal: Function;
    id: string;
}
const VendorModal = (props: VendorProps) => {
    const CountryInd = 99;    
    const [country, setCountry] = useState(CountryInd);    
    const [state, setState] = useState('');
    const [is_indian_state, setIs_indian_state] = useState(1);
    const [isProgress, setIsProgress] = useState(0);    
    const [item, setItem] = useState({vendor_name : '',is_indian_state: 1, gstin: '', pan: '' , state: '', country: '' , address: ''}); 

    const { countries,states } = useSelector((state: any) => ({
        states: state.states.states,
        countries: state.countries.countries
    })); 

    const handleValidSubmit = (event: any, values: any) => {
        setIsProgress(1);
        values["id"] = props.id;
        values["is_indian_state"] = is_indian_state;
        const resp = post(process.env.REACT_APP_API_URL + '/api/vendor_save', values);
        props.closeModal();
    }
    const onChangehandler = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        setCountry(value);
        if (value == CountryInd) {
            setState('');
            setIs_indian_state(1);
        } else {
            setState('');
            setIs_indian_state(0);
        }
    }
    useEffect(() => {
        const fetchData = async () => {            
            try {                
                let urls = [ 
                    process.env.REACT_APP_API_URL + '/api/vendor_get/' + props.id                    
                ];
    
                Promise.all(urls.map((url) => get(url))).then(([resI]) => {
                    setItem(resI.data);
                    setIs_indian_state(resI.data.is_indian_state);    
                    setCountry(resI.data.country);                    
                });
                
            } catch (error) {
                console.error(error);
            }

    }
    if (props.id != '0') {
        fetchData(); 
    } 

    }, []);
    return (
        <React.Fragment>
            <Modal
                isOpen={props.modal_is_open}
                toggle={props.openModal}
                className="md-700"
            >

                <AvForm onValidSubmit={handleValidSubmit}  >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            {props.id == '0' ? "Create Vendor" : "Modify Vendor"}
                        </h5>
                        <button
                            type="button"
                            onClick={() => props.closeModal()}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            id="closeButton"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        <div className="grey-bg-frm">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="progresspill-firstname-input">
                                            Vendor name
                                        </label>
                                        <AvField
                                            name="vendor_name"
                                            type="text"
                                            placeholder="Enter Vendor name"
                                            errorMessage="Enter Vendor name"
                                            validate={{ required: { value: true } }}
                                            value={item.vendor_name}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="progresspill-address-input">
                                            GSTIN
                                        </label>
                                        <AvField
                                            name="gstin" type="text"
                                            placeholder="Enter GSTIN"
                                            value={item.gstin}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="progresspill-address-input">
                                            PAN
                                        </label>
                                        <AvField
                                            name="pan"
                                            type="text"
                                            placeholder="Enter PAN"
                                            value={item.pan}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="progresspill-address-input">
                                            Country
                                        </label>
                                        <AvField type="select" name="country" value={country} onChange={onChangehandler}>
                                            <option key={-1} value={""}>--Select--</option>
                                            {countries.map((country: { id: string, name: string }) => (
                                                <option value={country.id} key={country.id}>{country.name}</option>
                                            ))}
                                        </AvField>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label htmlFor="progresspill-address-input">
                                            State / Province
                                        </label>
                                        {is_indian_state ?
                                            (
                                                <AvField type="select" name="state" value={item.state}  >
                                                    <option key={-1} value={""}>--Select--</option>
                                                    {states.map((state: { id: string, state: string; }) => (
                                                        <option value={state.id} key={state.id}>{state.state}</option>
                                                    ))}
                                                </AvField>
                                            )
                                            :
                                            (
                                                <AvField
                                                    name="state"
                                                    type="text"
                                                    placeholder="Enter state or province"
                                                    value={item.state}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="progresspill-address-input">
                                            Address
                                        </label>
                                        <AvField type="text" name="address" value={item.address} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer justify-content-center">
                        {isProgress ?
                            <>
                                <button type="submit" className="btn btn-primary " disabled={true}>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Submiting
                                </button>
                            </> :
                            <>
                                <button
                                    type="button"
                                    onClick={() => props.closeModal()}
                                    className="btn btn-secondary "
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>{" "}
                                <Button type="submit" color="primary" className="">
                                    Submit
                                </Button>
                            </>
                        }
                    </div>
                </AvForm>
            </Modal>
        </React.Fragment>
    )
}

export default VendorModal;

