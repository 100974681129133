import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Alert, Container } from "reactstrap";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "src/helpers/api_helper";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { connect } from "react-redux";
import { PermissionModule } from "src/store/permissions/actionTypes";

interface RoleParams {
    id: string;
}
interface UserProps extends RouteComponentProps<RoleParams> {
    permissions: Array<any>;
}
type RoleState = {
    id: string;
    first_name: string;
    second_name: string;
    email: string;
    designation: string;
    roles: Array<any>;
    role_id: string;
    permissions: Array<any>;
    loading: boolean;
    isProgress: number;
    isError: boolean;
    errorMsg: string;
    phone_number: string;
};

class UserModify extends Component<UserProps, RoleState> {
    constructor(props: any) {
        super(props);
        const { id } = this.props.match.params;
        this.state = {
            id: id,
            first_name: "",
            second_name: "",
            email: "",
            designation: "",
            roles: [],
            role_id: "",
            loading: true,
            permissions: [],
            isProgress: 0,
            isError: false,
            errorMsg: '',
            phone_number: "+91"
        };
    }

    resetForm = () => {

        this.props.history.push("/users");
    }
    onChangehandler = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        let data: any = {};
        data[name] = value;
        this.setState(data);
    }
    handleValidSubmit = async (event: any, values: any) => {
        this.setState({ isProgress: 1 });
        values["id"] = this.state.id;
        // console.log(values);
        const resp: any = await post(process.env.REACT_APP_API_URL + '/api/user_save', values);
        if (resp.success === true) {
            this.props.history.push("/users");
        } else {
            this.setState({ errorMsg: resp.message, isError: true });
        }
        this.setState({ isProgress: 0 });
    }
    loadData = async () => {
        try {
            let userPermissions = this.props.permissions.filter(permission => permission.module_permission_id === PermissionModule.USER);
            this.setState({ permissions: userPermissions });

            const permission = userPermissions.find(({ action, is_active }) => action == "modify" && is_active == 1);
            if (!permission) {
                this.props.history.push("/users");
            }

            const resp: any = await get(process.env.REACT_APP_API_URL + '/api/user_get/' + this.state.id);
            this.setState(resp.data);
            const respRoles: any = await get(process.env.REACT_APP_API_URL + '/api/roles_list');
            this.setState({ roles: respRoles.data, loading: false });

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    }
    componentDidMount() {
        this.loadData();
    }
    componentWillReceiveProps(props: UserProps) {
        this.loadData();
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Users</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="Modify User" breadcrumbItem="Modify User" />

                        <Row>
                            <Col className="col-12">
                                <Card>

                                    <CardHeader></CardHeader>



                                    <CardBody>
                                        {this.state.isError && (
                                            <Alert color="danger">
                                                {this.state.errorMsg}
                                            </Alert>
                                        )}
                                        <AvForm onValidSubmit={this.handleValidSubmit}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-firstname-input">
                                                                    First name
                                                                </label>
                                                                <AvField
                                                                    name="first_name"
                                                                    type="text"
                                                                    placeholder="Enter First name"
                                                                    errorMessage="Enter First name"
                                                                    value={this.state.first_name}
                                                                    onChange={this.onChangehandler}
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                            </>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-lastname-input">
                                                                    Second Name
                                                                </label>
                                                                <AvField
                                                                    name="second_name"
                                                                    type="text"
                                                                    placeholder="Enter Second Name"
                                                                    errorMessage="Enter Second Name"
                                                                    value={this.state.second_name}
                                                                    onChange={this.onChangehandler}
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                            </>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-address-input">
                                                                    Designation
                                                                </label>
                                                                <AvField
                                                                    name="designation"
                                                                    type="text"
                                                                    placeholder="Enter designation"
                                                                    errorMessage="Enter designation"
                                                                    value={this.state.designation}
                                                                    onChange={this.onChangehandler}
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                            </>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-address-input">
                                                                    Email
                                                                </label>
                                                                <AvField
                                                                    name="email"
                                                                    type="email"
                                                                    placeholder="Enter email"
                                                                    errorMessage="Enter email"
                                                                    value={this.state.email}
                                                                    onChange={this.onChangehandler}
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                            </>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-address-input">
                                                                    Phone Number
                                                                </label>
                                                                <AvField
                                                                    name="phone_number"
                                                                    type="phone"
                                                                    placeholder="Enter phone number"
                                                                    errorMessage="Enter phone number"
                                                                    value={this.state.phone_number}
                                                                    onChange={this.onChangehandler}
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                            </>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <AvField type="select" name="role_id" label="Role" helpMessage="Choose Role" errorMessage="Choose Role"
                                                                    validate={{ required: { value: true } }} value={this.state.role_id} onChange={this.onChangehandler} >
                                                                    <option value="" key="" >--Select--</option>
                                                                    {this.state.roles.map((role) => (
                                                                        <option value={role.id} key={role.id} >{role.role}</option>
                                                                    ))}
                                                                </AvField>
                                                            </>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-address-input">
                                                                    Password
                                                                </label>
                                                                <AvField
                                                                    name="password"
                                                                    type="password"
                                                                    placeholder="Enter password"
                                                                    errorMessage="Enter password"
                                                                    validate={{
                                                                        required: { value: false },
                                                                        pattern: { value: '/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/', errorMessage: "Password must contain one uppercase letter, one lower case, one special character,one digit and minimum 6 characters in length" },
                                                                        minLength: { value: 6, errorMessage: "Password must contain atleast 6 characters" }
                                                                    }}
                                                                />
                                                            </>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-address-input">
                                                                    Confirm Password
                                                                </label>
                                                                <AvField
                                                                    name="cpassword"
                                                                    type="password"
                                                                    placeholder="Enter confirm password"
                                                                    errorMessage="Enter confirm password"
                                                                    validate={{
                                                                        required: { value: false },
                                                                        match: { value: "password" },
                                                                        pattern: { value: '/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/', errorMessage: "Password must contain one uppercase letter, one lower case, one special character and one digit and minimum 6 characters in length" },
                                                                        minLength: { value: 6, errorMessage: "Password must contain atleast 6 characters" }
                                                                    }}
                                                                    minLength="6"
                                                                />
                                                            </>}
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="d-flex flex-wrap gap-2">
                                                {this.state.loading ?
                                                    <><Skeleton width={100} height={37} />{" "}<Skeleton width={100} height={37} /></> :
                                                    <>
                                                        {this.state.isProgress ?
                                                            <>
                                                                <button type="submit" className="btn btn-primary " disabled={true}>
                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Submiting
                                                                </button>
                                                            </> :
                                                            <>
                                                                <Button type="submit" color="primary" className="">
                                                                    Submit
                                                                </Button>{" "}
                                                                <Button type="reset" color="secondary" className="" onClick={this.resetForm}>
                                                                    Cancel
                                                                </Button>
                                                            </>
                                                        }
                                                    </>}
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div></div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state: { permissions: any }) => {
    return {
        permissions: state.permissions.permissions
    }
}
export default connect(mapStateToProps)(withRouter(UserModify));