import React, { Component, Dispatch } from "react";
import MetaTags from "react-meta-tags";
import { Container, Collapse, Form, Label, Input, Button } from "reactstrap";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledDropdown,
    Table
} from "reactstrap";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import avatar from "../../assets/images/users/avatar_blank.jpg";
import { get, getAccessToken, post } from "src/helpers/api_helper";
import classnames from "classnames";
import axios from "axios";
import { json } from "stream/consumers";
import { connect } from "react-redux";
import { getCompanies } from "src/store/actions";

interface PermissionParams {
   
}
interface CompanyProps extends RouteComponentProps<PermissionParams> {
    getCompanies: () => void;
}
type PermissionState = {
    is_indian_state: number;
    activeTab: number;
    accountProfile: number;
    companyProfile: number;
    settingsProfile: number;
    data: {
        states: Array<any>,
        countries: Array<any>,
        businessTypes: Array<any>,
        company: {
            id: string,
            company_name: string,
            business_name: string,
            company_telephone: string,
            company_address1: string,
            company_address2: string,
            company_town_or_city: string,
            company_state: string,
            business_type: string,
            company_pincode: string,
            company_email: string,
            gst_number: string,
            registration_number: string,
            company_state_name: string,
            business_type_name: string,
            company_country: string;
            is_indian_state: number;
            logo: string;
        },
        user: {
            id: string,
            first_name: string,
            second_name: string,
            email: string,
            designation: string,
            role_id: string
        },
        settings: {
            id: string,
            company_name: string,
            company_address: string,
            company_email: string,
            gst_number: string,
            registration_number: string,
            estimation_number_prefix: string,
            invoice_number_prefix: string,
            expense_number_prefix: string,
            estimation_number_start: number,
            invoice_number_start: number,
            expense_number_start: number,
            terms_and_conditions: string,
            invoice_light_color: string,
            invoice_dark_color: string,
            invoice_template: number
        },
        orders: Array<any>
    };
};

interface LoopActions {
    razorpay_payment_id: string;
    plan_name: string;
    payment_amount: number;
    payment_date: Date;
    valid_from: Date;
    valid_to: Date;
}
class CompanyProfile extends Component<CompanyProps, PermissionState> {
    constructor(props: any) {
        super(props);

        this.state = {
            is_indian_state: 0,
            activeTab: 1,
            accountProfile: 0,
            companyProfile: 0,
            settingsProfile: 0,
            data: {
                states: [],
                businessTypes: [],
                company: {
                    id: "0",
                    company_name: "",
                    business_name: "",
                    company_telephone: "",
                    company_address1: "",
                    company_address2: "",
                    company_town_or_city: "",
                    company_state: "",
                    business_type: "",
                    company_pincode: "",
                    company_email: "",
                    gst_number: "",
                    registration_number: "",
                    company_state_name: "",
                    business_type_name: "",
                    company_country: "",
                    is_indian_state: 0,
                    logo: ""
                },
                user: {
                    id: "0",
                    first_name: "",
                    second_name: "",
                    email: "",
                    designation: "",
                    role_id: ""
                },
                settings: {
                    id: "0",
                    company_name: "",
                    company_address: "",
                    company_email: "",
                    gst_number: "",
                    registration_number: "",
                    estimation_number_prefix: "",
                    invoice_number_prefix: "",
                    expense_number_prefix: "",
                    estimation_number_start: 0,
                    invoice_number_start: 0,
                    expense_number_start: 0,
                    terms_and_conditions: "",
                    invoice_light_color: '#FBC587',
                    invoice_dark_color: '#F7921C',
                    invoice_template: 2
                },
                orders: [],
                countries: []
            }
        };
    }

    onChangehandler = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        let data: any = {};
        data[name] = value;
        this.setState(data);
    }

    handleValidSubmitAcc = async (event: any, values: any) => {
        values["id"] = this.state.data.user.id;
        // values["designation"] = this.state.data.user.designation;
        // values["role_id"] = this.state.data.user.role_id;
        // console.log(values);
        const resp = await post(process.env.REACT_APP_API_URL + '/api/user_save', values);
        this.setState({ accountProfile: 0 });
        this.sendGetRequest();
    }

    resetFormAcc = () => {
        this.setState({ accountProfile: 0 });
    }

    clickEditAcc = () => {
        this.setState({ accountProfile: 1 });
    }

    handleValidSubmitComp = async (event: any, values: any) => {

        const userForm: any = document.getElementById('companyForm');
        const formData = new FormData(userForm);

        formData.append("id", this.state.data.company.id);
        formData.append("is_indian_state", String(this.state.is_indian_state));
        formData.append("company_email", this.state.data.company.company_email);
        var token = getAccessToken();
        const resp = await axios.post(process.env.REACT_APP_API_URL + '/api/save_company_profile', formData, { headers: { "Authorization": token } });
        this.props.getCompanies();
        this.setState({ companyProfile: 0 });
        this.sendGetRequest();
    }

    resetFormComp = () => {
        this.setState({ companyProfile: 0 });
    }

    clickEditComp = () => {
        this.setState({ companyProfile: 1 });
    }

    handleValidSubmitCompSettings = async (event: any, values: any) => {
        values["id"] = this.state.data.settings.id;
        values["company_name"] = this.state.data.settings.company_name;
        values["company_email"] = this.state.data.settings.company_email;
        values["company_address"] = this.state.data.settings.company_address;
        values["gst_number"] = this.state.data.settings.gst_number;
        values["registration_number"] = this.state.data.settings.registration_number;
        values["invoice_template"] = this.state.data.settings.invoice_template;
        const resp = await post(process.env.REACT_APP_API_URL + '/api/save_company_settings', values);
        this.setState({ settingsProfile: 0 });
        this.sendGetRequest();
        // this.setLocalStorage(); 
    }

    /* setLocalStorage = () => {
        const getAuthUser = localStorage.getItem("authUser");
            if (getAuthUser) {
                if (
                    process.env.REACT_APP_DEFAULTAUTH === "jwt"
                ) {
                    const obj = JSON.parse(getAuthUser);
                    obj.company = this.state.data.company.company_name;
                    obj.logo = this.state.data.company.logo;
                    localStorage.setItem("authUser",JSON.stringify(obj));
                }
            } 
    } */

    resetFormCompSettings = () => {
        this.setState({ settingsProfile: 0 });
    }

    clickEditCompSettings = () => {
        this.setState({ settingsProfile: 1 });
    }

    onChangeCountryhandler = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        if (value == 99) {
            this.setState({ is_indian_state: 1 })
        } else {
            this.setState({ is_indian_state: 0 })
        }
    }

    sendGetRequest = async () => {
        try {
            const resp: any = await get(process.env.REACT_APP_API_URL + '/api/company_profile');
            this.setState({ data: resp.data });

            if (this.state.data.company.is_indian_state) {
                this.setState({ is_indian_state: 1 })
            } else {
                this.setState({ is_indian_state: 0 })
            }

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    };

    componentDidMount() {
        this.sendGetRequest();
    }

    render() {
        return (
            <React.Fragment>

                <div className="page-content">
                    <MetaTags>
                        <title>Company Profile</title>
                    </MetaTags>
                    <Container fluid>
                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Company Profile" breadcrumbItem="Company Profile" />

                        <Row>

                            <Row>
                                <Col xl={12} lg={12}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <div className="col-sm order-2 order-sm-1">
                                                    <div className="d-flex user-det align-items-start align-items-center ali mt-3 mt-sm-0">
                                                        <div className="flex-shrink-0">
                                                            <div className="avatar-xl me-3">
                                                                <img
                                                                    src={avatar}
                                                                    alt=""
                                                                    className="img-fluid rounded-circle d-block"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <div>
                                                                <h5 className="font-size-16 mb-1">{this.state.data.user.first_name} {this.state.data.user.second_name}</h5>
                                                                <label className="mb-0">{this.state.data.user.email}</label>
                                                                {/* <p className="text-muted font-size-13">
                                                                    Full Stack Developer
                                                                </p>

                                                                <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                                                                    <div>
                                                                        <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                                                                        Development
                                                                    </div>
                                                                    <div>
                                                                        <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                                                                        phyllisgatlin@minia.com
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </Row>

                                            <Nav className="nav-tabs-custom card-header-tabs border-top mt-4">
                                                <NavItem>
                                                    <NavLink
                                                        to="#"
                                                        className={classnames(
                                                            {
                                                                active: this.state.activeTab === 1,
                                                            },
                                                            "px-3"
                                                        )}
                                                        onClick={() => {
                                                            this.setState({ activeTab: 1 });
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        Account Profile
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        to="#"
                                                        className={classnames(
                                                            {
                                                                active: this.state.activeTab === 2,
                                                            },
                                                            "px-3"
                                                        )}
                                                        onClick={() => {
                                                            this.setState({ activeTab: 2 });
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        Company Profile
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        to="#"
                                                        className={classnames(
                                                            {
                                                                active: this.state.activeTab === 3,
                                                            },
                                                            "px-3"
                                                        )}
                                                        onClick={() => {
                                                            this.setState({ activeTab: 3 });
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        Payments
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        to="#"
                                                        className={classnames(
                                                            {
                                                                active: this.state.activeTab === 4,
                                                            },
                                                            "px-3"
                                                        )}
                                                        onClick={() => {
                                                            this.setState({ activeTab: 4 });
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        Settings
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </CardBody>
                                    </Card>
                                    <TabContent className="user-settings" activeTab={this.state.activeTab}>
                                        <TabPane tabId={1}>
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle className="mb-0">Account Profile {" "}
                                                        <Link to="#" onClick={() => this.clickEditAcc()}>
                                                            <i className="fas fa-edit" data-toggle="tooltip" data-placement="bottom" title="Edit" style={{ color: '#28a745' }} />
                                                        </Link>

                                                    </CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    {this.state.accountProfile ? (
                                                        <div>
                                                            <AvForm onValidSubmit={this.handleValidSubmitAcc}>
                                                                <Row className="mb-2">
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Label
                                                                                htmlFor="horizontal-firstname-input"
                                                                                className="col-sm-12 col-form-label"
                                                                            >
                                                                                First name
                                                                            </Label>
                                                                            <Col sm={12}>
                                                                                <AvField
                                                                                    name="first_name"
                                                                                    type="text"
                                                                                    placeholder="Enter First name"
                                                                                    errorMessage="Enter First name"
                                                                                    value={this.state.data.user.first_name}

                                                                                    validate={{ required: { value: true } }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Label
                                                                                htmlFor="horizontal-firstname-input"
                                                                                className="col-sm-12 col-form-label"
                                                                            >
                                                                                Second Name
                                                                            </Label>
                                                                            <Col sm={12}>
                                                                                <AvField
                                                                                    name="second_name"
                                                                                    type="text"
                                                                                    placeholder="Enter Second Name"
                                                                                    errorMessage="Enter Second Name"
                                                                                    value={this.state.data.user.second_name}
                                                                                    validate={{ required: { value: true } }}
                                                                                />

                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Label
                                                                                htmlFor="horizontal-firstname-input"
                                                                                className="col-sm-12 col-form-label"
                                                                            >
                                                                                Email
                                                                            </Label>
                                                                            <Col sm={12}>
                                                                                <AvField
                                                                                    name="email"
                                                                                    type="email"
                                                                                    placeholder="Enter email"
                                                                                    errorMessage="Enter email"
                                                                                    value={this.state.data.user.email}
                                                                                    validate={{ required: { value: true } }}
                                                                                />

                                                                            </Col>
                                                                        </Row>
                                                                    </Col>

                                                                </Row>

                                                                <div className="d-flex flex-wrap gap-2">
                                                                    <Button type="submit" color="primary" className="">
                                                                        Submit
                                                                    </Button>{" "}
                                                                    <Button type="reset" color="secondary" className="" onClick={this.resetFormAcc}>
                                                                        Cancel
                                                                    </Button>
                                                                </div>
                                                            </AvForm>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <div className="pb-1">
                                                                <Row>
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Col xl={12}>
                                                                                <div>
                                                                                    <h5 className="font-size-15">First Name</h5>
                                                                                </div>
                                                                            </Col>
                                                                            <Col xl={12}>
                                                                                <div className="text-muted">
                                                                                    <p className="mb-2">{this.state.data.user.first_name}</p>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Col xl={12}>
                                                                                <div>
                                                                                    <h5 className="font-size-15">Last Name</h5>
                                                                                </div>
                                                                            </Col>
                                                                            <Col xl={12}>
                                                                                <div className="text-muted">
                                                                                    <p className="mb-2">{this.state.data.user.second_name}</p>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Col xl={12}>
                                                                                <div>
                                                                                    <h5 className="font-size-15">Email</h5>
                                                                                </div>
                                                                            </Col>
                                                                            <Col xl={12}>
                                                                                <div className="text-muted">
                                                                                    <p className="mb-2">{this.state.data.user.email}</p>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        {/* <Row>
                                                                            <Col xl={12}>
                                                                                <div>
                                                                                    <h5 className="font-size-15">Designation</h5>
                                                                                </div>
                                                                            </Col>
                                                                            <Col xl={12}>
                                                                                <div className="text-muted">
                                                                                    <p className="mb-2">{this.state.data.user.designation}</p>
                                                                                </div>
                                                                            </Col>
                                                                        </Row> */}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    )}

                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle className="mb-0">Plan Details</CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    {this.state.data.orders.map((order: LoopActions, index: number) => {
                                                        if (index == 0) {
                                                            return (
                                                                <div key={index}>
                                                                    <div className="pb-1">
                                                                        <Row>
                                                                            <Col lg={3}>
                                                                                <div>
                                                                                    <h5 className="font-size-15">Plan Name</h5>
                                                                                </div>
                                                                                <div className="col-xl">
                                                                                    <div className="text-muted">
                                                                                        <p className="mb-2">{order.plan_name}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={3}>
                                                                                <div>
                                                                                    <h5 className="font-size-15">Payment Amount</h5>
                                                                                </div>
                                                                                <div className="text-muted">
                                                                                    <p>{order.payment_amount}</p>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={3}>
                                                                                <h5 className="font-size-15">Valid From</h5>
                                                                                <div className="text-muted">
                                                                                    <p>{order.valid_from}</p>
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg={3}>
                                                                                <h5 className="font-size-15">Valid To</h5>
                                                                                <div className="text-muted">
                                                                                    <p>{order.valid_to}</p>
                                                                                </div>
                                                                            </Col>

                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                    }



                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        <TabPane tabId={2}>
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle className="mb-0">Company Details
                                                        {" "}
                                                        <Link to="#" onClick={() => this.clickEditComp()}>
                                                            <i className="fas fa-edit" data-toggle="tooltip" data-placement="bottom" title="Edit" style={{ color: '#28a745' }} />
                                                        </Link>
                                                    </CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    {this.state.companyProfile ? (
                                                        <div>
                                                            <AvForm onValidSubmit={this.handleValidSubmitComp} encType='multipart/formData' id="companyForm" >

                                                                <Row>
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Label
                                                                                htmlFor="horizontal-firstname-input"
                                                                                className="col-sm-12 col-form-label"
                                                                            >
                                                                                Company Name
                                                                            </Label>
                                                                            <Col sm={12}>
                                                                                <AvField
                                                                                    name="company_name"
                                                                                    type="text"
                                                                                    placeholder="Enter Company Name"
                                                                                    errorMessage="Enter Company Name"
                                                                                    value={this.state.data.company.company_name}
                                                                                    validate={{ required: { value: true } }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Label
                                                                                htmlFor="horizontal-firstname-input"
                                                                                className="col-sm-12 col-form-label"
                                                                            >
                                                                                Business Name
                                                                            </Label>
                                                                            <Col sm={12}>
                                                                                <AvField
                                                                                    name="business_name"
                                                                                    type="text"
                                                                                    placeholder="Enter Business Name"
                                                                                    errorMessage="Enter Business Name"
                                                                                    value={this.state.data.company.business_name}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>

                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Label
                                                                                htmlFor="horizontal-firstname-input"
                                                                                className="col-sm-12 col-form-label"
                                                                            >
                                                                                Flat, House no., Building, Apartment
                                                                            </Label>
                                                                            <Col sm={12}>
                                                                                <AvField
                                                                                    name="company_address1"
                                                                                    type="text"
                                                                                    placeholder="Enter Company Address"
                                                                                    errorMessage="Enter Company Address"
                                                                                    value={this.state.data.company.company_address1}
                                                                                    validate={{ required: { value: true } }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>

                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Label
                                                                                htmlFor="horizontal-firstname-input"
                                                                                className="col-sm-12 col-form-label"
                                                                            >
                                                                                Area, Street, Sector, Village
                                                                            </Label>
                                                                            <Col sm={12}>
                                                                                <AvField
                                                                                    name="company_address2"
                                                                                    type="text"
                                                                                    placeholder="Enter Company Address"
                                                                                    errorMessage="Enter Company Address"
                                                                                    value={this.state.data.company.company_address2}
                                                                                    validate={{ required: { value: true } }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>

                                                                </Row>

                                                                <Row>
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Label
                                                                                htmlFor="horizontal-firstname-input"
                                                                                className="col-sm-12 col-form-label"
                                                                            >
                                                                                Town/City
                                                                            </Label>
                                                                            <Col sm={12}>
                                                                                <AvField
                                                                                    name="company_town_or_city"
                                                                                    type="text"
                                                                                    placeholder="Enter Town Or City"
                                                                                    errorMessage="Enter Town Or City"
                                                                                    value={this.state.data.company.company_town_or_city}
                                                                                    validate={{ required: { value: true } }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Label
                                                                                htmlFor="horizontal-firstname-input"
                                                                                className="col-sm-12 col-form-label"
                                                                            >
                                                                                Country
                                                                            </Label>
                                                                            <Col sm={12}>
                                                                                <AvField type="select" name="company_country" value={this.state.data.company.company_country} validate={{ required: { value: true } }}
                                                                                    onChange={this.onChangeCountryhandler}
                                                                                >
                                                                                    <option key={-1} value={""}>--Select--</option>
                                                                                    {this.state.data.countries.map((country) => (
                                                                                        <option value={country.id} key={country.id}>{country.name}</option>
                                                                                    ))}
                                                                                </AvField>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Label
                                                                                htmlFor="horizontal-firstname-input"
                                                                                className="col-sm-12 col-form-label"
                                                                            >
                                                                                State / Province
                                                                            </Label>
                                                                            <Col sm={12}>
                                                                                {this.state.is_indian_state ?
                                                                                    (
                                                                                        <AvField type="select" name="company_state" value={this.state.data.company.company_state} validate={{ required: { value: true } }} >
                                                                                            <option key={-1} value={""}>--Select--</option>
                                                                                            {this.state.data.states.map((state) => (
                                                                                                <option value={state.id} key={state.id}>{state.state}</option>
                                                                                            ))}
                                                                                        </AvField>
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        <AvField
                                                                                            name="company_state"
                                                                                            type="text"
                                                                                            placeholder="Enter company state"
                                                                                            errorMessage="Enter company state"
                                                                                            value={this.state.data.company.company_state_name}
                                                                                            validate={{ required: { value: true } }}
                                                                                        />
                                                                                    )
                                                                                }


                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Label
                                                                                htmlFor="horizontal-firstname-input"
                                                                                className="col-sm-12 col-form-label"
                                                                            >
                                                                                Pincode
                                                                            </Label>
                                                                            <Col sm={12}>
                                                                                <AvField
                                                                                    name="company_pincode"
                                                                                    type="text"
                                                                                    placeholder="Enter Pincode"
                                                                                    errorMessage="Enter Pincode"
                                                                                    value={this.state.data.company.company_pincode}
                                                                                    validate={{ required: { value: true } }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Label
                                                                                htmlFor="horizontal-firstname-input"
                                                                                className="col-sm-12 col-form-label"
                                                                            >
                                                                                Phone Number
                                                                            </Label>
                                                                            <Col sm={12}>
                                                                                <AvField
                                                                                    name="company_telephone"
                                                                                    type="text"
                                                                                    placeholder="Enter Phone Number"
                                                                                    errorMessage="Enter Phone Number"
                                                                                    value={this.state.data.company.company_telephone}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Label
                                                                                htmlFor="horizontal-firstname-input"
                                                                                className="col-sm-12 col-form-label"
                                                                            >
                                                                                GSTIN
                                                                            </Label>
                                                                            <Col sm={12}>
                                                                                <AvField
                                                                                    name="gst_number"
                                                                                    type="text"
                                                                                    placeholder="Enter GSTIN"
                                                                                    errorMessage="Enter GSTIN"
                                                                                    value={this.state.data.company.gst_number}
                                                                                    validate={{ required: { value: true } }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Label
                                                                                htmlFor="horizontal-firstname-input"
                                                                                className="col-sm-12 col-form-label"
                                                                            >
                                                                                Business Type
                                                                            </Label>
                                                                            <Col sm={12}>
                                                                                <AvField type="select" name="business_type" value={this.state.data.company.business_type} >
                                                                                    <option key={-1} value={""}>--Select--</option>
                                                                                    {this.state.data.businessTypes.map((businessType) => (
                                                                                        <option value={businessType.id} key={businessType.id}>{businessType.type}</option>
                                                                                    ))}
                                                                                </AvField>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Label
                                                                                htmlFor="horizontal-firstname-input"
                                                                                className="col-sm-12 col-form-label"
                                                                            >
                                                                                Logo
                                                                            </Label>
                                                                            <Col sm={12}>
                                                                                <AvField
                                                                                    name="logo"
                                                                                    id="logo"
                                                                                    type="file"
                                                                                    placeholder="Choose Logo"
                                                                                    errorMessage="Choose Logo"
                                                                                    validate={{ required: { value: false } }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                <div className="d-flex flex-wrap gap-2" style={{marginTop:'15px'}}>
                                                                    <Button type="submit" color="primary" className="">
                                                                        Submit
                                                                    </Button>{" "}
                                                                    <Button type="reset" color="secondary" className="" onClick={this.resetFormComp}>
                                                                        Cancel
                                                                    </Button>
                                                                </div>
                                                            </AvForm>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <div className="pb-0">
                                                                <Row>
                                                                    <Col lg={3}>

                                                                        <h5 className="font-size-15">Company Name</h5>
                                                                        <div className="text-muted">
                                                                            <p className="mb-2">{this.state.data.company.company_name}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <h5 className="font-size-15">Business Name</h5>
                                                                        <div className="text-muted">
                                                                            <p className="mb-2">{this.state.data.company.business_name}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <h5 className="font-size-15">Phone Number</h5>
                                                                        <div className="text-muted">
                                                                            <p className="mb-2">{this.state.data.company.company_telephone}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <h5 className="font-size-15">Billing Address</h5>
                                                                        <div className="text-muted">
                                                                            <p className="mb-2">
                                                                                {this.state.data.company.company_address1} <br />
                                                                                {this.state.data.company.company_address2} <br />
                                                                                {this.state.data.company.company_town_or_city} – {this.state.data.company.company_pincode} <br />
                                                                                {this.state.data.company.company_state_name}
                                                                            </p>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg={3}>
                                                                        <h5 className="font-size-15">GSTIN</h5>
                                                                        <div className="text-muted">
                                                                            <p className="mb-2">{this.state.data.company.gst_number}</p>
                                                                        </div>
                                                                    </Col>
                                                                    {/* <Col lg={3}>
                                                                        <h5 className="font-size-15">Registration Number</h5>
                                                                        <div className="text-muted">
                                                                            <p className="mb-2">{this.state.data.company.registration_number}</p>
                                                                        </div>
                                                                    </Col> */}
                                                                    <Col lg={3}>
                                                                        <h5 className="font-size-15">Business Type</h5>
                                                                        <div className="text-muted">
                                                                            <p className="mb-2">
                                                                                {this.state.data.company.business_type_name}
                                                                            </p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <h5 className="font-size-15">Logo</h5>
                                                                        <div className="text-muted">
                                                                            <p className="mb-2">
                                                                                <img src={`${process.env.REACT_APP_IMG_URL}${this.state.data.company.logo}`} height="24" />
                                                                            </p>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>

                                                            {/*new */}



                                                        </div>
                                                    )}
                                                </CardBody>
                                            </Card>


                                        </TabPane>
                                        <TabPane tabId={3}>
                                            <div className="table-responsive">
                                                <Table className="table mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Payment Id</th>
                                                            <th>Plan Name</th>
                                                            <th>Payment Amount</th>
                                                            <th>Payment Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.data.orders.map((order: LoopActions, index: number) => (
                                                            <tr key={index}>
                                                                <th scope="row">{(index + 1)}</th>
                                                                <td>{order.razorpay_payment_id}</td>
                                                                <td>{order.plan_name}</td>
                                                                <td>{order.payment_amount}</td>
                                                                <td>{order.payment_date}</td>
                                                            </tr>
                                                        ))}


                                                    </tbody>
                                                </Table>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId={4}>
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle className="mb-0">Settings
                                                        {" "}
                                                        <Link to="#" onClick={() => this.clickEditCompSettings()}>
                                                            <i className="fas fa-edit" data-toggle="tooltip" data-placement="bottom" title="Edit" style={{ color: '#28a745' }} />
                                                        </Link>
                                                    </CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    {this.state.settingsProfile ? (
                                                        <div>
                                                            <AvForm onValidSubmit={this.handleValidSubmitCompSettings}>

                                                                <Row>
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Label
                                                                                htmlFor="horizontal-firstname-input"
                                                                                className="col-sm-12 col-form-label"
                                                                            >
                                                                                Estimate Number Prefix
                                                                            </Label>
                                                                            <Col sm={12}>
                                                                                <AvField
                                                                                    name="estimation_number_prefix"
                                                                                    type="text"
                                                                                    maxlength="5"
                                                                                    placeholder="Enter Estimate Number Prefix"
                                                                                    errorMessage="Enter Estimate Number Prefix"
                                                                                    value={this.state.data.settings.estimation_number_prefix}
                                                                                    validate={{ required: { value: true } }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Label
                                                                                htmlFor="horizontal-firstname-input"
                                                                                className="col-sm-12 col-form-label"
                                                                            >
                                                                                Invoice Number Prefix
                                                                            </Label>
                                                                            <Col sm={12}>
                                                                                <AvField
                                                                                    name="invoice_number_prefix"
                                                                                    type="text"
                                                                                    maxlength="5"
                                                                                    placeholder="Enter Invoice Number Prefix"
                                                                                    errorMessage="Enter Invoice Number Prefix"
                                                                                    value={this.state.data.settings.invoice_number_prefix}
                                                                                    validate={{ required: { value: true } }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <Row>
                                                                            <Label
                                                                                htmlFor="horizontal-firstname-input"
                                                                                className="col-sm-12 col-form-label"
                                                                            >
                                                                                Expense Number Prefix
                                                                            </Label>
                                                                            <Col sm={12}>
                                                                                <AvField
                                                                                    name="expense_number_prefix"
                                                                                    type="text"
                                                                                    maxlength="5"
                                                                                    placeholder="Entere Expense Number Prefix"
                                                                                    errorMessage="Enter Expense Number Prefix"
                                                                                    value={this.state.data.settings.expense_number_prefix}
                                                                                    validate={{ required: { value: true } }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <Label
                                                                            htmlFor="horizontal-firstname-input"
                                                                            className="col-sm-12 col-form-label"
                                                                        >
                                                                            Estimate Number Start
                                                                        </Label>
                                                                        <Col sm={12}>
                                                                            <AvField
                                                                                name="estimation_number_start"
                                                                                type="number"
                                                                                placeholder="Enter  Estimate Number Start"
                                                                                errorMessage="Enter  Estimate Number Start"
                                                                                value={this.state.data.settings.estimation_number_start}
                                                                                validate={{ required: { value: true } }}
                                                                            />
                                                                        </Col>
                                                                    </Col>

                                                                   
                                                                </Row>

                                                                <Row>
                                                                <Col lg={3}>
                                                                        <Label
                                                                            htmlFor="horizontal-firstname-input"
                                                                            className="col-sm-12 col-form-label"
                                                                        >
                                                                            Invoice Number Start
                                                                        </Label>
                                                                        <Col sm={12}>
                                                                            <AvField
                                                                                name="invoice_number_start"
                                                                                type="number"
                                                                                placeholder="Enter Invoice Number Start"
                                                                                errorMessage="Enter Invoice Number Start"
                                                                                value={this.state.data.settings.invoice_number_start}
                                                                                validate={{ required: { value: true } }}
                                                                            />
                                                                        </Col>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <Label
                                                                            htmlFor="horizontal-firstname-input"
                                                                            className="col-sm-12 col-form-label"
                                                                        >
                                                                            Expense Number Start
                                                                        </Label>
                                                                        <Col sm={12}>
                                                                            <AvField
                                                                                name="expense_number_start"
                                                                                type="number"
                                                                                placeholder="Enter Expense Number Start"
                                                                                errorMessage="Enter Expense Number Start"
                                                                                value={this.state.data.settings.expense_number_start}
                                                                                validate={{ required: { value: true } }}
                                                                            />
                                                                        </Col>
                                                                    </Col>
                                                                    <Col lg={6}>
                                                                        <Label
                                                                            htmlFor="horizontal-firstname-input"
                                                                            className="col-sm-12 col-form-label"
                                                                        >
                                                                            Terms and Conditions
                                                                        </Label>
                                                                        <Col sm={12}>
                                                                            <AvField
                                                                                name="terms_and_conditions"
                                                                                type="textarea"
                                                                                placeholder="Enter Terms and Conditions"
                                                                                value={this.state.data.settings.terms_and_conditions}
                                                                            />
                                                                        </Col>
                                                                    </Col>
                                                                    {/* <Col lg={3}>
                                                                        <Label
                                                                            htmlFor="horizontal-firstname-input"
                                                                            className="col-sm-12 col-form-label"
                                                                        >
                                                                            Invoice Theme colour - Light
                                                                        </Label>
                                                                        <Col sm={12}>
                                                                            <AvField
                                                                                name="invoice_light_color"
                                                                                type="color"
                                                                                className="form-control form-control-color mw-100"
                                                                                defaultValue="#5156be"
                                                                                title="Choose your color"
                                                                                value={this.state.data.settings.invoice_light_color}
                                                                            />
                                                                        </Col>
                                                                    </Col> */}
                                                                    <Col lg={3}>
                                                                        <Label
                                                                            htmlFor="horizontal-firstname-input"
                                                                            className="col-sm-12 col-form-label"
                                                                        >
                                                                            Invoice Theme colour
                                                                        </Label>
                                                                        <Col sm={12}>
                                                                            <AvField
                                                                                name="invoice_dark_color"
                                                                                type="color"
                                                                                className="form-control form-control-color mw-100"
                                                                                defaultValue="#5156be"
                                                                                title="Choose your color"
                                                                                value={this.state.data.settings.invoice_dark_color}
                                                                            />
                                                                        </Col>
                                                                    </Col>
                                                                    {/* <Col lg={3}>
                                                                        <Label
                                                                            htmlFor="horizontal-firstname-input"
                                                                            className="col-sm-12 col-form-label"
                                                                        >
                                                                            Invoice Theme
                                                                        </Label>
                                                                        <Col sm={12}>
                                                                            <AvField type="select" name="invoice_template" errorMessage="Choose template"
                                                                                validate={{ required: { value: true } }} value={this.state.data.settings.invoice_template}  >
                                                                                <option value={1} key={1} >Template 1</option>   
                                                                                <option value={2} key={2} >Template 2</option>                                                                             
                                                                            </AvField>
                                                                        </Col>
                                                                    </Col> */}
                                                                </Row>
                                                                {/* <Row className="mb-4">
                                                                    <Label
                                                                        htmlFor="horizontal-firstname-input"
                                                                        className="col-sm-2 col-form-label"
                                                                    >
                                                                        Signature
                                                                    </Label>
                                                                    <Col sm={4}>
                                                                        <AvField
                                                                            name="signature"
                                                                            type="file"
                                                                        />
                                                                    </Col>
                                                                </Row> */}


                                                                <div className="d-flex flex-wrap gap-2" style={{marginTop:'15px'}}>
                                                                    <Button type="submit" color="primary" className="">
                                                                        Submit
                                                                    </Button>{" "}
                                                                    <Button type="reset" color="secondary" className="" onClick={this.resetFormCompSettings}>
                                                                        Cancel
                                                                    </Button>
                                                                </div>
                                                            </AvForm>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <div className="pb-3">
                                                                <Row>
                                                                    <Col lg={3}>

                                                                        <h5 className="font-size-15">Estimate Number Prefix</h5>
                                                                        <div className="text-muted">
                                                                            <p className="mb-2">{this.state.data.settings.estimation_number_prefix}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <h5 className="font-size-15">Invoice Number Prefix</h5>
                                                                        <div className="text-muted">
                                                                            <p>{this.state.data.settings.invoice_number_prefix}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <h5 className="font-size-15">Expense Number Prefix</h5>
                                                                        <div className="text-muted">
                                                                            <p>{this.state.data.settings.expense_number_prefix}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <h5 className="font-size-15">Estimate Number Start</h5>
                                                                        <div className="text-muted">
                                                                            <p>{this.state.data.settings.estimation_number_start}</p>
                                                                        </div>
                                                                    </Col>                                                                    
                                                                </Row>
                                                                <Row>
                                                                <Col lg={3}>
                                                                        <h5 className="font-size-15">Invoice Number Start</h5>
                                                                        <div className="text-muted">
                                                                            <p>{this.state.data.settings.invoice_number_start}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <h5 className="font-size-15">Expense Number Start</h5>
                                                                        <div className="text-muted">
                                                                            <p>{this.state.data.settings.expense_number_start}</p>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={3}>
                                                                        <h5 className="font-size-15">Terms and Conditions</h5>
                                                                        <div className="text-muted">
                                                                            <p>{this.state.data.settings.terms_and_conditions}</p>
                                                                        </div>
                                                                    </Col>
                                                                    {/* <Col lg={3}>
                                                                        <h5 className="font-size-15">Invoice Theme colour - Light</h5>
                                                                        <div className="text-muted">
                                                                            <p><div style={{ width: "10%", backgroundColor: this.state.data.settings.invoice_light_color }}>&nbsp;</div></p>
                                                                        </div>
                                                                    </Col> */}
                                                                    <Col lg={3}>
                                                                        <h5 className="font-size-15">Invoice Theme colour :</h5>
                                                                        <div className="text-muted">
                                                                            <p><div style={{ width: "10%", backgroundColor: this.state.data.settings.invoice_dark_color }}>&nbsp;</div></p>
                                                                        </div>
                                                                    </Col>
                                                                    {/* <Col lg={3}>
                                                                        <h5 className="font-size-15">Invoice Theme </h5>
                                                                        <div className="text-muted">
                                                                            <p>Template {this.state.data.settings.invoice_template}</p>
                                                                        </div>
                                                                    </Col> */}
                                                                </Row>
                                                            </div>


                                                            {/* <div className="py-3">
                                                                <Row>
                                                                    <Col xl={2}>
                                                                        <div>
                                                                            <h5 className="font-size-15">Signature :</h5>
                                                                        </div>
                                                                    </Col>
                                                                    <div className="col-xl">
                                                                        <div className="text-muted">
                                                                            <p></p>
                                                                        </div>
                                                                    </div>
                                                                </Row>
                                                            </div> */}

                                                        </div>
                                                    )}
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                    </TabContent>
                                </Col >
                            </Row >
                        </Row >
                    </Container >
                </div >
            </React.Fragment >
        )
    }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {   
    return{
        getCompanies: () => {
            dispatch(getCompanies());
        }
    }
}
export default connect(null,mapDispatchToProps)(withRouter(CompanyProfile)); 
