import React, { useCallback, useState } from "react";
import { useHistory } from 'react-router-dom';

import { Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import MetaTags from "react-meta-tags";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Datatable from "../Datatable";
import { post, del } from "src/helpers/api_helper";
import { Link } from "react-router-dom";
import CustomerList from "./CustomerList";

const Customers = () => {  

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Customers</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Customers" breadcrumbItem="Customers List" />
          <Row>
          
          <CustomerList />
          </Row>
        </div>
      </div>      
    </React.Fragment>
  );
};

export default Customers;
