import React, { Component, Dispatch } from "react";
import MetaTags from "react-meta-tags";
import { Container, Table } from "reactstrap";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Button, Alert, Modal } from "reactstrap";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import Creatable, { useCreatable } from 'react-select/creatable';
import CreatableSelect from 'react-select/creatable';

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post, get } from "src/helpers/api_helper";
import { ActionMeta, OnChangeValue, Options } from "react-select/dist/declarations/src";
import CustomerCreateModal from "../Customers/CustomerCreateModal";
import ProductCreateModal from "../Products/ProductCreateModal";
import Moment from 'moment';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import RenewPlan from "../Payments/RenewPlan";
import { isNull, round } from "lodash";

import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { PermissionModule } from "src/store/permissions/actionTypes";

interface Params extends RouteComponentProps {    
    countries: Array<any>;
    units: Array<any>;
    taxes: Array<any>;
    states: Array<any>;
    customers: Array<any>;
    products: Array<any>;
    permissions: Array<any>;
    companies: Company;
}
interface Option {
    label: string;
    value: string;
}
interface State {
    isLoading: boolean;
    options: Option[];
}

interface Company {
    is_indian_state: number;
    company_state: string;
    company_country: string;
    currency: string;
}

interface States extends State {
    id: string;
    invoice_dtls: Array<any>;
    tax_dtls: Array<any>;
    products: Array<any>;
    customers: Array<any>;
    company: Company;
    taxes: Array<any>;
    net_total: number;
    tax_id: number;
    // discount_type: string; 
    discount: number;
    gross_total: number;
    gross_total_base: number;
    invoice_number: number;
    invoice_prefix: string;
    data: Array<any>;
    success: number;
    msg: string;
    isLoading: boolean;
    options: Array<any>;
    isLoad: boolean;
    optionproduct: Array<any>;
    valueProduct: Option | null | undefined;
    valueCustomer: Option | null | undefined;
    newCustomerName: string;
    newProductName: string;
    modal_cust_is_open: boolean;
    modal_pro_is_open: boolean;
    is_success: boolean;
    isProgress: number;
    isModalProgress: number;
    isValid: boolean;
    cust_name: string;
    cust_id: string;
    prod_name: string;
    prod_id: string;
    units: Array<any>;
    tax_amount: number;
    customer_currency: string;
    company_currency: string;
    currency_rate: number;
    permissions: Array<any>;
    billingAddress: string;
    shippingAddress: string;
    countries: Array<any>;
    states: Array<any>;
    loading: boolean;
    credit_period: number;
    isPlanExpired: number;
    modalPlan: boolean;
};
const createOption = (label: string, value: string) => ({
    label,
    value,
});

const createOptionProduct = (label: string, value: string) => ({
    label,
    value,
});

class InvoiceCreate extends Component<Params, States> {

    constructor(props: any) {
        super(props);
        this.state = {
            id: "0",
            net_total: 0,
            tax_id: 0,
            // discount_type: "amount",
            discount: 0,
            gross_total: 0,
            gross_total_base: 0,
            invoice_dtls: [
                { product_id: { label: null, value: null }, product_description: "", hsn_or_sac: "", qty: 0, rate: 0, discount_per: 0, discount_amt: 0, tax_id: '', tax_per: 0, tax_amt: 0, amount: 0 }
            ],
            tax_dtls: [],
            products: [],
            customers: [],
            company: { is_indian_state: 1, company_state: "", company_country: "",currency: "INR" },
            taxes: [],
            invoice_number: 0,
            invoice_prefix: "",
            data: [],
            success: 0,
            msg: "",
            isLoading: true,
            options: [],
            valueCustomer: undefined,
            isLoad: true,
            optionproduct: [],
            valueProduct: undefined,
            modal_cust_is_open: false,
            modal_pro_is_open: false,
            is_success: false,
            isProgress: 0,
            isModalProgress: 0,
            isValid: false,
            cust_name: "",
            cust_id: "",
            prod_name: "",
            prod_id: "",
            units: [],
            newCustomerName: "",
            newProductName: "",
            tax_amount: 0,
            customer_currency: "INR",
            company_currency: "INR",
            currency_rate: 0.00,
            permissions: [],
            billingAddress: "",
            shippingAddress: "",
            countries: [],
            states: [],
            loading: false,
            isPlanExpired: 0,
            credit_period: 0,
            modalPlan: false
        };
        this.onChangehandlerDtl = this.onChangehandlerDtl.bind(this);
        this.onChangehandler = this.onChangehandler.bind(this);
    }

    openModalPlan = () => {
        this.setState({ modalPlan: true });
    }

    closeModalPlan = () => {
        this.setState({ modalPlan: false });
    }

    successModalPlan = () => {
        this.setState({ isPlanExpired: 1, modalPlan: false })

        const getAuthUser: any = localStorage.getItem("authUser");
        const objUser = JSON.parse(getAuthUser);
        objUser.isPlanExpired = 1;
        localStorage.setItem("authUser", JSON.stringify(objUser));
    }

    resetForm = () => {
        this.props.history.push("/invoices");
    }

    onChangehandlerestimation = async (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        const respE: any = await get(process.env.REACT_APP_API_URL + '/api/check_invoice_no/' + value);
        if (respE.success == true) {
            this.setState({
                success: 1
            });
        }
        else {
            this.setState({
                success: 2
            });

        }
    }

    onChangehandler = async (e: any) => {
        let name = e.target.name;
        let value = e.target.value;

        /* if (name === "discount" && value) {
            let discAmt = parseFloat(value);
            this.setState({ ...this.state, [name]: value });
        } else {
            this.setState({ ...this.state, [name]: value });
        } */
        this.calcTotal();
    }

    calcTotal = async () => {
        const dtls = this.state.invoice_dtls;
        const net_total: number = await dtls.reduce((Pre, Cur) => Pre = parseFloat(Pre) + parseFloat(Cur.amount), 0);

        //tax calculation        
        const company = await this.state.company;
        const comp_is_indian_state: number = company.is_indian_state;
        const company_state: string = company.company_state;

        if (this.state.valueCustomer) {
            const customer = await this.props.customers.find(({ id }) => id === this.state.valueCustomer?.value);

            const billing_is_indian_state: number = customer.billing_is_indian_state;
            const billing_country: string = customer.billing_country;
            const billing_state: string = customer.billing_state;
            const place_supply: number = customer.place_supply;

            // if (comp_is_indian_state && billing_is_indian_state) {
            if (place_supply == 1) {
                let tax_dtls: Array<any> = [];
                dtls.forEach(async (item) => {

                    let product: any = this.state.products.find(({ id }) => id === item.product_id.value);
                    let taxes: Array<any> = [];

                    if (product) {
                        if (company_state == billing_state) {
                            let rowTax: any = this.state.taxes.find((tax: any) => tax.id == item.tax_id);
                            if (rowTax) {
                                taxes = rowTax.dtl.filter((tax: any) => tax.is_intra == 0);
                            }
                            // taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 0);

                        } else {
                            let rowTax: any = this.state.taxes.find((tax: any) => tax.id == item.tax_id);
                            if (rowTax) {
                                taxes = rowTax.dtl.filter((tax: any) => tax.is_intra == 1);
                            }
                            // taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 1);

                        }

                        if (item.qty > 0 && item.rate > 0) {
                            taxes.forEach(async (tax) => {
                                let split_name = tax.split_name;
                                let split_per = tax.split_per;
                                let row_total = (item.qty * item.rate - parseFloat(item.discount_amt));
                                let tax_amount = (row_total * split_per) / 100;

                                let newRaw = { label: split_name, tax_rate: split_per, tax_amount: tax_amount };
                                tax_dtls.push(newRaw);
                            });
                        }
                    }
                })


                //sum the tax per       
                let tax_dtls2: Array<any> = [];
                tax_dtls.forEach((a, index) => {
                    let tax_amount = tax_dtls.filter((el) => {
                        return el.label == a.label && el.tax_rate == a.tax_rate
                    }).reduce((Pre, Cur) => Pre = parseFloat(Pre) + parseFloat(Cur.tax_amount), 0);

                    if (isNaN(tax_amount)) {
                        tax_amount = 0;
                    }

                    tax_dtls2.push({ label: a.label, tax_rate: a.tax_rate, tax_amount: tax_amount });
                });


                tax_dtls = tax_dtls2.filter((arr, index, self) =>
                    index === self.findIndex((t) => (t.label === arr.label && t.tax_rate === arr.tax_rate)))


                this.setState({ tax_dtls: tax_dtls });
            }
        }

        const tax_amount: number = await this.state.tax_dtls.reduce((Pre, Cur) => Pre = parseFloat(Pre) + parseFloat(Cur.tax_amount), 0);
        this.setState({ tax_amount: tax_amount });
        // const discAmt: number = await this.state.discount_type == "per" ? (this.state.net_total * this.state.discount / 100) : this.state.discount;
        let discAmt: number = await round(this.state.invoice_dtls.reduce((Pre, Cur) => Pre = parseFloat(Pre) + parseFloat(Cur.discount_amt), 0), 2);
        if (isNaN(discAmt)) {
            discAmt = 0;
        }
        const gross_total: number = await (net_total /*+ tax_amount  - discAmt */);
        const inr_rate: number = (1 / this.state.currency_rate);
        const gross_total_base: number = this.state.currency_rate > 0 ? (gross_total * inr_rate) : 0.00;
        this.setState({ discount: discAmt, net_total: net_total, gross_total: gross_total, gross_total_base: gross_total_base });
    }

    addRow = () => {
        let newRaw = { product_id: { label: null, value: null }, product_description: "", hsn_or_sac: "", qty: 0, rate: 0, discount_per: 0, discount_amt: 0, tax_per: 0, tax_id: '', tax_amt: 0, amount: 0 }
        this.setState({ invoice_dtls: [...this.state.invoice_dtls, newRaw] });
    }

    removeRow = (i: number) => {
        const dtls = this.state.invoice_dtls;
        dtls.splice(i, 1);
        this.setState({ invoice_dtls: dtls });
        this.calcTotal();
    }

    handleValidSubmit = async (event: any, values: any) => {
        if (this.state.isPlanExpired) {
            this.setState({ isProgress: 1 });
            values["id"] = this.state.id;
            values["customer_currency"] = this.state.customer_currency;
            values["exchange_rate"] = this.state.currency_rate;
            values["gross_total_base"] = this.state.gross_total_base > 0 ? this.state.gross_total_base : this.state.gross_total;
            values["customer"] = this.state.valueCustomer?.value;
            values["dtls"] = this.state.invoice_dtls;
            values["tax_dtls"] = this.state.tax_dtls;
            values["net_total"] = this.state.net_total;
            values["discount"] = this.state.discount;
            values["tax_amount"] = this.state.tax_amount;
            values["gross_total"] = this.state.gross_total;
            const resp: any = await post(process.env.REACT_APP_API_URL + '/api/invoice_save', values);
            if (resp.success == true) {
                this.setState({
                    success: 1
                });
                this.props.history.push("/invoices");
            }
            else {
                this.setState({
                    success: 2
                });

            }
            this.setState({ isProgress: 0 });
        } else {
            this.openModalPlan();
        }

    }


    handlemodalProductSubmit = async (event: any, values: any) => {
        this.setState({
            isModalProgress: 1
        });
        const resp = await post(process.env.REACT_APP_API_URL + '/api/product_save', values);

        if (resp.success === true) {

            this.setState({ modal_pro_is_open: false });

            this.setState({ prod_name: resp.product_name, prod_id: resp.product_id });

            let newOptionproduct = createOptionProduct(this.state.prod_name, this.state.prod_id);

            this.setState({
                isLoad: false,
                optionproduct: [...this.state.optionproduct, newOptionproduct],
                valueProduct: newOptionproduct,
            });

        }
        else {


        }
    }


    handleCreate = (inputValue: string) => {

        this.setState({ isLoading: true, modal_cust_is_open: true, newCustomerName: inputValue });


        setTimeout(() => {
            const { options } = this.state;
            this.setState({
                isLoading: false,


            });
        }, 1000);
    };

    handleChange = async (
        newValue: OnChangeValue<Option, false>,
        actionMeta: ActionMeta<Option>
    ) => {
        let customer = this.props.customers.find(({ id }) => id === newValue?.value);
        var rate = 0.00;
        var customer_currency = "INR";
        if (customer) {
            if (this.state.company_currency != customer.currency) {
                const respR: any = await get(process.env.REACT_APP_API_URL + `/api/get_currency_rate/${customer.currency}`);
                rate = respR.data.rate;
                customer_currency = customer.currency;
            }
            let billing_state = "";
            let shipping_state = "";
            if (customer.billing_country == '99') {
                billing_state = this.state.states.find(({ id }) => id === customer.billing_state).state;
            } else {
                billing_state = customer.billing_state;
            }
            if (customer.shipping_country == '99') {
                shipping_state = this.state.states.find(({ id }) => id === customer.shipping_state).state;
            } else {
                shipping_state = customer.shipping_state;
            }
            let billing_country = this.state.countries.find(({ id }) => id === customer.billing_country);
            let shipping_country = this.state.countries.find(({ id }) => id === customer.shipping_country);

            let billing_address = '';
            let shipping_address = '';

            if (!isNull(customer.billing_address1)) {
                billing_address += `${customer.billing_address1}\n`;
            }
            if (!isNull(customer.billing_address2)) {
                billing_address += `${customer.billing_address2}\n`;
            }
            if (!isNull(customer.billing_town_or_city)) {
                billing_address += `${customer.billing_town_or_city}\n`;
            }
            billing_address += `${billing_state}-`;
            if (!isNull(customer.billing_pincode)) {
                billing_address += `${customer.billing_pincode}\n`;
            }
            billing_address += `${billing_country.name}`;

            if (!isNull(customer.shipping_address1)) {
                shipping_address += `${customer.shipping_address1}\n`;
            }
            if (!isNull(customer.shipping_address2)) {
                shipping_address += `${customer.shipping_address2}\n`;
            }
            if (!isNull(customer.shipping_town_or_city)) {
                shipping_address += `${customer.shipping_town_or_city}\n`;
            }
            shipping_address += `${shipping_state}-`;
            if (!isNull(customer.shipping_pincode)) {
                shipping_address += `${customer.shipping_pincode}\n`;
            }
            shipping_address += `${shipping_country.name}`;

            this.setState({ billingAddress: billing_address, shippingAddress: shipping_address, credit_period: Number(customer.credit_period) });
        } else {
            this.setState({
                billingAddress: '', shippingAddress: '', credit_period: 0, invoice_dtls: [
                    { product_id: { label: null, value: null }, product_description: "", hsn_or_sac: "", qty: 0, rate: 0, discount_per: 0, discount_amt: 0, tax_per: 0, tax_amt: 0, amount: 0 }
                ]
            });
        }


        this.setState({ valueCustomer: newValue, customer_currency: customer_currency, currency_rate: rate });
        this.calcTotal();
    };

    handleProductCreate = (inputValue: string) => {
        this.setState({ isLoad: true, modal_pro_is_open: true });

        this.setState({
            isLoad: false,
            newProductName: inputValue
        });

    };

    onChangeCountryhandler = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;

    }

    openModal = () => {
        this.setState({ modal_cust_is_open: true });
    }

    closeModal = () => {
        this.setState({ modal_cust_is_open: false });
    }

    handleCallback = async (customer_param: { customer_name: string, id: string }) => {
        let newOption = createOption(customer_param.customer_name, customer_param.id);

        const respC: any = await get(process.env.REACT_APP_API_URL + '/api/customers');
        this.setState({ customers: respC.data });

        this.setState({
            isLoading: false,
            options: [...this.state.options, newOption],
            valueCustomer: newOption,
            modal_cust_is_open: false
        });

        let customer = this.state.customers.find(({ id }) => id === newOption?.value);
        var rate = 0.00;
        var customer_currency = "INR";
        if (customer) {
            if (this.state.company_currency != customer.currency) {
                const respR: any = await get(process.env.REACT_APP_API_URL + `/api/get_currency_rate/${customer.currency}`);
                rate = respR.data.rate;
                customer_currency = customer.currency;
            }
            let billing_state = "";
            let shipping_state = "";
            if (customer.billing_country == '99') {
                billing_state = this.state.states.find(({ id }) => id === customer.billing_state).state;
            } else {
                billing_state = customer.billing_state;
            }
            if (customer.shipping_country == '99') {
                shipping_state = this.state.states.find(({ id }) => id === customer.shipping_state).state;
            } else {
                shipping_state = customer.shipping_state;
            }
            let billing_country = this.state.countries.find(({ id }) => id === customer.billing_country);
            let shipping_country = this.state.countries.find(({ id }) => id === customer.shipping_country);

            let billing_address = '';
            let shipping_address = '';

            if (!isNull(customer.billing_address1)) {
                billing_address += `${customer.billing_address1}\n`;
            }
            if (!isNull(customer.billing_address2)) {
                billing_address += `${customer.billing_address2}\n`;
            }
            if (!isNull(customer.billing_town_or_city)) {
                billing_address += `${customer.billing_town_or_city}\n`;
            }
            billing_address += `${billing_state}-`;
            if (!isNull(customer.billing_pincode)) {
                billing_address += `${customer.billing_pincode}\n`;
            }
            billing_address += `${billing_country.name}`;

            if (!isNull(customer.shipping_address1)) {
                shipping_address += `${customer.shipping_address1}\n`;
            }
            if (!isNull(customer.shipping_address2)) {
                shipping_address += `${customer.shipping_address2}\n`;
            }
            if (!isNull(customer.shipping_town_or_city)) {
                shipping_address += `${customer.shipping_town_or_city}\n`;
            }
            shipping_address += `${shipping_state}-`;
            if (!isNull(customer.shipping_pincode)) {
                shipping_address += `${customer.shipping_pincode}\n`;
            }
            shipping_address += `${shipping_country.name}`;

            this.setState({ billingAddress: billing_address, shippingAddress: shipping_address, credit_period: Number(customer.credit_period) });
        } else {
            this.setState({
                billingAddress: '', shippingAddress: '', credit_period: 0, invoice_dtls: [
                    { product_id: { label: null, value: null }, product_description: "", hsn_or_sac: "", qty: 0, rate: 0, discount_per: 0, discount_amt: 0, tax_per: 0, tax_amt: 0, amount: 0 }
                ]
            });
        }

    }

    handleCallbackPdt = async (product: { name: string, id: string }) => {
        let newOptionproduct = createOptionProduct(product.name, product.id);

        const respP: any = await get(process.env.REACT_APP_API_URL + '/api/products');

        this.setState({ products: respP.data });

        this.setState({
            isLoad: false,
            optionproduct: [...this.state.optionproduct, newOptionproduct],
            valueProduct: newOptionproduct,
            modal_pro_is_open: false
        });
    }

    openProductModal = () => {
        this.setState({ modal_pro_is_open: true });
    }
    closeProductModal = () => {
        this.setState({
            newProductName: ""
        })
        this.setState({ modal_pro_is_open: false });
    }

    handleProductChange = async (newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>, i: number, dtl: any) => {

        this.setState({ valueProduct: newValue });
        let dtls = this.state.invoice_dtls;
        let value = newValue?.value;
        let product = this.state.products.find(({ id }) => id === value);

        if (product) {
            if (this.state.valueCustomer) {
                const company = this.state.company;
                const comp_is_indian_state: number = company.is_indian_state;
                const company_state: string = company.company_state;


                let taxes: Array<any> = [];
                const customer = await this.props.customers.find(({ id }) => id === this.state.valueCustomer?.value);
                const billing_state: string = customer.billing_state;
                const place_supply: number = customer.place_supply;

                // if (comp_is_indian_state) {
                if (place_supply == 1) {
                    if (company_state == billing_state) {
                        taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 0);
                    } else {
                        taxes = product.tax.dtl.filter((tax: any) => tax.is_intra == 1);
                    }
                    dtl["tax_per"] = taxes.reduce((Pre, Cur) => Pre = parseFloat(Pre) + parseFloat(Cur.split_per), 0);
                } else {
                    dtl["tax_per"] = 0;
                }
            }

            dtl["rate"] = this.state.currency_rate > 0 ? (this.state.currency_rate * product.rate) : product.rate;
            dtl["hsn_or_sac"] = product.hsn_or_sac;
            dtl["product_description"] = product.description;
            if (dtl.qty > 0) {
                dtl["amount"] = round((dtl.qty * dtl.rate), 2);
            } else {
                dtl["qty"] = 1;
                dtl["amount"] = round((1 * dtl.rate), 2);
            }

            //new code 
            dtl["tax_amt"] = round((dtl["amount"] * dtl.tax_per / 100), 2);
            dtl["amount"] = parseFloat(dtl["amount"]) + parseFloat(dtl["tax_amt"]);

            dtl["tax_id"] = product.tax_id;
            dtl["tax_per"] = dtl.tax_per;

            dtl["product_id"] = newValue;
        } else {
            dtl["qty"] = 0;
            dtl["rate"] = 0;
            dtl["amount"] = 0;
            dtl["tax_amt"] = 0;
            dtl["tax_id"] = '';
            dtl["tax_per"] = 0;
            dtl["hsn_or_sac"] = "";
            dtl["product_description"] = "";
            dtl["product_id"] = { label: "", value: "" };
        }

        dtls[i] = dtl;
        this.setState({ invoice_dtls: dtls });
        this.calcTotal();
    };

    onChangehandlerDtl = (e: any, i: number, dtl: any) => {
        let name = e.target.name;
        let value = e.target.value;
        let dtls = this.state.invoice_dtls;

        if (name === "tax_id") {
            dtl["tax_id"] = value;
            let product = this.state.products.find(({ id }) => id === dtl.product_id.value);
            if (product) {
                let taxes: Array<any> = [];
                const company = this.state.company;
                const company_state: string = company.company_state;

                const customer = this.props.customers.find(({ id }) => id === this.state.valueCustomer?.value);
                const place_supply: number = customer.place_supply;
                const billing_state: string = customer.billing_state;

                if (place_supply == 1 && value != '' ) {
                    if (company_state == billing_state) {
                        let rowTax: any = this.state.taxes.find((tax: any) => tax.id == value);
                        taxes = rowTax.dtl.filter((tax: any) => tax.is_intra == 0);
                    } else {
                        let rowTax: any = this.state.taxes.find((tax: any) => tax.id == value);
                        taxes = rowTax.dtl.filter((tax: any) => tax.is_intra == 1);
                    }
                    dtl["tax_per"] = taxes.reduce((Pre, Cur) => Pre = parseFloat(Pre) + parseFloat(Cur.split_per), 0);
                    dtl["tax_amt"] = round((((dtl.qty * dtl.rate) - dtl.discount_amt) * dtl["tax_per"] / 100), 2);
                    dtl["amount"] = round(((dtl.qty * dtl.rate) - dtl.discount_amt + dtl["tax_amt"]), 2);
                } else {
                    dtl["tax_per"] = 0;
                }
            }
        }
        if (name === "qty") {
            dtl["discount_amt"] = round(((value * dtl.rate) * dtl.discount_per / 100), 2);
            dtl["amount"] = round(((value * dtl.rate - dtl["discount_amt"])), 2);
            dtl["tax_amt"] = round((dtl["amount"] * dtl.tax_per / 100), 2);
            dtl["amount"] = round((parseFloat(dtl["amount"]) + parseFloat(dtl["tax_amt"])), 2);
        }
        if (name === "rate") {
            if (dtl.qty > 0) {
                dtl["discount_amt"] = round(((dtl.qty * value) * dtl.discount_per / 100), 2);
                dtl["amount"] = round(((dtl.qty * value - dtl["discount_amt"])), 2);
                dtl["tax_amt"] = round((dtl["amount"] * dtl.tax_per / 100), 2);
                dtl["amount"] = round((parseFloat(dtl["amount"]) + parseFloat(dtl["tax_amt"])), 2);
            }
        }
        if (name === "discount_per") {
            if ((dtl.qty * dtl.rate) > 0) {
                dtl["discount_amt"] = round(((dtl.qty * dtl.rate) * value / 100), 2);
                dtl["amount"] = (dtl.qty * dtl.rate) - dtl["discount_amt"];
                dtl["tax_amt"] = round((dtl["amount"] * dtl.tax_per / 100), 2);
                dtl["amount"] = round((parseFloat(dtl["amount"]) + parseFloat(dtl["tax_amt"])), 2);
            }
        }

        if (name === "discount_amt") {
            if ((dtl.qty * dtl.rate) > 0) {
                dtl["discount_per"] = round((value / (dtl.qty * dtl.rate) * 100), 2);
                dtl["amount"] = round((dtl.qty * dtl.rate) - value, 2);
                dtl["tax_amt"] = round((dtl["amount"] * dtl.tax_per / 100), 2)
                dtl["amount"] = round(parseFloat(dtl["amount"]) + parseFloat(dtl["tax_amt"]), 2);
            }
        }

        dtl[name] = value;
        dtls[i] = dtl;
        this.setState({ invoice_dtls: dtls });
        this.calcTotal();
    }

    loadData = async() => {
        try {                            
            
            const getAuthUser: any = localStorage.getItem("authUser");
            const obj = JSON.parse(getAuthUser);
            this.setState({ isPlanExpired: obj.isPlanExpired });

            // const respC: any = await get(process.env.REACT_APP_API_URL + '/api/customers');
            // this.setState({ customers: respC.data });
            let customers = this.props.customers;
            let customerOptions: Array<any> = [];                        
            
            // const respP: any = await get(process.env.REACT_APP_API_URL + '/api/products');
            // this.setState({ products: respP.data, isLoad: false });
            let products = this.props.products;
            let productOptions: Array<any> = [];            

             customers.map((customer) => {
                let newOption = createOption(customer.customer_name, customer.id);
                customerOptions = [...customerOptions, newOption];                
            }
            )           
            
            products.map((product) => {
                let newOption = createOptionProduct(product.name, product.id);
                productOptions = [...productOptions, newOption]; 
            }
            )

            this.setState({
                isLoading: false,
                options: customerOptions,
                optionproduct: productOptions,
                products: products,
                customers: customers,
                isLoad: false
            });

            // const respPer: any = await get(process.env.REACT_APP_API_URL + `/api/permissions/3`);
            // this.setState({ permissions: respPer.data });
            let invoicePermissions = this.props.permissions.filter(permission => permission.module_permission_id === PermissionModule.INVOICE);
            this.setState({ permissions: invoicePermissions });
            const permission = invoicePermissions.find(({ action, is_active }) => action == "create" && is_active == 1);

            if (!permission) {
                this.props.history.push("/invoices");
            }

            const respE: any = await get(process.env.REACT_APP_API_URL + '/api/get_invoice_no');

            this.setState({ invoice_number: respE.data.invoice_number });
            this.setState({ invoice_prefix: respE.data.invoice_prefix });            

            // const respCom: any = await get(process.env.REACT_APP_API_URL + '/api/company');
            // this.setState({ company: respCom.data, company_currency: respCom.data.currency });  
            const respCom: Company = this.props.companies; 
            this.setState({ company : respCom, company_currency: respCom.currency });            

            // const resp1: any = await get(process.env.REACT_APP_API_URL + '/api/units');
            // this.setState({ units: resp1.data });
            this.setState({ units: this.props.units });

            // const resp2: any = await get(process.env.REACT_APP_API_URL + '/api/taxes');
            // this.setState({ taxes: resp2.data });
            this.setState({ taxes: this.props.taxes });

            // const respCu: any = await get(process.env.REACT_APP_API_URL + '/api/countries');
            // this.setState({ countries: respCu.data });
            this.setState({ countries: this.props.countries });

            /* const respS: any = await get(process.env.REACT_APP_API_URL + '/api/states');
            this.setState({ states: respS.data }); */
            this.setState({ states: this.props.states });

            this.setState({ isLoading: false, loading: false });

        } catch (err) {
            console.error(err);
        }
    }
    /* componentWillReceiveProps(props: Params) {
        this.loadData();
    } */
    componentDidUpdate(prevProps: Params) {
        if (prevProps.customers.length != this.props.customers.length) {
            let customers = this.props.customers;
            let customerOptions: Array<any> = []; 

            customers.map((customer) => {
                let newOption = createOption(customer.customer_name, customer.id);
                customerOptions = [...customerOptions, newOption];                
            }
            )

            this.setState({                
                options: customerOptions,                
                customers: customers                
            });
        }
        if (prevProps.products.length != this.props.products.length) {
            let products = this.props.products;
            let productOptions: Array<any> = [];            

                       
            
            products.map((product) => {
                let newOption = createOptionProduct(product.name, product.id);
                productOptions = [...productOptions, newOption]; 
            }
            )

            this.setState({ 
                optionproduct: productOptions,
                products: products
            });
        }
    }
    componentDidMount() {
        this.loadData();
    }
    

    padLeadingZeros(num: number, size: number) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    render() {        
        
        return (
            <React.Fragment>

                <div className="page-content">
                    <MetaTags>
                        <title>Invoice</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="Create Invoice" breadcrumbItem="Create Invoice" />

                        <Row>
                            <Col className="col-12">

                                {this.state.success === 2 ?
                                    (
                                        <Alert color="danger">
                                            Invoice number cannot be duplicated. Please try another one.
                                        </Alert>


                                    ) : null

                                }

                                <AvForm onValidSubmit={this.handleValidSubmit}>
                                    <div className="form-head mb-3">
                                        <Row>
                                            <Col xl={6}>

                                                <div className="row">
                                                    <div className="col-lg-6">

                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <label htmlFor="progresspill-address-input">
                                                                    Invoice Number
                                                                </label>

                                                                <AvField
                                                                    name="invoice_number"
                                                                    type="text"
                                                                    placeholder="Enter Estmation Number"
                                                                    errorMessage="Enter Estmation Number"
                                                                    value={this.padLeadingZeros(this.state.invoice_number, 5)}
                                                                    onChange={this.onChangehandlerestimation}
                                                                    validate={{
                                                                        required: { value: true },
                                                                        pattern: { value: '[0-9]+', errorMessage: "Enter Number only" }
                                                                    }}
                                                                />

                                                            </>}
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            {this.state.loading ?
                                                                <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                                <>
                                                                    <label htmlFor="progresspill-address-input">
                                                                        Invoice Date
                                                                    </label>
                                                                    <AvField
                                                                        name="invoice_date"
                                                                        type="date"
                                                                        placeholder="Enter Invoice Date"
                                                                        errorMessage="Enter Invoice Date"
                                                                        value={Moment().format('YYYY-MM-DD')}
                                                                        validate={{ required: { value: true } }}
                                                                    />
                                                                </>
                                                            }
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            {this.state.loading ?
                                                                <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                                <>
                                                                    <label htmlFor="PO/ Estimate Number">
                                                                        PO/ Estimate Number
                                                                    </label>
                                                                    <AvField
                                                                        name="po_or_estimate_no"
                                                                        type="text"
                                                                        placeholder="PO/ Estimate Number"
                                                                        errorMessage="Enter PO/ Estimate Number"
                                                                        value={""}
                                                                    />
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mb-3">
                                                            {this.state.loading ?
                                                                <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                                <>
                                                                    <label htmlFor="progresspill-address-input">
                                                                        Due Date
                                                                    </label>
                                                                    <AvField
                                                                        name="due_date"
                                                                        type="date"
                                                                        placeholder="Enter Due Date"
                                                                        errorMessage="Enter Due Date"
                                                                        value={Moment().add(this.state.credit_period, 'days').format('YYYY-MM-DD')}

                                                                        validate={{ required: { value: true } }}
                                                                    />
                                                                </>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>

                                            </Col>
                                            <Col xl={6}>
                                                <Row>
                                                    <div className="col-lg-12 mb-3">
                                                        {this.state.isLoading ?
                                                            <Skeleton height={37} /> :
                                                            <>
                                                                <CreatableSelect
                                                                    isClearable={true}
                                                                    isDisabled={this.state.isLoading}
                                                                    isLoading={this.state.isLoading}
                                                                    onChange={this.handleChange}
                                                                    onCreateOption={this.handleCreate}
                                                                    options={this.state.options}
                                                                    value={this.state.valueCustomer}
                                                                    name="customer"
                                                                    styles={{
                                                                        control: (base, state) => ({
                                                                            ...base,
                                                                            "&:hover": {
                                                                                borderColor: "#7ECD00"
                                                                            },
                                                                            border: '2px solid #7ECD00'
                                                                        })
                                                                    }}
                                                                />
                                                                {/* <AvField tag={CreatableSelect}
                                                            name="customer"
                                                            isDisabled={this.state.isLoading}
                                                            isLoading={this.state.isLoading}
                                                            onChange={this.handleChange}
                                                            onCreateOption={this.handleCreate}
                                                            options={this.state.options}
                                                            value={this.state.valueCustomer}
                                                            placeholder="Please select or create customer"
                                                            errorMessage="Please select or create customer"
                                                            validate={{ required: { value: true } }}
                                                        /> */}
                                                            </>
                                                        }
                                                    </div>
                                                </Row>
                                                <Row>
                                                    <Col xl={6}>
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <div className="mb-3">
                                                                    <label htmlFor="billingAddress">
                                                                        Billing Address
                                                                    </label>
                                                                    <AvField
                                                                        name="billingAddress"
                                                                        type="textarea"
                                                                        placeholder="Billing Address"
                                                                        errorMessage="Billing Address"
                                                                        rows="4"
                                                                        validate={{ required: { value: false } }}
                                                                        readOnly={true}
                                                                        value={this.state.billingAddress}
                                                                    />
                                                                </div>
                                                            </>}
                                                    </Col>
                                                    <Col xl={6}>
                                                        {this.state.loading ?
                                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                            <>
                                                                <div className="mb-3">
                                                                    <label htmlFor="shippingAddress">
                                                                        Shipping Address
                                                                    </label>
                                                                    <AvField
                                                                        name="shippingAddress"
                                                                        type="textarea"
                                                                        placeholder="Shipping Address"
                                                                        errorMessage="Shipping Address"
                                                                        rows="4"
                                                                        validate={{ required: { value: false } }}
                                                                        value={this.state.shippingAddress}
                                                                    />
                                                                </div>
                                                            </>}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="row">
                                        <div className="table-responsive">
                                            {this.state.loading == true ? (
                                                <>
                                                    <Table className="table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th><Skeleton width={100} /></th>
                                                                <th><Skeleton width={100} /></th>
                                                                <th><Skeleton width={100} /></th>
                                                                <th><Skeleton width={100} /></th>
                                                                <th><Skeleton width={100} /></th>
                                                                <th><Skeleton width={100} /></th>
                                                                <th><Skeleton width={100} /></th>
                                                                <th><Skeleton width={100} /></th>
                                                                <th><Skeleton width={100} /></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td><Skeleton height={20} /></td>
                                                                <td><Skeleton height={20} /></td>
                                                                <td><Skeleton height={20} /></td>
                                                                <td><Skeleton height={20} /></td>
                                                                <td><Skeleton height={20} /></td>
                                                                <td><Skeleton height={20} /></td>
                                                                <td><Skeleton height={20} /></td>
                                                                <td><Skeleton height={20} /></td>
                                                                <td><Skeleton height={20} /></td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </>
                                            ) :
                                                (
                                                    <>
                                                        <Table className="table mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th style={{ width: "2%" }}>#</th>
                                                                    <th>Product/Service</th>
                                                                    <th style={{ width: "10%" }}>HSN/SAC</th>
                                                                    <th style={{ width: "10%" }}>Qty</th>
                                                                    <th style={{ width: "10%" }}>Rate({this.state.customer_currency})</th>
                                                                    <th style={{ width: "10%" }}>Discount</th>
                                                                    <th style={{ width: "10%" }}>Tax</th>
                                                                    <th style={{ width: "10%" }}>Amount({this.state.customer_currency})</th>
                                                                    <th style={{ width: "2%" }}>Remove</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.invoice_dtls.map((dtl, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <th scope="row" className="count">{(i + 1)}</th>
                                                                            <td className="slct-td">
                                                                                <Row>
                                                                                    <div className="mb-3">
                                                                                        {this.state.isLoad ?
                                                                                            <Skeleton height={37} /> :
                                                                                            <>
                                                                                                <CreatableSelect
                                                                                                    isClearable
                                                                                                    isDisabled={this.state.isLoad}
                                                                                                    isLoading={this.state.isLoad}
                                                                                                    onChange={(newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => this.handleProductChange(newValue, actionMeta, i, dtl)}
                                                                                                    onCreateOption={this.handleProductCreate}
                                                                                                    options={this.state.optionproduct}
                                                                                                    value={dtl.product_id}
                                                                                                    name="product_id"
                                                                                                    styles={{
                                                                                                        control: (base, state) => ({
                                                                                                            ...base,
                                                                                                            "&:hover": {
                                                                                                                borderColor: "#7ECD00"
                                                                                                            },
                                                                                                            border: '2px solid #7ECD00'
                                                                                                        })
                                                                                                    }}
                                                                                                />
                                                                                            </>
                                                                                        }
                                                                                        {/* <AvField tag={CreatableSelect}
                                                                                        // isClearable
                                                                                        isDisabled={this.state.isLoad}
                                                                                        isLoading={this.state.isLoad}
                                                                                        onChange={(newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => this.handleProductChange(newValue, actionMeta, i, dtl)}
                                                                                        onCreateOption={this.handleProductCreate}
                                                                                        options={this.state.optionproduct}
                                                                                        value={dtl.product_id}
                                                                                        name="product_id"
                                                                                        validate={{ required: { value: true } }}
                                                                                        placeholder="Select Or Create Product"
                                                                                        errorMessage="Select Or Create Product"
                                                                                    /> */}
                                                                                    </div>
                                                                                </Row>
                                                                                <Row>
                                                                                    <AvField
                                                                                        name="product_description"
                                                                                        type="text"
                                                                                        placeholder="Description"
                                                                                        value={dtl.product_description}
                                                                                        onChange={(e: any) => this.onChangehandlerDtl(e, i, dtl)}
                                                                                    />
                                                                                </Row>
                                                                            </td>
                                                                            <td>
                                                                                <AvField
                                                                                    name="hsn_or_sac"
                                                                                    type="text"
                                                                                    placeholder="HSN/SAC"
                                                                                    readOnly={true}
                                                                                    value={dtl.hsn_or_sac}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <AvField
                                                                                    name="qty"
                                                                                    type="number"
                                                                                    placeholder="Enter qty"
                                                                                    errorMessage="Enter qty"
                                                                                    value={dtl.qty}
                                                                                    validate={{ required: { value: false } }}
                                                                                    onChange={(e: any) => this.onChangehandlerDtl(e, i, dtl)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <AvField
                                                                                    name="rate"
                                                                                    type="number"
                                                                                    placeholder="Enter rate"
                                                                                    errorMessage="Enter rate"
                                                                                    value={dtl.rate}
                                                                                    validate={{ required: { value: false } }}
                                                                                    onChange={(e: any) => this.onChangehandlerDtl(e, i, dtl)}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Row>
                                                                                    <div className="mb-3">
                                                                                        <AvField
                                                                                            name="discount_amt"
                                                                                            type="number"
                                                                                            placeholder="Amount"
                                                                                            value={dtl.discount_amt}
                                                                                            onChange={(e: any) => this.onChangehandlerDtl(e, i, dtl)}
                                                                                        />

                                                                                    </div>
                                                                                </Row>
                                                                                <Row>
                                                                                    <AvField
                                                                                        name="discount_per"
                                                                                        type="number"
                                                                                        placeholder="%"
                                                                                        value={dtl.discount_per}
                                                                                        onChange={(e: any) => this.onChangehandlerDtl(e, i, dtl)}
                                                                                    />
                                                                                </Row>
                                                                            </td>
                                                                            <td>
                                                                                <Row>
                                                                                    <div className="mb-3">
                                                                                        <AvField
                                                                                            name="tax_amt"
                                                                                            type="number"
                                                                                            placeholder="Tax amount"
                                                                                            errorMessage="Tax amount"
                                                                                            value={dtl.tax_amt}
                                                                                            validate={{ required: { value: false } }}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </Row>
                                                                                <Row>
                                                                                    <AvField type="select" name="tax_id"
                                                                                        value={dtl.tax_id}
                                                                                        onChange={(e: any) => this.onChangehandlerDtl(e, i, dtl)}
                                                                                    >
                                                                                        <option value="" key="" >Tax Per</option>
                                                                                        {this.state.taxes.map((tax) => (
                                                                                            <option value={tax.id} key={tax.id} >{tax.tax_name}</option>
                                                                                        ))}
                                                                                    </AvField>
                                                                                </Row>
                                                                            </td>
                                                                            <td>
                                                                                <AvField
                                                                                    name="amount"
                                                                                    type="number"
                                                                                    placeholder="Enter amount"
                                                                                    errorMessage="Enter amount"
                                                                                    value={dtl.amount}
                                                                                    validate={{ required: { value: false } }}
                                                                                    readOnly={true}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Link to="#" className="btn tbl-btn" onClick={() => this.removeRow(i)}>
                                                                                    <i className="fas fa-trash"></i>
                                                                                </Link>
                                                                            </td>
                                                                        </tr>)
                                                                }
                                                                )}


                                                            </tbody>
                                                        </Table>
                                                    </>)}


                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-5 add-row">
                                                {this.state.loading == true ?
                                                    <Skeleton height={20} width={100} />
                                                    : <Link to="#" className="btn" onClick={() => this.addRow()}>
                                                        <i className="bx bx-plus me-1"></i> Add Row
                                                    </Link>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row justify-content-between">
                                        <div className="col-lg-4">
                                            <div className="mb-3">
                                                {this.state.loading ?
                                                    <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                    <>
                                                        <label htmlFor="progresspill-address-input">
                                                            Footer Note
                                                        </label>
                                                        <AvField
                                                            name="footer_note"
                                                            type="textarea"
                                                            placeholder="Enter footer note"
                                                            errorMessage="Enter footer note"
                                                            rows="4"
                                                            validate={{ required: { value: false } }}
                                                        />
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="total">
                                                {this.state.loading ?
                                                    <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                    <>
                                                        <ul>
                                                            <li>
                                                                <label>Gross Total ({this.state.customer_currency})</label>
                                                                <span>{this.state.gross_total}</span>
                                                            </li>
                                                            {this.state.currency_rate > 0 && (
                                                                <li>
                                                                    <label>Gross Total (INR)</label>
                                                                    <span className="bold">{this.state.gross_total_base}</span>
                                                                </li>
                                                            )}
                                                            {/* <li>
                                                                <label> Sub Total</label>
                                                                <span>{this.state.net_total}</span>
                                                            </li>
                                                            <li>
                                                                <label>Discount</label>
                                                                <span>{this.state.discount}</span>
                                                            </li> */}
                                                            {this.state.tax_dtls.length > 0 && (
                                                                <li>
                                                                    <label>Tax</label>
                                                                    <span>&nbsp;</span>
                                                                </li>
                                                            )}
                                                            {this.state.tax_dtls.map((tax, i) => {
                                                                return (
                                                                    <li key={i}>
                                                                        <label>{tax.label} {" "} ({tax.tax_rate}%)</label>
                                                                        <span>{tax.tax_amount.toFixed(2)}</span>
                                                                    </li>
                                                                )
                                                            })}
                                                            {/* <li>
                                                                <label>Tax Amount</label>
                                                                <span>{this.state.tax_amount}</span>
                                                            </li> */}

                                                        </ul>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-center gap-2 mb-4">
                                        {this.state.loading ?
                                            <><Skeleton width={100} height={37} />{" "}<Skeleton width={100} height={37} /></> :
                                            <>
                                                {this.state.isProgress ?
                                                    <>
                                                        <button type="submit" className="btn btn-primary " disabled={true}>
                                                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Submiting
                                                        </button>
                                                    </> :
                                                    <>
                                                        <Button type="submit" color="primary" className="">
                                                            Submit
                                                        </Button>{" "}
                                                        <Button type="reset" color="secondary" className="" onClick={this.resetForm}>
                                                            Cancel
                                                        </Button>
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </AvForm>


                            </Col>
                        </Row>
                    </div>
                    <div>
                        <CustomerCreateModal newCustomerName={this.state.newCustomerName} modal_cust_is_open={this.state.modal_cust_is_open} openModal={this.openModal} closeModal={this.closeModal} parentCallback={this.handleCallback} />
                    </div>
                    <div>
                        <ProductCreateModal newProductName={this.state.newProductName} modal_pro_is_open={this.state.modal_pro_is_open} openProductModal={this.openProductModal} closeProductModal={this.closeProductModal} parentCallback={this.handleCallbackPdt} />
                    </div>
                    <RenewPlan modal_is_open={this.state.modalPlan} openModal={this.openModalPlan} closeModal={this.closeModalPlan} successModalPlan={this.successModalPlan} />
                </div>
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state:{ units: any,taxes: any,countries: any,states: any,customers:any,products: any, companies: any, permissions: any }) => {   
    return{  
        units: state.units.units,      
        taxes: state.taxes.taxes,        
        countries: state.countries.countries,
        states: state.states.states,
        customers: state.customers.customers,
        products: state.products.products,
        companies: state.companies.companies,
        permissions: state.permissions.permissions
    }
}

export default connect(mapStateToProps)(withRouter(InvoiceCreate));
