import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';

interface monthProps {
    year: number;
    month: number;
    monthStr: string;
    clickAddMonth: React.MouseEventHandler<HTMLElement> | undefined;
    clickRemoveMonth: React.MouseEventHandler<HTMLElement> | undefined;
    data: any | undefined;
    clickOpenModal: React.MouseEventHandler<HTMLElement>;
    amount: number;
}

const Month = (props: monthProps) => {
    const get_price = () => {
        if (props.data && props.data.status) {
            return (
                <span className="card-box-btm-hd">PAID</span>
            )
        } else {
            return (
                <span className="card-box-btm-hd dark-color">
                    <span className="feathers fea-rupee-icon"></span>
                    {props.amount}
                </span>
            )
        }
    }
    const get_status = (status: number) => {
        if (status == 4) {
            return (
                <span className="card-btn card-btn-hold">On Hold</span>
            )
        } else if (status == 3) {
            return (
                <span className="card-btn card-btn-done">Done</span>
            )
        } else if (status == 2) {
            return (
                <span className="card-btn card-btn-progress">Progress</span>
            )
        } else {
            return (
                <span className="card-btn card-btn-assigned">Not Assigned</span>
            )
        }
    }
    const get_li_status = () => {
        if (props.data && props.data.status) {
            let status = props.data.status;
            if (status == 4) {
                return (
                    <ul>
                        <li className="li-box-a li-hold">
                            <span>GSTR1 - Filed</span>
                            <span className="feathers fea-disabled-icon"></span>
                        </li>
                        <li className="li-box-a li-hold">
                            <span>GST3B - Filed</span>
                            <span className="feathers fea-disabled-icon"></span>
                        </li>
                    </ul>
                )
            } else if (status == 3) {
                return (
                    <ul>
                        <li className="li-box-a li-done">
                            <span>GSTR1 - Filed</span>
                            <span className="feathers fea-tick-icon"></span>
                        </li>
                        <li className="li-box-a li-done">
                            <span>GST3B - Filed</span>
                            <span className="feathers fea-tick-icon"></span>
                        </li>
                    </ul>
                )
            } else if (status == 2) {
                return (
                    <ul>
                        <li className="li-box-a li-progress">
                            <span>GSTR1 - Filed</span>
                            <span className="feathers fea-progress-icon"></span>
                        </li>
                        <li className="li-box-a li-progress">
                            <span>GST3B - Filed</span>
                            <span className="feathers fea-progress-icon"></span>
                        </li>
                    </ul>
                )
            } else {
                return (
                    <ul>
                        <li className="li-box-a li-disabled">
                            <span>GSTR1</span>
                            <span className="feathers fea-disabled-icon"></span>
                        </li>
                        <li className="li-box-a li-disabled">
                            <span>GST3B</span>
                            <span className="feathers fea-disabled-icon"></span>
                        </li>
                    </ul>
                )
            }
        } else {
            return (
                <ul>
                    <li className="li-box-a li-disabled">
                        <span>GSTR1</span>
                        <span className="feathers fea-disabled-icon"></span>
                    </li>
                    <li className="li-box-a li-disabled">
                        <span>GST3B</span>
                        <span className="feathers fea-disabled-icon"></span>
                    </li>
                </ul>
            )
        }
    }
    return (
        <>
            {props.data && props.data.status ?
                <div className="col-md-4 pointer" onClick={() => props.clickOpenModal(props.data)}>
                    <div className="bg-white card-box-a">
                        <div className="px-3 py-2">
                            <span className="card-box-hd mb-3 d-block">{props.monthStr} {props.year}</span>
                            {get_li_status()}
                        </div>
                        <div
                            className="card-box-btm px-3 py-2 d-flex justify-content-between align-items-center">
                            {get_price()}
                            {props.data ? props.data.status ? get_status(props.data.status) :
                                (
                                    <Link to="#" onClick={props.clickRemoveMonth}>
                                        <span className="card-btn card-btn-buy">Selected</span>
                                    </Link>
                                )
                                :
                                (
                                    <Link to="#" onClick={props.clickAddMonth}>
                                        <span className="card-btn card-btn-buy">Buy</span>
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                </div>
                :
                <div className="col-md-4">
                    <div className="bg-white card-box-a">
                        <div className="px-3 py-2">
                            <span className="card-box-hd mb-3 d-block">{props.monthStr} {props.year}</span>
                            {get_li_status()}
                        </div>
                        <div
                            className="card-box-btm px-3 py-2 d-flex justify-content-between align-items-center">
                            {get_price()}
                            {props.data ? props.data.status ? get_status(props.data.status) :
                                (
                                    <Link to="#" onClick={props.clickRemoveMonth}>
                                        <span className="card-btn card-btn-buy">Selected</span>
                                    </Link>
                                )
                                :
                                (
                                    <Link to="#" onClick={props.clickAddMonth}>
                                        <span className="card-btn card-btn-buy">Buy</span>
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                </div>
            }
        </>

    );
};

export default Month;