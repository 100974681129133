import React, { Component } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, UncontrolledAlert, ModalFooter, Button, NavLink, Table, BreadcrumbItem } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
    PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Datatable/datatables.scss";
import { del, get, post } from "src/helpers/api_helper";
import NoDataIndication from "../Datatable/NoDataIndication";
import moment from "moment";

import Select from 'react-select';
import { ActionMeta, OnChangeValue, Options } from "react-select/dist/declarations/src";

import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import ViewIcon from "src/assets/images/icons/View";
import SentIcon from "src/assets/images/icons/Sent";
import ConvertIcon from "src/assets/images/icons/Convert";
import ActionsIcon from "src/assets/images/icons/Actions";
import WhatsappIcon from "src/assets/images/icons/Whatsapp";
import FilterIcon from "src/assets/images/icons/Filter";
import adbanner from "../../assets/images/ad.png";

import MoneyIcon from '../../assets/images/icons/money';
import EstimateIcon from '../../assets/images/icons/Estimate';
import InvoiceIcon from '../../assets/images/icons/Invoice';
import AdditemIcon from '../../assets/images/icons/Estimate';


// Import Editor
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import MetaTags from "react-meta-tags";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
/* import EstimationDetailsModel from "./EstimationDetailsModel"; */
import SendEstModel from "./SendEstModel";
import ExpenseIcon from "src/assets/images/icons/Expense";
import Addcustomer2Icon from "src/assets/images/icons/Customer2";
import Invoice2Icon from "src/assets/images/icons/Invoice2";
import Estimate2Icon from "src/assets/images/icons/Estimate2";
import RenewPlan from "../Payments/RenewPlan";
import EstimationDetailsPdf from "./EstimationDetailsPdf";
import PdfIcon from "src/assets/images/icons/Pdf";
import EllipsisIcon from "src/assets/images/icons/Elipsis";
import { connect } from "react-redux";
import { PermissionModule } from "src/store/permissions/actionTypes";
import CustomerCreateFullModal from "../Customers/CustomerCreateFullModal";

interface MyProps extends RouteComponentProps<any> {
    customers: Array<any>;
    permissions: Array<any>;
    companies: { orders: any };
}

interface MyState {
    id: string;
    sizePerPage: number;
    totalSize: number;
    data: any;
    sortField: string;
    sortOrder: string;
    estimation_prefix: string;
    permissions: Array<any>;
    isLoading: boolean;
    options: Option[];
    valueCustomer: Option | null | undefined;
    customers: Array<any>;
    startDate: Date | null;
    endDate: Date | null;
    totalAmount: number;
    modal: boolean;
    isSending: boolean;
    editorState: EditorState;
    isHideSuccessMgs: boolean;
    isHideErrorMgs: boolean;
    email: string;
    loading: boolean;
    instance_id: string;
    modalView: boolean;
    company_name: string;
    isPlanExpired: number;
    modalPlan: boolean;
    pdfId: string;
    active_est_no: string;
    modal_cust_is_open: boolean;
};

interface ChangeProp {
    page: number;
    sizePerPage: number;
}

interface Option {
    label: string;
    value: string;
}

const createOption = (label: string, value: string) => ({
    label,
    value,
});

class EstimationList extends Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: '',
            sizePerPage: 10,
            totalSize: 0,
            data: [],
            sortField: "estimation_no",
            sortOrder: "desc",
            estimation_prefix: "",
            permissions: [],
            isLoading: true,
            options: [],
            valueCustomer: null,
            customers: [],
            // startDate: new Date(),
            // endDate: new Date(moment().add(6, 'days').format("YYYY-MM-DD"))
            startDate: null,
            endDate: null,
            totalAmount: 0,
            modal: false,
            isSending: false,
            editorState: EditorState.createEmpty(),
            isHideSuccessMgs: true,
            isHideErrorMgs: true,
            email: "",
            loading: true,
            instance_id: '',
            modalView: false,
            company_name: '',
            isPlanExpired: 0,
            pdfId: '',
            modalPlan: false,
            active_est_no: '',
            modal_cust_is_open: false
        };
        this.handleDelete = this.handleDelete.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    openModalPlan = () => {
        this.setState({ modalPlan: true });
    }

    closeModalPlan = () => {
        this.setState({ modalPlan: false });
    }

    fetchData = (param: any) => {
        const sendGetRequest = async () => {
            try {

                const resp = await post(process.env.REACT_APP_API_URL + `/api/estimation_list_grid`, param);

                if (resp.success) {
                    this.setState({
                        totalSize: resp.data.count,
                        totalAmount: resp.data.totalAmount,
                        data: resp.data.results,
                        estimation_prefix: resp.data.estimation_prefix,
                        instance_id: resp.data.instance_id,
                        company_name: resp.data.company_name
                    });
                }

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        sendGetRequest();
    }

    handleTableChange = (type: string, param: ChangeProp) => {
        this.setState({ sizePerPage: param.sizePerPage });
        this.fetchData(param);

    }

    handleDelete = (row: any) => {
        const sendGetRequest = async () => {
            try {

                const conf = window.confirm("Do you want to delete this record?");
                if (conf) {
                    const resp = del(process.env.REACT_APP_API_URL + '/api/estimation_delete/' + row.id);

                }

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        sendGetRequest();
        this.fetchData({ page: 1, searchText: "", sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    }

    downloadPdf = (event: any, row: any) => {
        event.stopPropagation();
        this.setState({ pdfId: row.id });
    }

    convertToInvoice = async (event: any, row: any) => {
        try {
            event.stopPropagation();
            const conf = window.confirm("Do you want to convert as invoice?");
            if (conf) {
                const resp: { invoice_id: string } = await get(process.env.REACT_APP_API_URL + '/api/convert_to_invoice/' + row.id);
                this.props.history.push("/invoice_edit/" + resp.invoice_id);
            }
        } catch (err) {
            console.error(err);
        }
    }

    send = async (event: any, row: any) => {
        try {
            event.stopPropagation();
            let est_no = this.state.estimation_prefix ? this.state.estimation_prefix + this.padLeadingZeros(row.estimation_no, 5) : "INV" + this.padLeadingZeros(row.estimation_no, 5);
            this.setState({ id: row.id, active_est_no: est_no, email: row.email, modal: !this.state.modal });
        } catch (err) {
            console.error(err);
        }
    }

    view = async (row: any) => {
        try {
            // this.setState({ id: row.id, modalView: !this.state.modalView });
        } catch (err) {
            console.error(err);
        }
    }

    isPlanExpired = async (e: any, location: string) => {
        try {
            if (this.state.isPlanExpired) {
                this.props.history.push(location);
            } else {
                this.openModalPlan();
            }
        } catch (err) {
            console.error(err);
        }
    }

    toggleView = () => {
        try {
            this.setState({ modalView: !this.state.modalView });
        } catch (err) {
            console.error(err);
        }
    }

    toggleSend = () => {
        try {
            this.setState({ modal: !this.state.modal });
        } catch (err) {
            console.error(err);
        }
    }

    padLeadingZeros(num: number, size: number) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    handleChange = async (
        newValue: OnChangeValue<Option, false>,
        actionMeta: ActionMeta<Option>
    ) => {
        this.setState({ valueCustomer: newValue });
        this.fetchData({ page: 1, searchText: "", customer: newValue?.value, startDate: this.state.startDate, endDate: this.state.endDate, sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    };
    loadData = async () => {
        try {

            const getAuthUser: any = localStorage.getItem("authUser");
            const obj = JSON.parse(getAuthUser);
            this.setState({ isPlanExpired: obj.isPlanExpired });

            let customers: any = this.props.customers;
            let customerOptions: Array<any> = [];

            customers.map((customer: any) => {
                let newOption = createOption(customer.customer_name, customer.id);
                customerOptions = [...customerOptions, newOption];
            }
            )

            let estmationPermissions = this.props.permissions.filter(permission => permission.module_permission_id === PermissionModule.ESTIMATION);

            this.setState({ customers: customers, options: customerOptions, permissions: estmationPermissions });


            this.setState({
                isLoading: false,
                loading: false
            });

        } catch (err) {
            console.error(err);
        }
    }
    componentWillReceiveProps(props: MyProps) {
        this.loadData();
    }
    componentDidMount() {
        this.loadData();
    }
    setStartDate(dateRange: Array<any>) {
        const [startDate, endDate] = dateRange;
        this.setState({ startDate: startDate, endDate: endDate });
        this.fetchData({ page: 1, searchText: "", customer: this.state.valueCustomer?.value, startDate: startDate, endDate: endDate, sizePerPage: this.state.sizePerPage, sortField: this.state.sortField, sortOrder: this.state.sortOrder });
    }
    onEditorStateChange = (editorState: EditorState) => {
        this.setState({
            editorState,
        });
    }
    handleValidSubmit = async (event: any, values: any) => {
        this.setState({ isSending: true });
        let content = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
        values["content"] = content;
        values["code"] = this.state.id;
        const resp = await post(process.env.REACT_APP_API_URL + '/api/estimation_sent', values);
        if (resp.success) {
            this.setState({ isHideSuccessMgs: false });
            setTimeout(() => {
                this.setState({ modal: false });
            }, 2000);

        } else {
            this.setState({ isHideErrorMgs: false });
        }
        this.setState({ isSending: false });
    }

    selectRow = (row: any, isSelect: any, rowIndex: any) => {
        this.props.history.push("/estimation_details/" + row.id);
    };

    preventParentAction = (event: any) => {
        event.stopPropagation();
    }
    openCustModal = () => {
        this.setState({ modal_cust_is_open: true });
    }
    closeCustModal = () => {
        this.setState({ modal_cust_is_open: false });
    }
    parentCallback = () => {
        this.closeCustModal();
    }
    render() {

        const pageOptions: any = {
            sizePerPage: this.state.sizePerPage,
            totalSize: this.state.totalSize,
            custom: true,
            showTotal: true,
        };

        const { SearchBar } = Search;

        const defaultSorted: any = [
            {
                dataField: this.state.sortField,
                order: this.state.sortOrder,
            },
        ];

        const columns = [
            {
                dataField: "id",
                text: "Id",
                sort: true,
                hidden: true
            },
            /* {
                dataField: "due_date",
                text: "Due",
                sort: true,
                formatter: (cellContent: any, row: any) => {
                    return (
                        <>{moment(row.due_date).format("DD/MM/YYYY")}</>
                    )
                }
            }, */
            {
                dataField: "invoice_date",
                text: "Date",
                sort: true,
                formatter: (cellContent: any, row: any) => {
                    return (
                        <>{moment(row.invoice_date).format("DD/MM/YYYY")}</>
                    )
                }
            },
            {
                dataField: "estimation_no",
                text: "Number",
                sort: true,
                formatter: (cellContent: any, row: any) => {
                    return (
                        <>{this.state.estimation_prefix ? this.state.estimation_prefix + this.padLeadingZeros(row.estimation_no, 5) : "EST" + this.padLeadingZeros(row.estimation_no, 5)}</>
                    );
                }
            },
            {
                dataField: "customer_name",
                text: "Customer",
                sort: true,
                formatter: (cellContent: any, row: any) => {
                    return (
                        <span dangerouslySetInnerHTML={{ __html: cellContent.match(/.{1,30}/g).join("<br />") }}></span>
                    )
                }
            },
            {
                dataField: "gross_total_base",
                text: "Amount",
                sort: true,
                formatter: (cellContent: any, row: any) => {
                    return (
                        <><span>{`₹ ${cellContent}`}</span></>
                    );
                }
            },
            {
                dataField: "status",
                text: "Status",
                isDummyField: true,
                formatter: (cellContent: any, row: any) => {
                    let status;
                    if (row.status == 4) {
                        status = <span className="status partially">Invoiced</span>
                    } else if (row.status == 3) {
                        status = <span className="status pending">Viewed</span>
                    } else if (row.status == 2) {
                        status = <span className="status pending">Mail Sent</span>
                    } else {
                        status = <span className="status pending">Generated</span>
                    }
                    return (
                        <>
                            {status}
                        </>
                    );
                }
            },
            {
                dataField: "action",
                text: "Action",
                isDummyField: true,
                formatter: (cellContent: any, row: any) => {
                    return (
                        <>
                            <div className='table-button'>

                                <Link to="#" onClick={(e: any) => this.downloadPdf(e, row)}><button className="round-btn" title="View"><PdfIcon /></button></Link>
                                <Link to="#" onClick={(e: any) => this.send(e, row)} ><button className="round-btn" title="Send"><SentIcon /></button></Link>
                                <a target="_blank" href={`https://api.whatsapp.com/send?text=Hello *${row.customer_name}*,%0a%0a Thanks for your business!%0a%0a Please find your Invoice ${this.state.estimation_prefix}${this.padLeadingZeros(row.invoice_no, 5)} here,%0a ${process.env.REACT_APP_URL}/estimation-view/${this.state.instance_id}/${row.id}%0a%0a%0a Thanks%0a *${this.state.company_name}* %0a Sent using ${process.env.REACT_APP_NAME}(https://www.makemyinvoice.in)`} onClick={this.preventParentAction}><button className="round-btn whatsapp" title="Whatsapp"><WhatsappIcon /></button></a>
                                <button className="round-btn" title="Actions" onClick={this.preventParentAction}>

                                    <UncontrolledDropdown className="mt-4 mt-sm-0">
                                        <DropdownToggle tag="a">
                                            <EllipsisIcon />
                                        </DropdownToggle>

                                        <DropdownMenu>

                                            <Link to={"/estimation_edit/" + row.id} >
                                                <DropdownItem to="#" ><i className="fas fa-edit" data-toggle="tooltip" data-placement="bottom" title="Edit" style={{ color: '#28a745' }} /> Edit</DropdownItem>
                                            </Link>
                                            <DropdownItem to="#" onClick={() => this.handleDelete(row)} ><i className="fas fa-trash-alt" data-toggle="tooltip" data-placement="bottom" title="Delete" style={{ color: '#dc3545' }} /> Delete</DropdownItem>

                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                </button>
                                {row.status != 4 && (<Link to="#" onClick={(e: any) => this.convertToInvoice(e, row)} ><Button type="button" className="normal-btn" title="Convert">Convert</Button></Link>)}

                            </div>
                        </>
                    );
                }
            }
        ];

        const title = "Estimate";
        const breadcrumbItem = "Estimate List";

        return (
            <React.Fragment>
                <div className="page-content" style={{ minHeight: '685px' }}>
                    <MetaTags>
                        <title>{title}</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Row>
                            <Col xs="12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between form-wrap">
                                    <div className="header"> <h4 className="mb-0 font-size-18">{breadcrumbItem}</h4>
                                        <div className="form-inline">
                                            <AvForm >
                                                <Row>
                                                    <Col lg={6}>
                                                        {this.state.loading == true ? (
                                                            <Skeleton width={'100%'} height={'50%'} />
                                                        ) : (
                                                            <Select
                                                                isClearable={true}
                                                                isDisabled={this.state.isLoading}
                                                                isLoading={this.state.isLoading}
                                                                onChange={this.handleChange}
                                                                options={this.state.options}
                                                                value={this.state.valueCustomer}
                                                                name="customer"
                                                            />
                                                        )}
                                                    </Col>
                                                    <Col lg={6}>
                                                        {this.state.loading == true ? (
                                                            <Skeleton width={'100%'} height={'50%'} />
                                                        ) : (
                                                            <DatePicker
                                                                selected={this.state.startDate}
                                                                onChange={(date: Array<any>) => this.setStartDate(date)}
                                                                selectsRange={true}
                                                                startDate={this.state.startDate}
                                                                endDate={this.state.endDate}
                                                                dateFormat="yyyy-MM-dd"
                                                                className="form-control calendar"
                                                                isClearable={true}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                todayButton="Today"
                                                                monthsShown={2}
                                                            />
                                                        )}

                                                    </Col>
                                                </Row>
                                            </AvForm>
                                        </div>
                                    </div>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <BreadcrumbItem>
                                                <Link to="#">{title}</Link>
                                            </BreadcrumbItem>
                                            <BreadcrumbItem active>
                                                <Link to="#">{breadcrumbItem}</Link>
                                            </BreadcrumbItem>
                                        </ol>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardHeader>
                                        <div className="row">

                                            <div className="col-md-12 col-xl-7 order-xl-1">
                                                {this.state.loading == true ? (
                                                    <Skeleton width={'30%'} height={'100%'} />
                                                ) : (
                                                    <ul className="values">

                                                        <li className="active">
                                                            <NavLink>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="32.815" height="49.055" viewBox="0 0 32.815 49.055">
                                                                    <path id="Path_441" data-name="Path 441" d="M32.681-43.765H24.729a13.718,13.718,0,0,1,2.729,6.671H34.23l-1.55,5.29H27.56q-.606,5.98-4.75,9T11.287-19.777L27.9-.406V0H20.249L2.392-20.882l-.034-4.185h8.389A12.034,12.034,0,0,0,17.587-26.9a7.875,7.875,0,0,0,3.335-4.9H1.415l1.55-5.29h17.89q-1.617-6.4-9.905-6.671H1.482l1.583-5.29H34.23Z" transform="translate(-1.415 49.055)" />
                                                                </svg>


                                                                <span>{" "}{this.state.totalAmount}</span>
                                                                <label>Total</label>
                                                            </NavLink>
                                                        </li>

                                                    </ul>
                                                )}
                                            </div>
                                            <div className="col-md-12 col-xl-5 order-xl-1">
                                                {this.state.permissions.find(({ action, is_active }) => action == "create" && is_active == 1) &&
                                                    (
                                                        <ul className="qck-link1">
                                                            <li>
                                                                <Link to="/product_add" >
                                                                    <span><ExpenseIcon /></span>
                                                                    <label>Add Item</label>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="#" onClick={() => this.openCustModal()}>
                                                                    <span><Addcustomer2Icon /></span>
                                                                    <label>Add Customer</label>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/invoice_add" >
                                                                    <span><Invoice2Icon /></span>
                                                                    <label>New Invoice</label>
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/estimation_add"  >
                                                                    <span><Estimate2Icon /></span>
                                                                    <label>New Estimate</label>
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    )
                                                }
                                            </div>
                                        </div>

                                    </CardHeader>
                                    <CardBody>


                                        {this.state.loading == true ? (
                                            <>
                                                <CardTitle className="h4"><Skeleton height={'10%'} width={'10%'} /></CardTitle>
                                                <Table className="table mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th><Skeleton width={'100%'} /></th>
                                                            <th><Skeleton width={'100%'} /></th>
                                                            <th><Skeleton width={'100%'} /></th>
                                                            <th><Skeleton width={'100%'} /></th>
                                                            <th><Skeleton width={'100%'} /></th>
                                                            <th><Skeleton width={'100%'} /></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><Skeleton height={'10%'} /></td>
                                                            <td><Skeleton height={'10%'} /></td>
                                                            <td><Skeleton height={'10%'} /></td>
                                                            <td><Skeleton height={'10%'} /></td>
                                                            <td><Skeleton height={'10%'} /></td>
                                                            <td><Skeleton height={'10%'} /></td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </>
                                        ) : (
                                            <>
                                                <CardTitle className="h4">Estimate List</CardTitle>
                                                <PaginationProvider
                                                    pagination={paginationFactory(pageOptions)}
                                                >
                                                    {({ paginationProps, paginationTableProps }) => (
                                                        <ToolkitProvider
                                                            keyField="id"
                                                            columns={columns}
                                                            data={this.state.data}
                                                        // search
                                                        >
                                                            {toolkitProps => (
                                                                <React.Fragment>

                                                                    <Row>
                                                                        <Col xl="12">
                                                                            <div className="table-responsive">
                                                                                <BootstrapTable
                                                                                    // responsive
                                                                                    selectRow={{
                                                                                        mode: "radio",
                                                                                        hideSelectColumn: true,
                                                                                        clickToSelect: true,
                                                                                        onSelect: this.selectRow
                                                                                    }}
                                                                                    remote
                                                                                    bordered={false}
                                                                                    striped={false}
                                                                                    defaultSorted={defaultSorted}
                                                                                    classes={"table align-middle table-nowrap"}
                                                                                    headerWrapperClasses={"thead-light"}
                                                                                    onTableChange={this.handleTableChange}
                                                                                    {...toolkitProps.baseProps}
                                                                                    {...paginationTableProps}
                                                                                    noDataIndication={() => <NoDataIndication />}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row className="align-items-md-center mt-30">
                                                                        <Col className="inner-custom-pagination d-flex">
                                                                            <div className="d-inline">
                                                                                <SizePerPageDropdownStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                                <PaginationTotalStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </div>
                                                                            <div className="text-md-right ms-auto">
                                                                                <PaginationListStandalone
                                                                                    {...paginationProps}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </React.Fragment>
                                                            )}
                                                        </ToolkitProvider>
                                                    )}
                                                </PaginationProvider>
                                            </>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
                <EstimationDetailsPdf id={this.state.pdfId} companies={this.props.companies} />
                <SendEstModel id={this.state.id} invoice_no={this.state.active_est_no} isOpen={this.state.modal} toggle={this.toggleSend} email={this.state.email} />
                {/* <EstimationDetailsModel id={this.state.id} isOpen={this.state.modalView} toggle={this.toggleView} instance_id={this.state.instance_id} email={this.state.email} /> */}
                {this.state.modal_cust_is_open == true && <CustomerCreateFullModal modal_cust_is_open={this.state.modal_cust_is_open} closeModal={() => this.closeCustModal()} parentCallback={this.parentCallback} />}
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state: { customers: any, permissions: any, companies: any }) => {
    return {
        customers: state.customers.customers,
        permissions: state.permissions.permissions,
        companies: state.companies.companies
    }
}
export default connect(mapStateToProps)(withRouter(EstimationList));