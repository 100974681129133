import React from 'react';
import MetaTags from "react-meta-tags";
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Reports = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Banks</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Reports" breadcrumbItem="Reports" />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody className='body-report'>
                                    <Row className="my-4">
                                        <Col className="col-md-4">
                                            <Link to="/sales_gstr1" className="">
                                                <div className="grid-a d-flex align-items-center active">
                                                    <span className="icon-blk">
                                                        <span className="feathers fea-doc-icon"></span>
                                                    </span>
                                                    <span className="grid-b">
                                                        <a href="#">
                                                            Sales - GSTR1
                                                        </a>
                                                    </span>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col className="col-md-4">
                                            <div className="grid-a d-flex align-items-center active">
                                                <span className="icon-blk">
                                                    <span className="feathers fea-doc-icon"></span>
                                                </span>
                                                <span className="grid-b">
                                                    <a href="#">
                                                        Purchase - GST2A
                                                    </a>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col className="col-md-4">
                                            <div className="grid-a d-flex align-items-center active">
                                                <span className="icon-blk">
                                                    <span className="feathers fea-doc-icon"></span>
                                                </span>
                                                <span className="grid-b">
                                                    <a href="#">
                                                        Sales by Customer
                                                    </a>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="my-4">
                                        <Col className="col-md-4">
                                            <div className="grid-a d-flex align-items-center active">
                                                <span className="icon-blk">
                                                    <span className="feathers fea-doc-icon"></span>
                                                </span>
                                                <span className="grid-b">
                                                    <a href="#">
                                                        Sales by Items
                                                    </a>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col className="col-md-4">
                                            <div className="grid-a d-flex align-items-center active">
                                                <span className="icon-blk">
                                                    <span className="feathers fea-doc-icon"></span>
                                                </span>
                                                <span className="grid-b">
                                                    <a href="#">
                                                        Customer Balance Receivable
                                                    </a>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col className="col-md-4">
                                            <div className="grid-a d-flex align-items-center active">
                                                <span className="icon-blk">
                                                    <span className="feathers fea-doc-icon"></span>
                                                </span>
                                                <span className="grid-b">
                                                    <a href="#">
                                                        Expenses Details
                                                    </a>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="my-4">
                                        <Col className="col-md-4">
                                            <div className="grid-a d-flex align-items-center active">
                                                <span className="icon-blk">
                                                    <span className="feathers fea-doc-icon"></span>
                                                </span>
                                                <span className="grid-b">
                                                    <a href="#">
                                                        Expenses by Supplier
                                                    </a>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col className="col-md-4">
                                            <div className="grid-a d-flex align-items-center active">
                                                <span className="icon-blk">
                                                    <span className="feathers fea-doc-icon"></span>
                                                </span>
                                                <span className="grid-b">
                                                    <a href="#">
                                                        TDS Receivable
                                                    </a>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col className="col-md-4">
                                            <div className="grid-a d-flex align-items-center active">
                                                <span className="icon-blk">
                                                    <span className="feathers fea-doc-icon"></span>
                                                </span>
                                                <span className="grid-b">
                                                    <a href="#">
                                                        Invoice Details
                                                    </a>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="my-4">
                                        <Col className="col-md-4">
                                            <div className="grid-a d-flex align-items-center active">
                                                <span className="icon-blk">
                                                    <span className="feathers fea-doc-icon"></span>
                                                </span>
                                                <span className="grid-b">
                                                    <a href="#">
                                                        Profit and Loss Account
                                                    </a>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col className="col-md-4">
                                            <div className="grid-a d-flex align-items-center active">
                                                <span className="icon-blk">
                                                    <span className="feathers fea-doc-icon"></span>
                                                </span>
                                                <span className="grid-b">
                                                    <a href="#">
                                                        Estimate Details
                                                    </a>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col className="col-md-4">
                                            <div className="grid-a d-flex align-items-center active">
                                                <span className="icon-blk">
                                                    <span className="feathers fea-doc-icon"></span>
                                                </span>
                                                <span className="grid-b">
                                                    <a href="#">
                                                        Supplier Balance Payable
                                                    </a>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Reports;