import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Col, Label, Modal, Row, Table, UncontrolledAlert } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";

import { post, get, del } from "src/helpers/api_helper";
import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";
import Moment from 'moment';

import Select from 'react-select';
import { ActionMeta, OnChangeValue, Options } from "react-select/dist/declarations/src";
import { connect } from "react-redux";
import CreatableSelect from 'react-select/creatable';
import ExpItemModal from "../ExpItems/ExpItemModal";

interface Option {
    label: string;
    value: string;
}
interface Params extends RouteComponentProps {
    modal_is_open: boolean;
    openModal: React.KeyboardEventHandler<any> | React.MouseEventHandler<any> | undefined;
    closeModal: any;
    exp_items?: Array<any>;
}
const createOption = (label: string, value: string) => ({
    label,
    value,
});

type State = {
    banks: Array<any>;
    items: Array<any>;
    dtls: Array<any>;
    isModalProgress: boolean;
    isHideSuccessMgs: boolean;
    isHideErrorMgs: boolean;
    expense_date: string;
    expense_number: number;
    expense_prefix: string;
    modalExpItem: boolean;
    newItemName: string;
    valueItem: Option | null | undefined;
};

class ExpenseCreate extends Component<Params, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            expense_number: 0,
            expense_prefix: "",
            expense_date: Moment().format('YYYY-MM-DD'),
            banks: [],
            items: [],
            dtls: [
                { expense_no: 0, item_id: { label: '', value: '' }, amount: 0, heads_id: { label: '', value: '' }, description: '' }
            ],
            isModalProgress: false,
            isHideSuccessMgs: true,
            isHideErrorMgs: true,
            valueItem: undefined,
            modalExpItem: false,
            newItemName: ''
        };
    }
    removeRow = (i: number) => {
        const dtls = this.state.dtls;
        dtls.splice(i, 1);

        const filterDtls = dtls.map((dtl, i) => {
            dtl.expense_no = (this.state.expense_number + i);
            return dtl;
        });

        this.setState({ dtls: filterDtls });
    }
    addRow = () => {
        const max_exp_no: number = this.state.dtls.reduce((Pre, Cur) => Pre = Math.max(Pre, Cur.expense_no), this.state.expense_number);
        let newRaw = { expense_no: (max_exp_no + 1), item_id: { label: '', value: '' }, amount: 0, heads_id: { label: '', value: '' }, description: '' }
        this.setState({ dtls: [...this.state.dtls, newRaw] });
    }
    onChangehandlerDtl = (e: any, i: number, dtl: any) => {
        let name = e.target.name;
        let value = e.target.value;
        let dtls = this.state.dtls;
        dtl[name] = value;
        dtls[i] = dtl;
        this.setState({ dtls: dtls });
    }
    handleItemChange = async (newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>, i: number, dtl: any) => {
        let dtls = this.state.dtls;
        dtl.item_id = newValue;
        dtls[i] = dtl;
        this.setState({ dtls: dtls });
    };
    handleBankChange = async (newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>, i: number, dtl: any) => {
        let dtls = this.state.dtls;
        dtl.heads_id = newValue;
        dtls[i] = dtl;
        this.setState({ dtls: dtls });
    };
    handleValidSubmit = async (event: any, values: any) => {
        this.setState({ isModalProgress: true });
        values["dtls"] = this.state.dtls;
        values["id"] = 0;
        const resp: any = await post(process.env.REACT_APP_API_URL + '/api/expense_save', values);
        if (resp.success == true) {
            this.setState({
                isHideSuccessMgs: false
            });
            window.location.reload();
        }
        else {
            this.setState({
                isHideErrorMgs: false
            });
        }
        this.setState({ isModalProgress: false });
    }

    componentDidUpdate(prevProps: Params) {
        if (prevProps.exp_items?.length != this.props.exp_items?.length) {
            this.genExpItemOptions();
        }
    }
    genExpItemOptions = () => {
        let options: Option[] = [];
        this.props.exp_items?.map((item: { item_name: string, id: string }) => {
            let newOption = createOption(item.item_name, item.id);
            options.push(newOption);
        }
        )
        this.setState({ items: options });
    }
    componentDidMount() {
        const fetchData = async () => {
            try {
                this.genExpItemOptions();
                let urls = [
                    process.env.REACT_APP_API_URL + `/api/bank_list`,
                    // process.env.REACT_APP_API_URL + '/api/get_exp_items',
                    process.env.REACT_APP_API_URL + '/api/get_expense_no'
                ];

                Promise.all(urls.map((url) => get(url))).then(([respB, /* resI, */ respE]) => {
                    let banks_new: { label: string, value: string }[];
                    let newOption = createOption('Cash', '1');
                    this.setState({ banks: [...this.state.banks, newOption] });
                    respB.data.map((bank: { bank_name: string, id: string }) => {
                        let newOption = createOption(bank.bank_name, bank.id);
                        this.setState({ banks: [...this.state.banks, newOption] });
                    }
                    );
                    /* resI.data.map((item: { item_name: string, id: string }) => {
                        let newOption = createOption(item.item_name, item.id);
                        this.setState({ items: [...this.state.items, newOption] });
                    }
                    ) */
                    this.setState({ expense_number: respE.data.expense_no, expense_prefix: respE.data.expense_prefix });
                    let newRaw = { expense_no: respE.data.expense_no, item_id: { label: '', value: '' }, amount: 0, heads_id: { label: '', value: '' }, description: '' }
                    this.setState({ dtls: [newRaw] });
                });

            } catch (error) {
                console.error(error);
            }

        }
        fetchData();
    }
    openModalExpItem = () => {
        this.setState({ modalExpItem: true });
    }
    openModalExpItemCreate = (inputValue: string) => {
        this.setState({ modalExpItem: true, newItemName: inputValue });
    }
    closeModalExpItem = () => {
        this.setState({ modalExpItem: false });
    }

    render() {
        return (
            <Modal
                isOpen={this.props.modal_is_open}
                toggle={this.props.openModal}
                // className={'md-700'}
                className="modal-xl"
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">

                    </h5>
                    <button
                        type="button"
                        onClick={() => this.props.closeModal()}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <AvForm onValidSubmit={this.handleValidSubmit}>
                    <div className="modal-body">
                        <UncontrolledAlert hidden={this.state.isHideSuccessMgs} color="success">
                            Expenses save successfully
                        </UncontrolledAlert>

                        <UncontrolledAlert hidden={this.state.isHideErrorMgs} color="danger">
                            Somthing went wrong, Please try again
                        </UncontrolledAlert>
                        <div className='grey-bg-frm'>
                            <Row>
                                <Col lg={2} className="mb-2">
                                    <Label
                                        htmlFor="horizontal-date_receipt-input"
                                    >
                                        Date
                                    </Label>
                                    <AvField
                                        name="expense_date"
                                        type="date"
                                        placeholder="Enter Date of expense"
                                        errorMessage="Enter Date of expense"
                                        validate={{ required: { value: true } }}
                                        value={this.state.expense_date}
                                    />
                                </Col>
                                <Col lg={6}>

                                </Col>
                            </Row>
                            <Row>
                                <div className="table-responsive">
                                    <Table className="table mb-0 cstm-tbl">
                                        <thead className="table-light">
                                            <tr>
                                                <th style={{ width: "15%" }}>Expense No</th>
                                                <th style={{ width: "40%" }}>Item</th>
                                                <th>Amount</th>
                                                <th style={{ width: "20%" }}>Mode</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.dtls.map((dtl, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <AvField
                                                                name="expense_no"
                                                                type="text"
                                                                placeholder="Expense No"
                                                                value={`${this.state.expense_prefix}${dtl.expense_no}`}
                                                                onChange={(e: any) => this.onChangehandlerDtl(e, i, dtl)}
                                                                readOnly={true}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Row>
                                                                <div className="mb-1">
                                                                    {/* <Select
                                                                        isClearable
                                                                        options={this.state.items}
                                                                        value={dtl.item_id}
                                                                        name="item_id"
                                                                        onChange={(newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => this.handleItemChange(newValue, actionMeta, i, dtl)}
                                                                    /> */}
                                                                    <CreatableSelect
                                                                        isClearable={true}
                                                                        onChange={(newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => this.handleItemChange(newValue, actionMeta, i, dtl)}
                                                                        onCreateOption={this.openModalExpItemCreate}
                                                                        options={this.state.items}
                                                                        value={dtl.item_id}
                                                                        name="item_id2"
                                                                    />
                                                                   {/*  <AvField tag={CreatableSelect}
                                                                        type="text"
                                                                        isClearable={true}
                                                                        onChange={(newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => this.handleItemChange(newValue, actionMeta, i, dtl)}
                                                                        onCreateOption={this.openModalExpItemCreate}
                                                                        options={this.state.items}
                                                                        value={dtl.item_id}
                                                                        name="item_id"                                                                        
                                                                        validate={{ required: { value: true } }} /> */}
                                                                </div>
                                                            </Row>
                                                            <Row>
                                                                <AvField
                                                                    name="description"
                                                                    type="text"
                                                                    placeholder="Description"
                                                                    value={dtl.description}
                                                                    onChange={(e: any) => this.onChangehandlerDtl(e, i, dtl)}
                                                                />
                                                            </Row>
                                                        </td>
                                                        <td>
                                                            <AvField
                                                                name="amount"
                                                                type="number"
                                                                placeholder="Amount"
                                                                value={dtl.amount}
                                                                onChange={(e: any) => this.onChangehandlerDtl(e, i, dtl)}
                                                                validate={{ required: { value: true } }}
                                                                key={i}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Select
                                                                isClearable
                                                                options={this.state.banks}
                                                                value={dtl.heads_id}
                                                                name="heads_id"
                                                                onChange={(newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => this.handleBankChange(newValue, actionMeta, i, dtl)}
                                                                validate={{ required: { value: true } }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Link to="#" className="btn tbl-btn" onClick={() => this.removeRow(i)}>
                                                                <i className="fas fa-trash"></i>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="col-md-12">
                                    <div className="mb-5 add-row">
                                        <Link to="#" className="btn" onClick={() => this.addRow()}>
                                            <i className="bx bx-plus me-1"></i> Add Row
                                        </Link>

                                    </div>
                                </div>
                            </Row>
                        </div>

                    </div>
                    <div className="modal-footer justify-content-center">
                        {this.state.isModalProgress ?
                            (
                                <button type="submit" className="btn btn-primary " disabled={true}>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Submiting
                                </button>
                            ) : (
                                <div className="modal-footer justify-content-center">
                                    <button
                                        type="button"
                                        onClick={() => this.props.closeModal()}
                                        className="btn btn-secondary "
                                        data-dismiss="modal"
                                    >
                                        Close
                                    </button> {" "}
                                    <button type="submit" className="btn btn-primary ">
                                        Submit
                                    </button>
                                </div>
                            )
                        }
                    </div>
                    {this.state.modalExpItem && (
                        <ExpItemModal modal_is_open={this.state.modalExpItem} openModal={this.openModalExpItem} closeModal={this.closeModalExpItem} id={'0'} item_name={this.state.newItemName} />
                    )}
                </AvForm>
            </Modal>
        )
    }
}
const mapStateToProps = (state: { exp_items: any }) => {
    return {
        exp_items: state.exp_items.exp_items
    }
}

export default connect(mapStateToProps)(withRouter(ExpenseCreate));

