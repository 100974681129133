import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, UncontrolledAlert } from 'reactstrap';
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { useHistory } from 'react-router';
import { get, post } from 'src/helpers/api_helper';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getMonthName } from 'src/helpers/common';
import { round } from 'lodash';
import { Razorpay } from "../Authentication/Razorpay";

interface CategoryProps {
    modal_is_open: boolean;
    closeModal: Function;
}
interface razerpay_init {
    r_key: string;
    order_id: string;
    amount: number;
    currency: string;
    merchant_order_id: string;
}
interface response {
    razorpay_payment_id: string;
    razorpay_order_id: string;
    razorpay_signature: string;
    orderId: string;
}
const GstReturnModal = (props: CategoryProps) => {
    const [nos, setNos] = useState<number>(1);
    const [isProgress, setIsProgress] = useState(0);
    const [actYear, setActYear] = useState(moment().year());
    const [actMonth, setActMonth] = useState((moment().month() + 1));
    const [years, setYears] = useState<any>([]);
    const [months, setMonths] = useState<any>([]);
    const [settings, setSettings] = useState<any>([]);
    const [isHideSuccessMgs, setIsHideSuccessMgs] = useState(true);
    const [isHideErrorMgs, setIsHideErrorMgs] = useState(true);
    const [errorMgs, setErrorMgs] = useState("Somthing went wrong, Please try again");
    const companies = useSelector((state: any) => state.companies.companies);

    const { gst_number, company_email, company_telephone } = useSelector((state: any) => ({
        gst_number: state.companies.companies.gst_number,
        company_email: state.companies.companies.company_email,
        company_telephone: state.companies.companies.company_telephone
    }));
    const checkGstRtnExists = async (data: any) => {
        const resp = await post(process.env.REACT_APP_API_URL + '/api/chk_gst_return_exists', data);
        if (resp.success) {
            return true;
        } else {
            setErrorMgs(resp.message);
            setIsHideErrorMgs(false);
            return false;
        }
    }
    const handleValidSubmit = async (event: any, values: any) => {
        if (await checkGstRtnExists(values)) {
            if (nos) {
                freeSubs(values);
            } else {
                paymentSubs(values);
            }
        }
    }
    const paymentSubs = async (data2: any) => {
        setIsProgress(1);
        let amount: number = round((settings.total_amount), 2);
        const result = await post(process.env.REACT_APP_API_URL + '/api/razorpay_init', { amount: amount });

        if (!result) {
            console.log("Server error.");
        }

        let data: razerpay_init = result.data;

        const orderId = data.order_id;
        const appName = process.env.REACT_APP_NAME;
        const options = {
            key: data.r_key,
            amount: data.amount,
            currency: data.currency,
            name: appName,
            description: `GST return submission payment`,
            order_id: orderId,
            handler: async (response: response) => {
                setIsProgress(1);
                let amount: number = round((settings.total_amount), 2);
                data2['amount'] = amount;
                data2['razorpay_order_id'] = response.razorpay_order_id;
                data2['razorpay_payment_id'] = response.razorpay_payment_id;
                data2['razorpay_signature'] = response.razorpay_signature;
                const resp = await post(process.env.REACT_APP_API_URL + '/api/gst_return_save', data2);
                if (resp.success) {
                    getData();
                    setIsProgress(0);
                    setIsHideSuccessMgs(false);
                    props.closeModal();
                } else {
                    setErrorMgs(resp.message);
                    setIsHideErrorMgs(false);
                }
                setIsProgress(0);
            },
            prefill: {
                name: companies.company_name,
                email: companies.company_email,
                contact: companies.company_telephone,
            },
            notes: {
                address: companies.company_address1,
                merchant_order_id: data.merchant_order_id
            },
            theme: {
                color: "#6266b0",
            },
        };

        const paymentObject = Razorpay(options);
        paymentObject.open();
    }

    const freeSubs = (data: any) => {
        const sendGetRequest = async () => {
            try {
                setIsProgress(1);
                console.log('data', data);
                const resp = await post(process.env.REACT_APP_API_URL + '/api/gst_return_save_free', data);
                if (resp.success) {
                    getData();
                    setIsHideSuccessMgs(false);
                    props.closeModal();
                } else {
                    setErrorMgs(resp.message);
                    setIsHideErrorMgs(false);
                }
                setIsProgress(0);
            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        sendGetRequest();
    }
    const getData = async () => {
        const respNos: any = await get(process.env.REACT_APP_API_URL + `/api/gst_return_nos`);
        setNos(respNos.data.nos);
    }
    useEffect(() => {
        let curMonth = moment().month();
        if (curMonth < 3) {
            setActYear(actYear - 1);
        }
        let yearStart = (actYear - 10);
        var arrYears = [];
        for (let i = yearStart; i <= actYear; i++) {
            arrYears.push(i);
        }
        arrYears.reverse();
        setYears(arrYears);

        var arrMonths = [];
        for (let i = 1; i <= 12; i++) {
            arrMonths.push(i);
        }
        setMonths(arrMonths);
        getData();
        getSettings();


    }, [])
    const getSettings = async () => {
        const respS: any = await get(process.env.REACT_APP_API_URL + `/api/gst_settings`);
        setSettings(respS.data);
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
    }
    const loadScript = (src: string) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    const onChangeYear = (e: any) => {
        let value = e.target.value;
        setActYear(value);
    }
    const onChangeMonth = (e: any) => {
        let value = e.target.value;
        setActMonth(value);
    }

    const buttonSwitch = () => {
        if (nos > 0) {
            return (
                <>
                    <button
                        type="button"
                        onClick={() => props.closeModal()}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        Close
                    </button>{" "}
                    <Button type="submit" color="primary" className="">
                        Submit
                    </Button>
                </>
            )
        } else {
            var number = 0;
            if (!isNaN(settings.amount)) {
                number = settings.amount;
            }
            return (
                <Button type="submit" color="primary" className="">
                    Pay Now (₹{round(number, 2)} + GST)
                </Button>
            )

        }
    }
    return (
        <React.Fragment>
            <Modal
                isOpen={props.modal_is_open}
                toggle={() => props.closeModal}
                className="md-700"
            >

                <AvForm onValidSubmit={handleValidSubmit}  >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            GST Return
                        </h5>
                        <button
                            type="button"
                            onClick={() => props.closeModal()}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            id="closeButton"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <UncontrolledAlert hidden={isHideSuccessMgs} color="success">
                            GST return Saved successfully
                        </UncontrolledAlert>

                        <UncontrolledAlert hidden={isHideErrorMgs} color="danger">
                            {errorMgs}
                        </UncontrolledAlert>
                        <div className="grey-bg-frm">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="progresspill-firstname-input">
                                            GSTIN
                                        </label>
                                        <AvField
                                            name="gstin"
                                            type="text"
                                            placeholder="Enter GSTIN"
                                            errorMessage="Enter GSTIN"
                                            validate={{ required: { value: true } }}
                                            value={gst_number}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="progresspill-address-input">
                                            Year
                                        </label>
                                        <AvField type="select" name="year" value={actYear} onChange={(e: any) => onChangeYear(e)} >
                                            <option value="" key="" >Year</option>
                                            {years.map((year: number) => (
                                                <option value={year} key={year} >{year}</option>
                                            ))}
                                        </AvField>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="progresspill-address-input">
                                            Month
                                        </label>
                                        <AvField type="select" name="month" value={actMonth} onChange={(e: any) => onChangeMonth(e)} >
                                            <option value="" key="" >Month</option>
                                            {months.map((month: number) => (
                                                <option value={month} key={month} >{getMonthName(month)}</option>
                                            ))}
                                        </AvField>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="progresspill-firstname-input">
                                            Email
                                        </label>
                                        <AvField
                                            name="email"
                                            type="email"
                                            placeholder="Enter Email"
                                            errorMessage="Enter Email"
                                            validate={{ required: { value: true } }}
                                            value={company_email}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="progresspill-firstname-input">
                                            Phone
                                        </label>
                                        <AvField
                                            name="phone"
                                            type="text"
                                            placeholder="Enter Phone"
                                            errorMessage="Enter Phone"
                                            validate={{ required: { value: true } }}
                                            value={company_telephone}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="progresspill-firstname-input">
                                            GSTIN Login
                                        </label>
                                        <AvField
                                            name="gst_login"
                                            type="text"
                                            placeholder="Enter GSTIN Login"
                                            errorMessage="Enter GSTIN Login"
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="progresspill-firstname-input">
                                            Password
                                        </label>
                                        <AvField
                                            name="gst_pwd"
                                            type="password"
                                            placeholder="Password"
                                            errorMessage="Password"
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                    <div className="modal-footer justify-content-center">
                        {isProgress ?
                            <>
                                <button type="submit" className="btn btn-primary " disabled={true}>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Submiting
                                </button>
                            </> :
                            <>
                                {buttonSwitch()}
                                {/* <button
                                    type="button"
                                    onClick={() => props.closeModal()}
                                    className="btn btn-secondary "
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>{" "}
                                <Button type="submit" color="primary" className="">
                                    Submit
                                </Button> */}
                            </>
                        }
                    </div>
                </AvForm>
            </Modal>
        </React.Fragment>
    )
}

export default GstReturnModal;

