import React, { Component, Dispatch } from "react";
import MetaTags from "react-meta-tags";
import { Container, Input, Label, Modal } from "reactstrap";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Button } from "reactstrap";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post, get } from "src/helpers/api_helper";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { connect } from "react-redux";
import { getProducts } from "src/store/actions";

interface Params extends RouteComponentProps {
    newProductName: string,
    modal_pro_is_open: boolean,
    openProductModal: React.KeyboardEventHandler<any> | React.MouseEventHandler<any>,
    closeProductModal: Function,
    parentCallback: Function,
    units?: Array<any>;
    taxes?: Array<any>;
    getProducts?: () => void;
}

type State = {
    id: string;
    name: string;
    description: string;
    rate: number;
    unit_id: number;
    tax_id: number;
    units: Array<any>;
    taxes: Array<any>;
    type: string;
    hsn_or_sac: string;
    loading: boolean;
    isProgress: number;
};

class ProductCreateModal extends Component<Params, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: "0",
            name: "",
            description: "",
            unit_id: 0,
            tax_id: 0,
            rate: 0,
            units: [],
            taxes: [],
            type: "product",
            hsn_or_sac: "",
            loading: false,
            isProgress: 0
        };
    }

    componentWillReceiveProps(props: Params) {
        this.setState({
            name: props.newProductName
        })
        this.loadData();
    }

    onChangehandler = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        let data: any = {};
        data[name] = value;
        this.setState(data);
    }
    loadData = async() => {
        let units: any = this.props.units;
        let taxes: any = this.props.taxes;
        this.setState({ units: units, taxes: taxes,loading: false });    
    }
    componentDidMount() {        
        this.loadData();
    }    
    handleValidSubmit = async (event: any, values: any) => {
        this.setState({ isProgress: 1 });
        values["id"] = this.state.id;
        const resp: any = await post(process.env.REACT_APP_API_URL + '/api/product_save', values);
        if (resp.success === true) {
            let product = { name: resp.product_name, id: resp.product_id };
            this.props.parentCallback(product);
            this.props.getProducts?.();  
        } else {

        }
        this.setState({ isProgress: 0 });
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.modal_pro_is_open}
                    toggle={this.props.openProductModal}
                    className="md-700"
                >
                    <AvForm onValidSubmit={this.handleValidSubmit}>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Create Product
                            </h5>
                            <button
                                type="button"
                                onClick={() => this.props.closeProductModal()}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="grey-bg-frm">
                                <Row>
                                    <Col>
                                        {this.state.loading ?
                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                            <>
                                                <label htmlFor="progresspill-firstname-input">
                                                    Product name
                                                </label>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="name"
                                                        type="text"
                                                        placeholder="Enter Product name"
                                                        errorMessage="Enter Product name"
                                                        validate={{ required: { value: true } }}
                                                        value={this.state.name}
                                                    />
                                                </div>
                                            </>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>

                                        <div className="d-flex mb-2 radio-btns justify-content-between">
                                            {this.state.loading ?
                                                <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                <>
                                                    <label htmlFor="progresspill-lastname-input">
                                                        Product Type
                                                    </label>
                                                    <AvRadioGroup inline name="type" required onChange={this.onChangehandler} value={this.state.type}>
                                                        <AvRadio label="Product" value="product" />
                                                        <AvRadio label="Service" value="service" />
                                                    </AvRadioGroup>
                                                </>}
                                        </div>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            {this.state.loading ?
                                                <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                <>
                                                    <label htmlFor="progresspill-firstname-input">
                                                        {this.state.type == "service" ? "SAC" : "HSN"}
                                                    </label>
                                                    <AvField
                                                        name="hsn_or_sac"
                                                        type="text"
                                                        placeholder={this.state.type == "service" ? "Enter SAC" : "Enter HSN"}
                                                        value={this.state.hsn_or_sac}
                                                        onChange={this.onChangehandler}
                                                    />
                                                </>}
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            {this.state.loading ?
                                                <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                <>
                                                    <AvField type="select" name="tax_id" label="GST Tax Rate" errorMessage="Choose Tax"
                                                        // validate={{ required: { value: true } }} 
                                                        value={this.state.tax_id}  >
                                                        <option value="" key="" >--Select--</option>
                                                        {this.state.taxes.map((tax) => (
                                                            <option value={tax.id} key={tax.id} >{tax.code}</option>
                                                        ))}
                                                    </AvField>
                                                </>}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        {this.state.loading ?
                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                            <>
                                                <label htmlFor="progresspill-lastname-input">
                                                    Rate
                                                </label>
                                                <div className="mb-3">

                                                    <AvField
                                                        name="rate"
                                                        type="number"
                                                        placeholder="Enter rate"
                                                        errorMessage="Enter rate"
                                                    // validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </>}
                                    </Col>
                                    <Col lg={6}>
                                        <div className="mb-3">
                                            {this.state.loading ?
                                                <><Skeleton width={100} /><Skeleton height={37} /></> :
                                                <>
                                                    <AvField type="select" name="unit_id" label="Unit" errorMessage="Choose Unit"
                                                    // validate={{ required: { value: true } }}   
                                                    >
                                                        <option value="" key="" >--Select--</option>
                                                        {this.state.units.map((unit) => (
                                                            <option value={unit.id} key={unit.id} >{unit.unit}</option>
                                                        ))}
                                                    </AvField>
                                                </>}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.state.loading ?
                                            <><Skeleton width={100} /><Skeleton height={37} /></> :
                                            <>
                                                <label htmlFor="progresspill-address-input">
                                                    Description
                                                </label>
                                                <div className="mb-3">
                                                    <AvField
                                                        name="description"
                                                        type="textarea"
                                                        placeholder="Enter description"
                                                    // errorMessage="Enter description"
                                                    // validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </>}
                                    </Col>
                                </Row>

                            </div>
                        </div>
                        <div className="modal-footer justify-content-center">
                            {this.state.loading ?
                                <><Skeleton width={100} height={37} />{" "}<Skeleton width={100} height={37} /></> :
                                <>
                                    {this.state.isProgress ?
                                        <>
                                            <button type="submit" className="btn btn-primary " disabled={true}>
                                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Submiting
                                            </button>
                                        </> :
                                        <>
                                            <button
                                                type="button"
                                                onClick={() => this.props.closeProductModal()}
                                                className="btn btn-secondary "
                                                data-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                            <button type="submit" className="btn btn-primary ">
                                                Submit
                                            </button>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </AvForm>
                </Modal>
            </React.Fragment >
        )
    }
}
const mapStateToProps = (state:{ units: any,taxes: any }) => {   
    return{  
        units: state.units.units,      
        taxes: state.taxes.taxes
    }
}
const mapDispatchToProps = (dispatch: Dispatch<any>) => {   
    return{
        getProducts: () => {
            dispatch(getProducts());
        }
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(ProductCreateModal));
