import React, { Component, Dispatch } from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Table } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import "../Datatable/datatables.scss";
import { del, get, post } from "src/helpers/api_helper";
import NoDataIndication from "../Datatable/NoDataIndication";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { getProducts } from "src/store/actions";
import { connect } from "react-redux";
import { PermissionModule } from "src/store/permissions/actionTypes";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MetaTags from "react-meta-tags";

interface MyProps extends RouteComponentProps<any> {
    permissions: Array<any>;
}

interface MyState {
    sizePerPage: number;
    totalSize: number;
    data: any;
    sortField: string;
    sortOrder: string;
    permissions: Array<any>;
    loading: boolean;
};

interface ChangeProp {
    page: number;
    sizePerPage: number;
}

class Payments extends Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);
        this.state = {
            sizePerPage: 10,
            totalSize: 0,
            data: [],
            sortField: "id",
            sortOrder: "asc",
            permissions: [],
            loading: true
        };

    }

    fetchData = (param: any) => {
        const sendGetRequest = async () => {
            try {
                const resp = await post(process.env.REACT_APP_API_URL + `/api/gst_payments_list_grid`, param);

                if (resp.success) {
                    this.setState({
                        totalSize: resp.data.count,
                        data: resp.data.results
                    });
                }

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        sendGetRequest();
    }
    loadData = async () => {
        try {
            let productPermissions = this.props.permissions.filter(permission => permission.module_permission_id === PermissionModule.PRODUCT);
            this.setState({ permissions: productPermissions, loading: false });

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    }
    componentDidMount() {
        this.loadData();
    }

    handleTableChange = (type: string, param: ChangeProp) => {
        this.setState({ sizePerPage: param.sizePerPage });
        this.fetchData(param);

    }
    padLeadingZeros(num: number, size: number) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    render() {

        const pageOptions: any = {
            sizePerPage: this.state.sizePerPage,
            totalSize: this.state.totalSize, // replace later with size(customers),
            custom: true,
        };

        const { SearchBar } = Search;

        const defaultSorted: any = [
            {
                dataField: this.state.sortField,
                order: this.state.sortOrder,
            },
        ];

        const columns = [
            {
                dataField: "id",
                text: "Id",
                sort: true,
                hidden: true
            },
            {
                dataField: "return_no",
                text: "Ticket Id",
                sort: true,
                formatter: (cellContent: any, row: any) => {
                    return (
                        <>RTN{this.padLeadingZeros(row.return_no, 5)}</>
                    );
                }
            },
            {
                dataField: "razorpay_payment_id",
                text: "Payment Id",
                sort: true,
            },
            {
                dataField: "payment_amount",
                text: "Payment Amount",
                sort: true,
            },
            {
                dataField: "payment_date",
                text: "Payment Date",
                sort: true,
            }
        ];

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>GST Payments List</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="GST Payment Submission" breadcrumbItem="GST Payments List" />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.data}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>                                                            

                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            // responsive                                  
                                                                            remote
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            classes={"table align-middle table-nowrap"}
                                                                            headerWrapperClasses={"thead-light"}
                                                                            onTableChange={this.handleTableChange}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            noDataIndication={() => <NoDataIndication />}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state: { permissions: any }) => {
    return {
        permissions: state.permissions.permissions
    }
}

export default connect(mapStateToProps)(withRouter(Payments));