import moment from 'moment';
import React, { useState } from 'react';
import MetaTags from "react-meta-tags";
import { Link } from 'react-router-dom';
import {
    Row, Col, Card, CardBody, CardTitle,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    CardHeader,
    Nav,
    Table,
    BreadcrumbItem,
    Button,
} from "reactstrap";
import { getMonthName } from 'src/helpers/common';
import Grid from '../Datatable/Grid';
import Documents from './Documents';

const MyOrders = () => {
    const [activeTab, setActiveTab] = useState<number>(1);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [popData, setPopData] = useState<any>(null);
    const [allCount, setAllCount] = useState<number>(0);
    const [pendingCount, setPendingCount] = useState<number>(0);
    const [processingCount, setProcessingCount] = useState<number>(0);
    const [onHoldCount, setOnHoldCount] = useState<number>(0);
    const [completedCount, setCompletedCount] = useState<number>(0);
    const title = "My Orders";
    const breadcrumbItem = "My Orders";
    const get_status = (status: number) => {
        if (status == 4) {
            return (
                <span className="card-btn card-btn-hold">On Hold</span>
            )
        } else if (status == 3) {
            return (
                <span className="card-btn card-btn-done">Completed</span>
            )
        } else if (status == 2) {
            return (
                <span className="card-btn card-btn-progress">Processing</span>
            )
        } else {
            return (
                <span className="card-btn card-btn-assigned">Not Assigned</span>
            )
        }
    }
    const openModal = (data: any) => {
        setIsOpenModal(true);
        setPopData(data);
    }
    const columns = [
        {
            dataField: "id",
            text: "Id",
            sort: true,
            hidden: true
        },
        {
            dataField: "payment_date",
            text: "Date",
            sort: true,
            formatter: (cellContent: any, row: any) => {
                return (
                    <>{moment(cellContent).format("DD/MM/YYYY")}</>
                )
            }
        },
        {
            dataField: "service",
            text: "Service",
            sort: true,
            formatter: (cellContent: any, row: any) => {
                return (
                    <>{cellContent}</>
                );
            }
        },
        {
            dataField: "month",
            text: "Period",
            sort: true,
            formatter: (cellContent: any, row: any) => {
                return (
                    <>{getMonthName(row.month)} {row.year}</>
                );
            }
        },
        {
            dataField: "status",
            text: "Status",
            sort: true,
            formatter: (cellContent: any, row: any) => {
                return (
                    <>{get_status(row.status)}</>
                );
            }
        },
        {
            dataField: "payment_date",
            text: "Documents",
            sort: true,
            formatter: (cellContent: any, row: any) => {
                return (
                    <>
                        <div className='table-button btns-inv'>
                            <Link to="#" onClick={() => openModal(row)}><button className="brown-btn" title="Documents"><i className="fas fas fa-file-alt doc-icon"></i> Documents</button></Link>
                        </div>
                    </>
                );
            }
        }
    ];
    const defaultSorted: any = [
        {
            dataField: "return_no",
            order: "desc",
        },
    ];
    return (
        <React.Fragment>
            <div className="page-content" style={{ minHeight: '685px' }}>
                <MetaTags>
                    <title>{title}</title>
                </MetaTags>
                <div className="container-fluid">
                    <Row>
                        <Col xs="12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between form-wrap">
                                <div className="header"> <h4 className="mb-0 font-size-18">{breadcrumbItem}</h4>
                                </div>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <BreadcrumbItem>
                                            <Link to="#">{title}</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem active>
                                            <Link to="#">{breadcrumbItem}</Link>
                                        </BreadcrumbItem>
                                    </ol>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12">
                            <Card>

                                <CardHeader>
                                    <div className="row">
                                        <div className="col-md-12 col-xl-10">

                                            <ul className="values_orders">

                                                <li className={activeTab == 1 ? "active" : ""}>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setActiveTab(1)
                                                        }}
                                                    >
                                                        <span>All Order</span>

                                                    </NavLink>
                                                </li>
                                                <li className={activeTab == 2 ? "active" : ""}>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setActiveTab(2)
                                                        }}
                                                    >
                                                        <span>Not Assigned</span>

                                                    </NavLink>
                                                </li>
                                                <li className={activeTab == 3 ? "active" : ""}>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setActiveTab(3)
                                                        }}
                                                    >
                                                        <span>Processing</span>

                                                    </NavLink>
                                                </li>
                                                <li className={activeTab == 4 ? "active" : ""}>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setActiveTab(4)
                                                        }}
                                                    >
                                                        <span>On Hold</span>

                                                    </NavLink>
                                                </li>
                                                <li className={activeTab == 5 ? "active" : ""}>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setActiveTab(5)
                                                        }}
                                                    >
                                                        <span>Completed</span>

                                                    </NavLink>
                                                </li>
                                            </ul>
                                            <ul className="my-order-count">
                                                <li>{allCount} Orders</li>
                                                <li>{pendingCount} Orders</li>
                                                <li>{processingCount} Orders</li>
                                                <li>{onHoldCount} Orders</li>
                                                <li>{completedCount} Orders</li>
                                            </ul>
                                        </div>

                                    </div>

                                </CardHeader>

                                <CardBody className="pt-0">

                                    <TabContent
                                        activeTab={activeTab}
                                        className="text-muted my-order"
                                    >
                                        <TabPane tabId={1}>
                                            <Row>
                                                <Col sm="12">
                                                    <Grid link='my_orders_all_list_grid' columns={columns} defaultSorted={defaultSorted} setCount={setAllCount}  />
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId={2}>
                                            <Row>
                                                <Col sm="12">
                                                    <Grid link='my_orders_pending_list_grid' columns={columns} defaultSorted={defaultSorted} setCount={setPendingCount} />
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId={3}>
                                            <Row>
                                                <Col sm="12">
                                                    <Grid link='my_orders_processing_list_grid' columns={columns} defaultSorted={defaultSorted} setCount={setProcessingCount} />
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId={4}>
                                            <Row>
                                                <Col sm="12">
                                                    <Grid link='my_orders_on_hold_list_grid' columns={columns} defaultSorted={defaultSorted} setCount={setOnHoldCount} />
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId={5}>
                                            <Row>
                                                <Col sm="12">
                                                    <Grid link='my_orders_completed_list_grid' columns={columns} defaultSorted={defaultSorted} setCount={setCompletedCount} />
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
            {isOpenModal && <Documents isOpen={isOpenModal} toggle={() => setIsOpenModal(false)} data={popData} />}
        </React.Fragment>
    );
};

export default MyOrders;