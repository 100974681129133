import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { get } from 'src/helpers/api_helper';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import GstReturnModal from './GstReturnModal';

const Services = (props: any) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [settings, setSettings] = useState<any>([]);
    useEffect(() => {
        getSettings();
    }, []);
    const getSettings = async () => {
        const respS: any = await get(process.env.REACT_APP_API_URL + `/api/gst_settings`);
        setSettings(respS.data);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>GST Services</title>
                </MetaTags>
                <div className="container-fluid heading-pb-0">
                    <Breadcrumbs title="GST Services" breadcrumbItem="GST Services"/>
                    <div className="gst_bx_wrap">
                        <div className="row">

                            <div className="col-lg-4">
                                <div className="gst_bx">
                                    <div className="thump">
                                        <span className="c1">GST</span>
                                    </div>
                                    <h1>GST Return</h1>
                                    <p>Complete your GST return easily and quickly</p>
                                    {/* <div className="d-flex justify-content-between align-items-center rate-wrap">
                                        <span className="rate">₹0.00 Onwards </span><a href="#" onClick={() => setIsOpenModal(true)}>Buy Now</a>
                                    </div> */}
                                    <div className="d-flex justify-content-center align-items-center rate-wrap btn-buynow">
                                        <a href="#" onClick={() => setIsOpenModal(true)}>Buy Now</a>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center rate-wrap">
                                        ₹0.00 Onwards
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="gst_bx">
                                    <div className="thump">
                                        <span className="c2">GST</span>
                                    </div>
                                    <h1>GST Registration</h1>
                                    <p>GST Registration for any type of business</p>
                                    {/* <div className="d-flex justify-content-between align-items-center rate-wrap">
                                        <span className="rate">₹499.00 Onwards </span><a href="#" >Buy Now</a>
                                    </div> */}
                                    <div className="d-flex justify-content-center align-items-center rate-wrap btn-buynow">
                                        <a href="#" >Buy Now</a>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center rate-wrap">
                                        ₹499.00 Onwards
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="gst_bx">
                                    <div className="thump">
                                        <span className="c3">GST</span>
                                    </div>
                                    <h1>GST LUT</h1>
                                    <p>Generate LUT quickly</p>
                                    {/* <div className="d-flex justify-content-between align-items-center rate-wrap">
                                        <span className="rate">₹499.00 Onwards </span><a href="#" >Buy Now</a>
                                    </div> */}
                                    <div className="d-flex justify-content-center align-items-center rate-wrap btn-buynow">
                                        <a href="#" >Buy Now</a>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center rate-wrap">
                                        ₹499.00 Onwards
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="gst_bx">
                                    <div className="thump">
                                        <span className="c4">GST</span>
                                    </div>
                                    <h1>GST Amendment</h1>
                                    <p>Any Amendment in GST, order here</p>
                                    {/* <div className="d-flex justify-content-between align-items-center rate-wrap">
                                        <span className="rate">₹499.00 Onwards </span><a href="#" >Buy Now</a>
                                    </div> */}
                                    <div className="d-flex justify-content-center align-items-center rate-wrap btn-buynow">
                                        <a href="#" >Buy Now</a>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center rate-wrap">
                                        ₹499.00 Onwards
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="gst_bx">
                                    <div className="thump">
                                        <span className="c5">ITR</span>
                                    </div>
                                    <h1>ITR Filing</h1>
                                    <p>Complete your ITR filing easly and quickly</p>
                                    {/* <div className="d-flex justify-content-between align-items-center rate-wrap">
                                        <span className="rate">₹499.00 Onwards </span><a href="#" >Buy Now</a>
                                    </div> */}
                                    <div className="d-flex justify-content-center align-items-center rate-wrap btn-buynow">
                                        <a href="#" >Buy Now</a>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center rate-wrap">
                                        ₹499.00 Onwards
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="gst_bx">
                                    <div className="thump">
                                        <span className="c6">DSC</span>
                                    </div>
                                    <h1>Digital Signature</h1>
                                    <p>Buy your DSC easily and quickly</p>
                                    {/* <div className="d-flex justify-content-between align-items-center rate-wrap">
                                        <span className="rate">₹999.00 Onwards </span><a href="#" >Buy Now</a>
                                    </div> */}
                                    <div className="d-flex justify-content-center align-items-center rate-wrap btn-buynow">
                                        <a href="#" >Buy Now</a>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center rate-wrap">
                                        ₹999.00 Onwards
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="gst_bx">
                                    <div className="thump">
                                        <span className="c7">REG</span>
                                    </div>
                                    <h1>Registration</h1>
                                    <p>Order here fof any business registration</p>
                                    {/* <div className="d-flex justify-content-center align-items-center rate-wrap">
                                        <a className="intrest" href="#">I am Intrested</a>
                                    </div> */}
                                    <div className="d-flex justify-content-center align-items-center rate-wrap">
                                        <a href="#" >I am Intrested</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="gst_bx">
                                    <div className="thump">
                                        <span className="c8">PR</span>
                                    </div>
                                    <h1>Payroll</h1>
                                    <p>Generate Pay statement and Payslip</p>
                                    {/* <div className="d-flex justify-content-center align-items-center rate-wrap">
                                        <a className="intrest" href="#">I am Intrested</a>
                                    </div> */}
                                    <div className="d-flex justify-content-center align-items-center rate-wrap">
                                        <a href="#" >I am Intrested</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="gst_bx">
                                    <div className="thump">
                                        <span className="c9">AC</span>
                                    </div>
                                    <h1>Book Keeping</h1>
                                    <p>Hire online accountant and save 3X cost</p>
                                    {/* <div className="d-flex justify-content-center align-items-center rate-wrap">
                                        <a href="#">I am Intrested</a>
                                    </div> */}
                                    <div className="d-flex justify-content-center align-items-center rate-wrap">
                                        <a href="#" >I am Intrested</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {isOpenModal && <GstReturnModal modal_is_open={isOpenModal} closeModal={() => setIsOpenModal(false)} />}
        </React.Fragment>
    );
};

export default Services;