import React, { Component, Dispatch } from "react";
import MetaTags from "react-meta-tags";
import { Container, Collapse } from "reactstrap";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { get, post } from "src/helpers/api_helper";
import classnames from "classnames";
import { connect } from "react-redux";
import { getPermissions } from "src/store/actions";

interface PermissionParams extends RouteComponentProps<any> {
    index: number;
    permission : { permission_id :number ,permission_name : string, actions : Array<any> };
    open : boolean;
    getPermisions: () => void
  }

type PermissionState = {
    isOpen : boolean;
};

interface LoopActions {    
    id: number; 
    role_id: string; 
    module_permission_id: number; 
    system_action_id: number; 
    label: string;
    is_active : number;
}

class Accordion extends Component<PermissionParams, PermissionState> {
    constructor(props: any) {
        super(props);        
        this.state = {
            isOpen : this.props.open
        };
    }
    onChangehandler = (e: any) => {
        // let name = e.target.name;
        let value = e.target.value;
        let checked = e.target.checked;
        const sendGetRequest = async () => {
            try {                
                const resp: any = await post(process.env.REACT_APP_API_URL + '/api/save_permission',{ checked : checked , value : value });
                this.props.getPermisions();
            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        sendGetRequest();
    }
    render() {
        return (
            <React.Fragment>
                <div
                    className="accordion"
                    id="accordionFlushExample"
                    key={this.props.index}
                >
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFlushOne">
                            <button
                                className={classnames(
                                    "accordion-button",
                                    "fw-medium",
                                    { collapsed: !this.state.isOpen }
                                )}
                                type="button"
                                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                                style={{ cursor: "pointer" }}
                            >
                                {this.props.permission.permission_name}
                            </button>
                        </h2>
                        <Collapse isOpen={this.state.isOpen} className="accordion-collapse">
                            <div className="accordion-body">
                                <Row>
                                    {this.props.permission.actions.map((action: LoopActions, index: number) => (
                                        <Col md={4} key={index}>
                                            <div className="form-check form-switch mb-3" dir="ltr">
                                                <input type="checkbox" className="form-check-input" id={`customSwitch${action.id}-${action.role_id}-${this.props.permission.permission_id}-${action.system_action_id}`} onChange={this.onChangehandler} value={`${action.id}|${action.role_id}|${this.props.permission.permission_id}|${action.system_action_id}`} defaultChecked={action.is_active ? true : false} />
                                                <label className="form-check-label" htmlFor={`customSwitch${action.id}-${action.role_id}-${this.props.permission.permission_id}-${action.system_action_id}`}>{action.label} </label>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </Collapse>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}
const mapDispatchToProps = (dispatch: Dispatch<any>) => {   
    return{
        getPermisions: () => {
            dispatch(getPermissions());
        }
    }
}
export default connect(null,mapDispatchToProps)(withRouter(Accordion)); 