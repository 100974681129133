import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, UncontrolledAlert } from 'reactstrap';
import { getMonthName } from 'src/helpers/common';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getAccessToken, post } from 'src/helpers/api_helper';
import axios from 'axios';
import { isEmpty } from 'lodash';

interface Props {
    isOpen: boolean;
    toggle: () => void;
    data: any;
    // getData: () => void;
}
const Documents = (props: Props) => {
    const [isHideSuccessMgs, setIsHideSuccessMgs] = useState(true);
    const [isHideErrorMgs, setIsHideErrorMgs] = useState(true);
    const [isModalProgress, setIsModalProgress] = useState(false);
    const [errorMgs, setErrorMgs] = useState("Somthing went wrong, Please try again");
    const { companies } = useSelector((state: any) => ({
        companies: state.companies.companies,
    }));    
    const handleValidSubmit = async (event: any, values: any) => {
        setIsModalProgress(true);
        setIsHideSuccessMgs(true);
        setIsHideErrorMgs(true);
        const userForm: any = document.getElementById('ticketForm');
        const formData = new FormData(userForm);

        formData.append("id", props.data.id);
        var token = getAccessToken();
        const resp: any = await axios.post(process.env.REACT_APP_API_URL + '/api/save_gst_files', formData, { headers: { "Authorization": token } });

        if (resp.data.success == true) {
            setIsHideSuccessMgs(false);
            // props.getData();
            props.toggle();
        } else {
            setErrorMgs(resp.data.message);
            setIsHideErrorMgs(false);
        }
        setIsModalProgress(false);
    }    
    const removeFile = async (id: string, index: number) => {
        const conf = window.confirm("Do you want to delete this file?");
        if (conf) {
            const resp: any = await post(process.env.REACT_APP_API_URL + '/api/gst_rm_file', { id, index });
            if (resp.success == true) {
                let element: HTMLElement | null = document.getElementById("file_" + index);
                element?.parentNode?.removeChild(element)
                // props.getData();
            }
            else {
                setIsHideErrorMgs(false);
            }
        }
    }

    return (
        <React.Fragment>
            <Modal
                isOpen={props.isOpen}
                role="dialog"
                autoFocus={true}
                centered={true}
            >
                <AvForm onValidSubmit={handleValidSubmit} encType='multipart/formData' id="ticketForm">
                <div className="modal-dialog modal-dialog-centered modal-custom-size">
                    <div className="modal-content" >
                        <UncontrolledAlert hidden={isHideSuccessMgs} color="success">
                            Documents Saved successfully
                        </UncontrolledAlert>

                        <UncontrolledAlert hidden={isHideErrorMgs} color="danger">
                            {errorMgs}
                        </UncontrolledAlert>                        
                            <div className="modal-body border-0" >
                                <div className="row">                                    
                                    <div className="col-lg-12">                                        
                                        <div className="fw-bold fs-20 mt-3 mb-2">
                                            Upload Documents
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                name="file1"
                                                id="file1"
                                                type="file"
                                                placeholder="Choose File"
                                                className="form-control form-control-sm"
                                                accept=".pdf, .csv, .xls, .xlsx"
                                            // onChange={(e:any) => handleChange(e)}                                                
                                            />
                                            {!isEmpty(props.data.c_file1) && (
                                                <div className='table-button my-2' id='file_1'>
                                                    <a href={`${process.env.REACT_APP_IMG_URL}${props.data.c_file1}`} target="_blank">{props.data.c_file_name1}</a> <Link to="#" onClick={() => removeFile(props.data.id, 1)}><i className="fas fa-trash-alt" style={{ color: "red" }}></i></Link>
                                                </div>
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                name="file2"
                                                id="file2"
                                                type="file"
                                                placeholder="Choose File"
                                                className="form-control form-control-sm"
                                                accept=".pdf, .csv, .xls, .xlsx"
                                            />
                                            {!isEmpty(props.data.c_file2) && (
                                                <div className='table-button my-2' id='file_1'>
                                                    <a href={`${process.env.REACT_APP_IMG_URL}${props.data.c_file2}`} target="_blank">{props.data.c_file_name2}</a> <Link to="#" onClick={() => removeFile(props.data.id, 2)}><i className="fas fa-trash-alt" style={{ color: "red" }}></i></Link>
                                                </div>
                                            )}
                                        </div>
                                        <div className="mb-3">
                                            <AvField
                                                name="file3"
                                                id="file3"
                                                type="file"
                                                placeholder="Choose File"
                                                className="form-control form-control-sm"
                                                accept=".pdf, .csv, .xls, .xlsx"
                                            />
                                            {!isEmpty(props.data.c_file3) && (
                                                <div className='table-button my-2' id='file_1'>
                                                    <a href={`${process.env.REACT_APP_IMG_URL}${props.data.c_file3}`} target="_blank">{props.data.c_file_name3}</a> <Link to="#" onClick={() => removeFile(props.data.id, 3)}><i className="fas fa-trash-alt" style={{ color: "red" }}></i></Link>
                                                </div>
                                            )}
                                        </div>
                                        <div className="fw-bold fs-20 mt-3 mb-2">
                                            Download Documents
                                        </div>
                                        <div className="mb-4">
                                            {!isEmpty(props.data.file1) && <div className="popup-box-b-c d-flex justify-content-between align-items-center">
                                                <span>{props.data.file_name1}</span>
                                                <a href={`${process.env.REACT_APP_IMG_URL}${props.data.file1}`} className="dl-btn d-flex align-items-center" target="_blank">
                                                    <span className="feathers fea-download-icon"></span>
                                                </a>
                                            </div>}
                                            {!isEmpty(props.data.file2) && <div className="popup-box-b-c d-flex justify-content-between align-items-center">
                                                <span>{props.data.file_name2}</span>
                                                <a href={`${process.env.REACT_APP_IMG_URL}${props.data.file2}`} className="dl-btn d-flex align-items-center" target="_blank">
                                                    <span className="feathers fea-download-icon"></span>
                                                </a>
                                            </div>}
                                            {!isEmpty(props.data.file3) && <div className="popup-box-b-c d-flex justify-content-between align-items-center">
                                                <span>{props.data.file_name3}</span>
                                                <a href={`${process.env.REACT_APP_IMG_URL}${props.data.file3}`} className="dl-btn d-flex align-items-center" target="_blank">
                                                    <span className="feathers fea-download-icon"></span>
                                                </a>
                                            </div>}

                                        </div>
                                        <div className="d-block text-center">
                                            {isModalProgress ?
                                                (
                                                    <button type="submit" className="btn btn-primary " disabled={true}>
                                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Submiting
                                                    </button>
                                                ) : (
                                                    <>
                                                        <button type="button" className="btn btn-secondary mw-100" onClick={props.toggle}>
                                                            Close
                                                        </button>{" "}
                                                        <button type="submit" className="btn btn-primary mw-100">
                                                            Save
                                                        </button>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>                        
                    </div>
                </div>
                </AvForm>
            </Modal>
        </React.Fragment>
    );
};

export default Documents;