import React, { useEffect, useState } from 'react';
import MetaTags from "react-meta-tags";
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { Button, Card, CardBody, CardHeader, Col, Row, BreadcrumbItem } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from 'moment';
import Month from './Month';
import { get, post } from 'src/helpers/api_helper';
import { Razorpay } from "../Authentication/Razorpay";
import { round } from "lodash";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import RecordIcon from 'src/assets/images/icons/Record';
import PaymentDetails from './PaymentDetails';
import "../../assets/scss/card.css";
import { getMonthName } from 'src/helpers/common';
import Select from 'react-select';
import { ActionMeta, OnChangeValue } from "react-select/dist/declarations/src";

interface razerpay_init {
    r_key: string;
    order_id: string;
    amount: number;
    currency: string;
    merchant_order_id: string;
}
interface response {
    razorpay_payment_id: string;
    razorpay_order_id: string;
    razorpay_signature: string;
    orderId: string;
}
interface Option {
    label: string;
    value: string;
}

const createOption = (label: string, value: string) => ({
    label,
    value,
});
function GstReturn(props: any) {
    const companies = useSelector((state: any) => state.companies.companies);
    const [years, setYears] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const [settings, setSettings] = useState<any>([]);
    const [boxNo, setBoxNo] = useState(0);
    const [actYear, setActYear] = useState(moment().year());
    const [actYear2, setActYear2] = useState((actYear + 1));
    const [data1, setData1] = useState<any>(null);
    const [data2, setData2] = useState<any>(null);
    const [data3, setData3] = useState<any>(null);
    const [data4, setData4] = useState<any>(null);
    const [data5, setData5] = useState<any>(null);
    const [data6, setData6] = useState<any>(null);
    const [data7, setData7] = useState<any>(null);
    const [data8, setData8] = useState<any>(null);
    const [data9, setData9] = useState<any>(null);
    const [data10, setData10] = useState<any>(null);
    const [data11, setData11] = useState<any>(null);
    const [data12, setData12] = useState<any>(null);
    const [isProgress, setIsProgress] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [popData, setPopData] = useState<any>(null);
    const [optionsYear, setOptionsYear] = useState<Option[]>([]);
    const [valueCustomer, setValueCustomer] = useState<Option | null | undefined>(null);
    const [nos, setNos] = useState<number>(0);
    const [free, setFree] = useState<number>(0);
    const [pay, setpay] = useState<number>(0);
    useEffect(() => {
        let curMonth = moment().month();
        if (curMonth < 3) {
            setActYear(actYear - 1);
        }
        getSettings();
        let yearStart = (actYear - 10);
        var arrYears = [];
        for (let i = yearStart; i <= actYear; i++) {
            arrYears.push(i);
        }
        arrYears.reverse();
        setYears(arrYears);

        let yearOptions: Array<any> = [];

        arrYears.map((year: number) => {
            let yearVal = `${year}`;
            let yearLabel = `${year}-${year + 1}`;
            let newOption = createOption(yearLabel, yearVal);
            yearOptions = [...yearOptions, newOption];
        }
        )
        setOptionsYear(yearOptions)

    }, [])
    useEffect(() => {
        setActYear2((Number(actYear) + 1));
        let yearValDef = `${actYear}`;
        let yearLabelDef = `${actYear}-${actYear + 1}`;
        const yearOption = createOption(yearLabelDef, yearValDef);
        setValueCustomer(yearOption);
    }, [actYear])
    useEffect(() => {
        getData();
    }, [actYear2])
    const getSettings = async () => {
        const respS: any = await get(process.env.REACT_APP_API_URL + `/api/gst_settings`);
        setSettings(respS.data);
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
    }
    const getData = async () => {
        //clear data
        setBoxNo(0);
        setFree(0);
        setpay(0);

        const respR: any = await get(process.env.REACT_APP_API_URL + `/api/gst_returns/${actYear}`);
        setData(respR.data);
        setDataAll(respR.data);
        const respNos: any = await get(process.env.REACT_APP_API_URL + `/api/gst_return_nos`);
        setNos(respNos.data.nos);
    }
    const setDataAll = (dat: any) => {
        let newdata1 = dat.find(({ year, month }: { year: number, month: number }) => year == actYear2 && month == 1);
        setData1(newdata1);
        let newdata2 = dat.find(({ year, month }: { year: number, month: number }) => year == actYear2 && month == 2);
        setData2(newdata2);
        let newdata3 = dat.find(({ year, month }: { year: number, month: number }) => year == actYear2 && month == 3);
        setData3(newdata3);
        let newdata4 = dat.find(({ year, month }: { year: number, month: number }) => year == actYear && month == 4);
        setData4(newdata4);
        let newdata5 = dat.find(({ year, month }: { year: number, month: number }) => year == actYear && month == 5);
        setData5(newdata5);
        let newdata6 = dat.find(({ year, month }: { year: number, month: number }) => year == actYear && month == 6);
        setData6(newdata6);
        let newdata7 = dat.find(({ year, month }: { year: number, month: number }) => year == actYear && month == 7);
        setData7(newdata7);
        let newdata8 = dat.find(({ year, month }: { year: number, month: number }) => year == actYear && month == 8);
        setData8(newdata8);
        let newdata9 = dat.find(({ year, month }: { year: number, month: number }) => year == actYear && month == 9);
        setData9(newdata9);
        let newdata10 = dat.find(({ year, month }: { year: number, month: number }) => year == actYear && month == 10);
        setData10(newdata10);
        let newdata11 = dat.find(({ year, month }: { year: number, month: number }) => year == actYear && month == 11);
        setData11(newdata11);
        let newdata12 = dat.find(({ year, month }: { year: number, month: number }) => year == actYear && month == 12);
        setData12(newdata12);
    }
    useEffect(() => {
        freeHandler();
    }, [boxNo])
    const freeHandler = () => {
        if (boxNo <= nos) {
            setFree(boxNo);
            setpay(0);
        } else {
            setFree(nos);
            setpay((boxNo - nos));
        }
    }

    const clickAddMonth = (month: number, year: number) => {
        let newData = data;
        newData.push({ id: '0', year: year, month: month, status: 0 });
        setData(newData);
        let noBoxes = newData.filter(({ id, status }: { id: string, status: number }) => id == '0' && status === 0).length;
        setBoxNo(noBoxes);
        setDataAll(newData);

    }

    const clickRemoveMonth = (pMonth: number, pYear: number) => {
        let oldData = data;
        let i = oldData.findIndex(({ year, month }: { year: number, month: number }) => year == pYear && month == pMonth);
        oldData.splice(i, 1);
        let noBoxes = oldData.filter(({ id, status }: { id: string, status: number }) => id == '0' && status === 0).length;
        setBoxNo(noBoxes);
        setDataAll(oldData);
    }

    const loadScript = (src: string) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    const onPaymentHandler = (response: response) => {
        const sendGetRequest = async () => {
            try {
                setIsProgress(true);
                let amount: number = round((pay * settings.total_amount), 2);
                let dtls = data.filter(({ id, status }: { id: string, status: number }) => id == '0' && status === 0);
                const resp = await post(process.env.REACT_APP_API_URL + '/api/gst_return_save', { 'amount': amount, 'razorpay_order_id': response.razorpay_order_id, 'razorpay_payment_id': response.razorpay_payment_id, 'razorpay_signature': response.razorpay_signature, 'dtls': dtls });
                if (resp.success) {
                    getData();
                    setIsProgress(false);
                } else {

                }
                setIsProgress(false);
            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        sendGetRequest();
    }
    const freeSubs = () => {
        const sendGetRequest = async () => {
            try {
                setIsProgress(true);
                let dtls = data.filter(({ id, status }: { id: string, status: number }) => id == '0' && status === 0);
                const resp = await post(process.env.REACT_APP_API_URL + '/api/gst_return_save_free', { 'dtls': dtls });
                if (resp.success) {
                    getData();

                } else {

                }
                setIsProgress(false);
            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        sendGetRequest();
    }
    const makePayment = async (event: any, values: any) => {
        if (boxNo) {
            if (boxNo <= nos) {
                freeSubs();
            } else {
                setIsProgress(true);
                let amount: number = round((pay * settings.total_amount), 2);
                const result = await post(process.env.REACT_APP_API_URL + '/api/razorpay_init', { amount: amount });

                if (!result) {
                    console.log("Server error.");
                }

                let data: razerpay_init = result.data;

                const orderId = data.order_id;
                const appName = process.env.REACT_APP_NAME;
                const options = {
                    key: data.r_key,
                    amount: data.amount,
                    currency: data.currency,
                    name: appName,
                    description: `GST return submission payment`,
                    order_id: orderId,
                    handler: onPaymentHandler,
                    prefill: {
                        name: companies.company_name,
                        email: companies.company_email,
                        contact: companies.company_telephone,
                    },
                    notes: {
                        address: companies.company_address1,
                        merchant_order_id: data.merchant_order_id
                    },
                    theme: {
                        color: "#6266b0",
                    },
                };

                const paymentObject = Razorpay(options);
                paymentObject.open();
            }
        }

    }
    const openModal = (data: any) => {
        setIsOpenModal(true);
        setPopData(data);
    }
    const handleChange = async (
        newValue: OnChangeValue<Option, false>,
        actionMeta: ActionMeta<Option>
    ) => {
        setValueCustomer(newValue);
        setActYear(Number(newValue?.value));
    }
    const discountCol = () => {
        if (boxNo && nos) {
            return <>
                <span className="d-block mb-4">Discount Amount</span>
                <span className="d-block fw-bold fs-16 mb-2">
                    <span className="feathers fea-rupee-icon"></span>
                    {round(free * settings.total_amount, 2)} <span className='fw-normal fs-16'>({free} months)</span>
                </span>
            </>
        }
    }
    return (
        <React.Fragment>

            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col xs="12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between form-wrap">
                                <div className="header">
                                    <h4 className="mb-0 font-size-18">
                                        Book GST Return
                                    </h4>
                                    <div className="form-inline">
                                        <AvForm >
                                            <Row>
                                                <Col lg={10}>

                                                    <Select
                                                        // isClearable={true}
                                                        onChange={handleChange}
                                                        options={optionsYear}
                                                        value={valueCustomer}
                                                        name="year"
                                                        placeholder="Fiancial Year"
                                                    />

                                                </Col>
                                                <Col lg={2}>
                                                    <ul className="qck-link1">
                                                        <li>
                                                            <Link to="/gst_payments" >
                                                                <span className='pay-history' title='Payment History'><RecordIcon /></span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </div>
                                </div>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <BreadcrumbItem>
                                            <Link to="/gst_services">GST Services</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem active>
                                            <Link to="#">GST Return</Link>
                                        </BreadcrumbItem>
                                    </ol>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <AvForm onValidSubmit={makePayment}>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody className='mt-4'>
                                        <div className="body-color">
                                            <div className="container ">
                                                <div className="row gy-4">
                                                    <div className="col-lg-8">
                                                        <div className="row gy-4 mb-3">

                                                            <Month year={actYear} month={4} monthStr={"April"} clickAddMonth={() => clickAddMonth(4, actYear)} clickRemoveMonth={() => clickRemoveMonth(4, actYear)} data={data4} clickOpenModal={openModal} amount={settings.amount} />
                                                            <Month year={actYear} month={5} monthStr={"May"} clickAddMonth={() => clickAddMonth(5, actYear)} clickRemoveMonth={() => clickRemoveMonth(5, actYear)} data={data5} clickOpenModal={openModal} amount={settings.amount} />
                                                            <Month year={actYear} month={6} monthStr={"June"} clickAddMonth={() => clickAddMonth(6, actYear)} clickRemoveMonth={() => clickRemoveMonth(6, actYear)} data={data6} clickOpenModal={openModal} amount={settings.amount} />
                                                            <Month year={actYear} month={7} monthStr={"July"} clickAddMonth={() => clickAddMonth(7, actYear)} clickRemoveMonth={() => clickRemoveMonth(7, actYear)} data={data7} clickOpenModal={openModal} amount={settings.amount} />
                                                            <Month year={actYear} month={8} monthStr={"August"} clickAddMonth={() => clickAddMonth(8, actYear)} clickRemoveMonth={() => clickRemoveMonth(8, actYear)} data={data8} clickOpenModal={openModal} amount={settings.amount} />
                                                            <Month year={actYear} month={9} monthStr={"September"} clickAddMonth={() => clickAddMonth(9, actYear)} clickRemoveMonth={() => clickRemoveMonth(9, actYear)} data={data9} clickOpenModal={openModal} amount={settings.amount} />
                                                            <Month year={actYear} month={10} monthStr={"October"} clickAddMonth={() => clickAddMonth(10, actYear)} clickRemoveMonth={() => clickRemoveMonth(10, actYear)} data={data10} clickOpenModal={openModal} amount={settings.amount} />
                                                            <Month year={actYear} month={11} monthStr={"November"} clickAddMonth={() => clickAddMonth(11, actYear)} clickRemoveMonth={() => clickRemoveMonth(11, actYear)} data={data11} clickOpenModal={openModal} amount={settings.amount} />
                                                            <Month year={actYear} month={12} monthStr={"December"} clickAddMonth={() => clickAddMonth(12, actYear)} clickRemoveMonth={() => clickRemoveMonth(12, actYear)} data={data12} clickOpenModal={openModal} amount={settings.amount} />
                                                            <Month year={actYear2} month={1} monthStr={"January"} clickAddMonth={() => clickAddMonth(1, actYear2)} clickRemoveMonth={() => clickRemoveMonth(1, actYear2)} data={data1} clickOpenModal={openModal} amount={settings.amount} />
                                                            <Month year={actYear2} month={2} monthStr={"February"} clickAddMonth={() => clickAddMonth(2, actYear2)} clickRemoveMonth={() => clickRemoveMonth(2, actYear2)} data={data2} clickOpenModal={openModal} amount={settings.amount} />
                                                            <Month year={actYear2} month={3} monthStr={"March"} clickAddMonth={() => clickAddMonth(3, actYear2)} clickRemoveMonth={() => clickRemoveMonth(3, actYear2)} data={data3} clickOpenModal={openModal} amount={settings.amount} />

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4">

                                                        <div className="row gy-4">
                                                            <div className="col-12">
                                                                <div className="bg-white card-box-b">
                                                                    <span className="card-box-b-title d-block mb-2">
                                                                        Selected Months
                                                                    </span>
                                                                    <div className="card-box-ba">
                                                                        {data.filter(({ id, status }: { id: string, status: number }) => id == '0' && status === 0).map((d: any) => (
                                                                            <span className="card-box-ba1">{getMonthName(d.month)} {d.year}</span>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="bg-white card-box-b border-a">
                                                                    <div className="card-box-bb mb-4">
                                                                        <span className="d-block mb-4">Total Items</span>
                                                                        <span className="d-block fw-bold fs-20">{boxNo}</span>
                                                                    </div>
                                                                    <div className="card-box-bb mb-4">
                                                                        <span className="d-block mb-4">Total Tax</span>
                                                                        <span className="d-block fw-bold fs-20">
                                                                            <span className="feathers fea-rupee-icon"></span>
                                                                            {pay && round(pay * settings.tax_amount, 2)}
                                                                        </span>
                                                                    </div>
                                                                    <div className="card-box-bb mb-4">
                                                                        {discountCol()}
                                                                        <span className="d-block mb-4">Total Amount</span>
                                                                        <span className="d-block fw-bold fs-20">
                                                                            <span className="feathers fea-rupee-icon"></span>
                                                                            {pay && round(pay * settings.total_amount, 2)}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="bg-white card-box-b mb-4">
                                                                    <div className="card-box-bc mb-3">
                                                                        {pay ? <><span>{pay}</span>
                                                                            <span>x</span>
                                                                            <span>{settings.total_amount}</span>
                                                                            <span>=</span>
                                                                            <span>{round(pay * settings.total_amount, 2)}</span></>
                                                                            : <span>{pay}</span>
                                                                        }

                                                                    </div>

                                                                    {isProgress ?
                                                                        (
                                                                            <Button type="submit" color="primary" style={{ width: '100%' }} disabled={true}>
                                                                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Submiting
                                                                            </Button>
                                                                        ) : (
                                                                            <Button type="submit" color="primary" style={{ width: '100%' }} >
                                                                                {pay ? "Pay and Subscribe" : "Subscribe"}
                                                                            </Button>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </AvForm>
                </div>
            </div>
            {isOpenModal && <PaymentDetails isOpen={isOpenModal} toggle={() => setIsOpenModal(false)} data={popData} getData={getData} />}

        </React.Fragment>
    );
}

export default GstReturn;