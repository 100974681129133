import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Label, Row, Table, UncontrolledDropdown } from 'reactstrap';
import NoDataIndication from '../Datatable/NoDataIndication';
import { get, post } from 'src/helpers/api_helper';
import MetaTags from "react-meta-tags";
import moment from 'moment';
import { AvForm, AvField } from "availity-reactstrap-validation";
import jsPDF from "jspdf";
import 'jspdf-autotable';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { CSVLink, CSVDownload } from "react-csv";


const SalesGstr1 = () => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [years, setYears] = useState<any>([]);
    const [actYear, setActYear] = useState(0);
    const [actMonth, setActMonth] = useState(0);
    const [data, setData] = useState<any>([]);
    const [csvData, setCsvData] = useState<any>([]);
    const [invoice_prefix, setInvoice_prefix] = useState<string>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSetData, setIsSetData] = useState(false);
    const csvHeaders = [
        { label: "Invoice Date", key: "invoice_date" },
        { label: "Invoice Number", key: "invoice_no" },
        { label: "Customer GSTIN", key: "gstin" },
        { label: "State of Supply", key: "shipping_address" },
        { label: "HSN/SAC", key: "hsn_or_sac" },
        { label: "Taxable Value", key: "taxableValue" },
        { label: "GST Rate", key: "tax_per" },
        { label: "Total", key: "amount" },
    ];

    useEffect(() => {
        let yearCurr = moment().year();
        // let yearStart = (yearCurr - 10);
        var arrYears = [];
        for (let i = 2020; i <= (yearCurr + 1); i++) {
            arrYears.push(i);
        }
        arrYears.reverse();
        setYears(arrYears);
    }, [])
    /* useEffect(() => {
        if (actYear && actMonth)
            fetchData();
    }, [actYear, actMonth]) */
    const clickBtn = () => {
        if (actYear && actMonth) {
            fetchData();
            setIsSetData(true);
        }
            
    }

    const fetchData = () => {
        const sendGetRequest = async () => {
            try {
                setIsSubmitting(true);
                const resp = await get(process.env.REACT_APP_API_URL + `/api/sales_gstr1/${actYear}/${actMonth}`);
                if (resp.success) {
                    setData(resp.data);
                    setInvoice_prefix(resp.invoice_prefix);
                    let csvData: any = [];
                    resp.data.map((report: any) => {
                        let taxableValue = ((report.qty * report.rate) - report.discount_amt);
                        csvData.push({
                            invoice_date: moment(report.invoice_date).format("YYYY-MM-DD"),
                            invoice_no: invoice_prefix ? invoice_prefix + padLeadingZeros(report.invoice_no, 5) : "INV" + padLeadingZeros(report.invoice_no, 5),
                            gstin: report.gstin,
                            shipping_address: report.place_state,
                            hsn_or_sac: report.hsn_or_sac,
                            taxableValue: taxableValue.toFixed(2),
                            tax_per: `${report.tax_per}%`,
                            amount: report.amount
                        });
                    })
                    setCsvData(csvData);
                }
                setIsSubmitting(false);

            } catch (err) {
                // Handle Error Here
                console.error(err);
            }
        };

        sendGetRequest();
    }
    const padLeadingZeros = (num: number, size: number) => {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    const downlaodPdf = () => {
        const doc: any = new jsPDF()
        doc.autoTable({ html: '#my-table', theme: 'grid' })
        doc.save('sales_gstr1_report.pdf')
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Sales GSTR1</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Reports" breadcrumbItem="Sales GSTR1" />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <AvForm>
                                        <div className="container">
                                            <div className="row">
                                                <div className='col-md-3'>
                                                <Col sm={12}>
                                                    <AvField type="select" name="year"
                                                        validate={{ required: { value: false } }} value={actYear} onChange={(e: any) => setActYear(Number(e.target.value))} >
                                                        <option value={0} key="" >--Year--</option>
                                                        {years.map((year: number, index: number) => (
                                                            <option value={year} key={index} >{year}</option>
                                                        ))}
                                                    </AvField>
                                                </Col>
                                                </div>
                                                <div className='col-md-3'>
                                                    <Col sm={12}>
                                                        <AvField type="select" name="month"
                                                            validate={{ required: { value: false } }} value={actMonth} onChange={(e: any) => setActMonth(Number(e.target.value))} >
                                                            <option value={0} key="" >--Month--</option>
                                                            {months.map((month: string, index: number) => (
                                                                <option value={(index + 1)} key={index} >{month}</option>
                                                            ))}
                                                        </AvField>
                                                    </Col>
                                                </div>
                                                <div className='col-md-4' style={{position: "relative"}}>
                                                <div className="modal-footer justify-content-center" style={{position: "absolute", bottom:"0"}}>
                                                    {isSubmitting ?
                                                        (
                                                            <button type="submit" className="btn btn-primary " disabled={true}>
                                                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Submiting
                                                            </button>
                                                        ) : (
                                                            <>
                                                                <button type="button" className="btn btn-primary" onClick={() => clickBtn()}>
                                                                    Submit
                                                                </button>
                                                            </>
                                                        )
                                                    }
                                                    <div>
                                                    {data.length ?
                                                        (
                                                            <>
                                                                <CSVLink data={csvData} headers={csvHeaders} filename={"sales_gstr1_report.csv"} className="btn tbl-btn" style={{ marginLeft:22 }}>
                                                                    <i className="fas fa-file-pdf"></i>
                                                                </CSVLink>
                                                                <Link to="#" className="btn tbl-btn mx-2" >
                                                                    <i className="fas fa-file-csv" style={{ color: "red" }} onClick={() => downlaodPdf()}></i>
                                                                </Link>
                                                            </>
                                                            
                                                        ) : (
                                                            <>
                                                                <span className="btn tbl-btn" style={{ marginLeft:22 }}><i className="fas fa-file-pdf"></i></span>
                                                                <span className="btn tbl-btn mx-2"><i className="fas fa-file-csv" style={{ color: "red" }}></i></span>
                                                            </>
                                                        )
                                                    }
                                                    </div>
                                                </div>
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </AvForm>
                                    {isSetData && (
                                        <>
                                            <Card className='py-3' style={{marginTop:"2%"}}>
                                                {data.length ? (
                                                    <>
                                                        <Row>
                                                            <div className="col-lg-10">
                                                            </div>
                                                            <div className="col-lg-2">
                                                                <div className="mb-3">
                                                                    <div className='table-button'>
                                                                        {/* <CSVLink data={csvData} headers={csvHeaders} filename={"sales_gstr1_report.csv"} className="btn tbl-btn" >
                                                                            <i className="fas fa-file-pdf"></i>
                                                                        </CSVLink>
                                                                        <Link to="#" className="btn tbl-btn mx-2" onClick={() => downlaodPdf()}>
                                                                            <i className="fas fa-file-csv" style={{ color: "red" }} ></i>
                                                                        </Link>
                                                                        <Link to="#" className="btn tbl-btn mx-2">
                                                                            <i className="fas fa-file-csv" style={{ color: "red" }} onClick={() => downlaodPdf()}></i>
                                                                        </Link> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <Table className="align-middle mb-0" id='my-table' >
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Invoice Date</th>
                                                                                <th>Invoice Number</th>
                                                                                <th>Customer GSTIN</th>
                                                                                <th>State of Supply</th>
                                                                                <th>HSN/SAC</th>
                                                                                <th>Taxable Value</th>
                                                                                <th>GST Rate</th>
                                                                                <th>Total</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {data.map((report: any) => {
                                                                                let taxableValue = ((report.qty * report.rate) - report.discount_amt);
                                                                                return (
                                                                                    <tr key={report.id}>
                                                                                        <td>{moment(report.invoice_date).format("YYYY-MM-DD")}</td>
                                                                                        <td>{invoice_prefix ? invoice_prefix + padLeadingZeros(report.invoice_no, 5) : "INV" + padLeadingZeros(report.invoice_no, 5)}</td>
                                                                                        <td>{report.gstin}</td>
                                                                                        <td>{report.place_state}</td>
                                                                                        <td>{report.hsn_or_sac}</td>
                                                                                        <td>{taxableValue.toFixed(2)}</td>
                                                                                        <td>{report.tax_per}%</td>
                                                                                        <td>{report.amount}</td>
                                                                                    </tr>
                                                                                )
                                                                            })}

                                                                        </tbody>
                                                                    </Table>

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                ) : (
                                                    <>
                                                        <NoDataIndication />
                                                    </>
                                                )}
                                            </Card>
                                        </>
                                    )}

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SalesGstr1;