import React, { Component, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, CardTitle, CardHeader, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { post, get } from "src/helpers/api_helper";
import { useHistory } from "react-router";

const VendorCreate = () => {
    const CountryInd = 99;
    const [id, setId] = useState("0");
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState(CountryInd);
    const [states, setStates] = useState([]);
    const [state, setState] = useState('');
    const [is_indian_state, setIs_indian_state] = useState(1);
    const history = useHistory();
    const handleValidSubmit = (event: any, values: any) => {
        values["id"] = id;
        values["is_indian_state"] = is_indian_state;
        const resp = post(process.env.REACT_APP_API_URL + '/api/vendor_save', values);
        history.push("/vendors");
    }
    const resetForm = () => {
        history.push("/vendors");
    }
    const onChangehandler = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        setCountry(value);
        if (value == CountryInd) {
            setState('');
            setIs_indian_state(1);            
        } else {
            setState('');
            setIs_indian_state(0);
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            try {                
                let urls = [                    
                    process.env.REACT_APP_API_URL + '/api/states',
                    process.env.REACT_APP_API_URL + '/api/countries'                    
                ];
    
                Promise.all(urls.map((url) => get(url))).then(([respS, respC]) => {
                    setStates(respS.data);
                    setCountries(respC.data);  
                });
                
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();

    }, []);
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Vendors</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Create Vendor" breadcrumbItem="Create Vendor" />

                    <Row>
                        <Col className="col-12">
                            <Card>

                                <CardHeader></CardHeader>



                                <CardBody>

                                    <AvForm onValidSubmit={handleValidSubmit}>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="progresspill-firstname-input">
                                                        Vendor name
                                                    </label>
                                                    <AvField
                                                        name="vendor_name"
                                                        type="text"
                                                        placeholder="Enter Vendor name"
                                                        errorMessage="Enter Vendor name"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="progresspill-address-input">
                                                        Address
                                                    </label>
                                                    <AvField type="text" name="address" value="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="progresspill-address-input">
                                                        GSTIN
                                                    </label>
                                                    <AvField
                                                        name="gstin" type="text"
                                                        placeholder="Enter GSTIN"                                                        
                                                        value=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="progresspill-address-input">
                                                        Country
                                                    </label>
                                                    <AvField type="select" name="country" value={country}  onChange={onChangehandler}>
                                                        <option key={-1} value={""}>--Select--</option>
                                                        {countries.map((country: { id: string, name: string }) => (
                                                            <option value={country.id} key={country.id}>{country.name}</option>
                                                        ))}
                                                    </AvField>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="progresspill-address-input">
                                                        State / Province
                                                    </label>
                                                    {is_indian_state ?
                                                        (
                                                            <AvField type="select" name="state" value=""  >
                                                                <option key={-1} value={""}>--Select--</option>
                                                                {states.map((state: { id: string, state: string; }) => (
                                                                    <option value={state.id} key={state.id}>{state.state}</option>
                                                                ))}
                                                            </AvField>
                                                        )
                                                        :
                                                        (
                                                            <AvField
                                                                name="state"
                                                                type="text"
                                                                placeholder="Enter state or province"                                                                
                                                                value=""                                                                                                                            
                                                            />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex flex-wrap gap-2">
                                            <Button type="submit" color="primary" className="">
                                                Submit
                                            </Button>{" "}
                                            <Button type="reset" color="secondary" className="" onClick={resetForm}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div></div>
        </React.Fragment>
    );
};

export default VendorCreate;