import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState } from "react";

import { Row, Col, Alert, Container } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import logo from "../../assets/images/logo_login.png";

//Import config
import config from "../../config";
// import CarouselPage from "./CarouselPage";
import CarouselPageNew from "./CarouselPageNew";

interface LoginProps {
    history: object;
}

const LoginEmail = ({ history }: LoginProps) => {
    const [isProgress, setIsProgress] = useState(0);
    const dispatch = useDispatch();
    const appName = process.env.REACT_APP_NAME;

    const { error } = useSelector((state: any) => ({
        error: state.login.error,
    }));

    // handleValidSubmit
    const handleValidSubmit = (event: any, values: any) => {
        dispatch(loginUser(values, history));
    };

    return (
        <React.Fragment>
            <AvForm
                className="custom-form mt-2 pt-2"
                onValidSubmit={(e: any, v: any) => {
                    handleValidSubmit(e, v);
                    setIsProgress(1);
                }}
            >
                {error ? <Alert color="danger">{error}</Alert> : null}


                <div className="mb-3">
                    <AvField
                        name="email"
                        label="Email"
                        //value="admin@themesbrand.com"
                        className="form-control"
                        placeholder="Enter email"
                        type="email"
                        required
                    />
                </div>
                <div className="mb-3">
                    <div className="d-flex align-items-start">
                        <div className="flex-grow-1">
                            <label className="form-label">Password</label>
                        </div>
                        <div className="flex-shrink-0">
                            <div className="">
                                <Link
                                    to="/forgotpassword"
                                    className="text-muted"
                                >
                                    Forgot password?
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="mb-3">
                        <AvField
                            name="password"
                            //value="123456"
                            type="password"
                            className="form-control"
                            required
                            placeholder="Enter Password"
                        />
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="remember-check"
                            />
                            <label
                                className="form-check-label"
                                htmlFor="remember-check"
                            >
                                Remember me
                            </label>
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    {isProgress === 1 ?
                        (
                            <button
                                className="btn blk-btn w-100 waves-effect waves-light"
                                type="submit"
                                disabled={true}
                            >
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}Submiting
                            </button>
                        ) : (
                            <button
                                className="btn blk-btn w-100 waves-effect waves-light"
                                type="submit"
                            >
                                Log In
                            </button>
                        )
                    }
                </div>
            </AvForm>

        </React.Fragment >
    );
};

export default withRouter(LoginEmail);
