import React from 'react';

const RecordIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.3" height="16" viewBox="0 0 16.3 16">
    <g id="_15415511951594721605" data-name="15415511951594721605" transform="translate(-3 -2.291)">
      <g id="Group_550" data-name="Group 550" transform="translate(3 1.935)">
        <ellipse id="Ellipse_17" data-name="Ellipse 17" cx="6" cy="3.5" rx="6" ry="3.5" transform="translate(0 0.356)"/>
        <path id="Path_403" data-name="Path 403" d="M9.183,22.423A8.853,8.853,0,0,1,3,20.4v2.023C3,24.334,5.7,25.8,9.183,25.8a13.781,13.781,0,0,0,1.518-.112,5.688,5.688,0,0,1-1.518-3.26Z" transform="translate(-3 -10.002)" />
        <path id="Path_404" data-name="Path 404" d="M3,12.4v2.023C3,16.334,5.7,17.8,9.183,17.8h.056a5.694,5.694,0,0,1,1.686-3.485,17.022,17.022,0,0,1-1.742.112A8.853,8.853,0,0,1,3,12.4Z" transform="translate(-3 -6.498)"/>
      </g>
      <path id="Path_405" data-name="Path 405" d="M21.059,15.1v4.778l.731-.731a.556.556,0,0,1,.787.787L20.89,21.62c-.056.056-.112.112-.169.112a.478.478,0,0,1-.45,0c-.056-.056-.112-.056-.169-.112l-1.686-1.686a.556.556,0,1,1,.787-.787l.731.731V15.1a4.5,4.5,0,1,0,1.124,0Z" transform="translate(-5.693 -5.746)"/>
    </g>
  </svg>
  
  );
}

export default RecordIcon;
